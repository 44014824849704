import * as moment from 'moment';
import { SET_DADOS_ON_BOARD } from './types';
import { setStorage } from '../../hooks/useStorage';

const INITIAL_STATE_ = {
    Id: 1,
    IdUsuarioLoja: 0,
    dataAlteracao: moment().format('YYYY-MM-DD HH:mm:ss'),
    completado: false,
    ultimoItem: 1,
    items: [
     { id: 1, title: 'Configure sua loja', descricao: 'Configure os dados da sua loja', descricaoBotao: 'Configurar loja', link: '/config-loja', status: false, dataAlteracao: '', dataAlteracao: moment().format('YYYY-MM-DD HH:mm:ss') },
     { id: 2, title: 'Adicione os produtos', descricao: 'Defina o preço, escreva uma descrição, insira fotos do produtos', descricaoBotao: 'Adicionar produtos', link: '/produtos', status: false, dataAlteracao: moment().format('YYYY-MM-DD HH:mm:ss') },
     { id: 3, title: 'Personalize o design', descricao: 'Defina a logo, os banners, redes sociais da loja, endereço e horário de atendimento da loaj', descricaoBotao: 'Desenhar loja', link: '/configurar-ecommerce', status: false, dataAlteracao: moment().format('YYYY-MM-DD HH:mm:ss') },
     { id: 4, title: 'Configure os meios de pagamentos', descricao: 'Selecione e configure o meio de pagamento a ser utilizado', descricaoBotao: 'Configurar pagamentos', link: '/formas-pagamento', status: false, dataAlteracao: moment().format('YYYY-MM-DD HH:mm:ss') },
     { id: 5, title: 'Liberar loja', descricao: 'Selecione o provedor e configure o domínio da sua loja', descricaoBotao: 'Configurar loja', link: '/config-dominio-leap', status: false, dataAlteracao: moment().format('YYYY-MM-DD HH:mm:ss') },
     { id: 6, title: 'Configure a comunicação da loja', descricao: 'Configure a comunicação da loja', descricaoBotao: 'Configurar comunicação', link: '/config-emails', status: false, dataAlteracao: moment().format('YYYY-MM-DD HH:mm:ss') },
    ]
 };

const INITIAL_STATE = null;

const onBoardRecuder = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_DADOS_ON_BOARD:
      const verificacao = action.data?.ListOnboardingStorexOnboardingStoreItem.filter((item) => item.FlagStatus)?.length === action.data?.ListOnboardingStorexOnboardingStoreItem.length;
      const obj = {...action.data, FlagCompletado: verificacao};

      setStorage('onBoard', JSON.stringify(obj));
      return obj;
    default:
      return state;
  }
};

export default onBoardRecuder;
