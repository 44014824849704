import { Box, Modal, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_SHOPPING_CART_CLOSE } from "../../store/ModalShoppingCartReducer/types";
import { CloseBtn, ModalContainer } from "./styles";

import imgCarrinho from '../../assets/carrinho.svg';

const ShoppingCartModal = () => {
    const dispatch = useDispatch();
    const modalShoppingCart = useSelector((store) => store.modalShoppingCart);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        setModalIsOpen(modalShoppingCart.isOpen);
    }, [modalShoppingCart?.isOpen]);

    const toggleModal = () => {
        dispatch({
            type: MODAL_SHOPPING_CART_CLOSE
        });
    }

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') return false;
        if (reason === 'escapeKeyDown') return false;

        dispatch({
            type: MODAL_SHOPPING_CART_CLOSE
        });
    };

    return modalIsOpen &&
        <Modal
            open={modalIsOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            keepMounted
            disableEscapeKeyDown
            disableAutoFocus
        >
            <ModalContainer>
                <Stack direction="row" style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'nowrap', align: 'flex-end', alignItems: 'center', marginRight: 30 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
                        Carrinho
                    </Typography>
                    <div style={{ minHeight: 20 }}>
                        <CloseBtn style={{ fontSize: 25 }} onClick={toggleModal} />
                    </div>
                </Stack>
                <hr />

                <Box style={{ marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography variant="body2" component="p" fontSize={18}>
                        Carrinho vazio
                    </Typography>
                    <img alt="Carrinho" src={imgCarrinho} style={{ width: '50%', marginTop: 100 }} />
                </Box>
            </ModalContainer>
        </Modal>
}

export default ShoppingCartModal;