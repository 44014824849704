import { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { SET_MENU, drawerWidth } from '../../store/Customization/types';
import Header from './Header';

import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Outlet, useLocation, useNavigate } from 'react-router';
import Sidebar from './Sidebar';
import MuiAppBar from '@mui/material/AppBar';
import { Button, Card, CssBaseline, styled, Typography, useTheme } from '@mui/material';
import Footer from '../../components/Footer';
import { CONTROLEMENSAGEM_SUCESSO } from '../../store/ControleMensagemReducer/types';
import { removeStorage } from '../../hooks/useStorage';
import { isMobile } from '../../services/DeviceServices';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open, comprimentoMenuLateral, lojaConfigurada }) => ({
  ...theme.typography.mainContent,
  ...(!open && {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: comprimentoMenuLateral > 0 ? (lojaConfigurada ? '90px' : 10) : 0,
      width: comprimentoMenuLateral > 0 && lojaConfigurada ? '97%' : '100%'
    },
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px',
      width: '100vw',
      padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: comprimentoMenuLateral > 0 ? (lojaConfigurada ? '90px' : 10) : 0,
      width: '100vw',
      padding: '16px',
      marginRight: '10px'
    }
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    width: !lojaConfigurada ? '100%' : `calc(100% - ${comprimentoMenuLateral}px)`,
    [theme.breakpoints.down('md')]: {
      marginLeft: '20px'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '10px'
    }
  })
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, comprimentoMenuLateral, lojaConfigurada }) => ({
  zIndex: 0,
  marginLeft: 0,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: comprimentoMenuLateral,
    width: !lojaConfigurada ? '100%' : `calc(100% - ${comprimentoMenuLateral}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MainLayout = () => {
  const location = useLocation();
  const themeContext = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onBoard = useSelector((store) => store.onBoard);
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const ehLeapLovers = process.env.REACT_APP_LEAP_LOVERS === 'true';
  const matchDownMd = useMediaQuery(themeContext.breakpoints.down('lg'));
  const [comprimentoMenuLateral, setComprimentoMenuLateral] = useState(0);

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  useEffect(() => {
    setComprimentoMenuLateral(!location.pathname.includes('lojas') ? drawerWidth : 0);
  }, [location]);

  const lojaConfigurada = ehLeapLovers ? true : !location.pathname.includes('lojas') && !(location.pathname.replace('/', '') === 'onboard-lojista') && !(location.pathname.replace('/', '') === 'on-board-design-site') && onBoard?.ListOnboardingStorexOnboardingStoreItem && onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 1)[0].FlagStatus;

  if (isMobile() && (location.pathname.replace('/', '') !== 'onboard-lojista' && location.pathname.replace('/', '') !== 'on-board-design-site')) {
    return <Box style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#d3d3d3' }}>
      <Box style={{ width: '100%', height: '100%', minHeight: 700, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card style={{ width: 350, height: 'auto', minHeight: 400, padding: 20, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box style={{ width: '100%' }}>
            <InfoIcon style={{ fontSize: 60 }} color="primary" />
          </Box>
          <Typography style={{ fontSize: 16, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>Para continuar, recomendamos o uso de um computador ou tablet para melhor desempenho e experiência.</Typography>

          <Typography style={{ fontSize: 16, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>Gostaria de continuar no computador? Envie este link para seu email e acesse de lá.</Typography>

          <Typography style={{ fontSize: 16, fontWeight: 'bold', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', border: '1px solid #c0c0c0', padding: 10, borderRadius: 10 }}>https://crm.leapstyle.me/ <ContentCopyIcon style={{ marginLeft: 15, fontSize: 25, cursor: 'pointer' }} onClick={() => {
            try {
              navigator.clipboard.writeText('https://crm.leapstyle.me/');

              dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Link copiado',
              });
            }
            catch (err) {

            }
          }} /></Typography>

          <Button variant="contained" onClick={() => {
            removeStorage('usrin');
            removeStorage('superUsrin');
            removeStorage('onBoard');
            navigate('/login');
          }}>Concluir</Button>
        </Card>
      </Box>

    </Box>
  }

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        position="fixed"
        color="inherit"
        elevation={0}
        comprimentoMenuLateral={comprimentoMenuLateral}
        lojaConfigurada={lojaConfigurada}
        open={leftDrawerOpened}
        sx={{
          zIndex: 999,
          bgcolor: themeContext.palette.background.default,
          transition: leftDrawerOpened ? themeContext.transitions.create('width') : 'none'
        }}
      >
        <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
      </AppBar>

      {/* drawer */}
      {
        onBoard && lojaConfigurada &&
        <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
      }
      {
        ehLeapLovers &&
        <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
      }

      <Main theme={themeContext} open={leftDrawerOpened} comprimentoMenuLateral={comprimentoMenuLateral} lojaConfigurada={lojaConfigurada} style={{ background: themeContext.palette.background.default, minHeight: '100vh', height: '100%', padding: 20, paddingTop: 90, paddingBottom: 20, color: 'black' }}>
        {/* breadcrumb */}
        <Outlet />
      </Main>
      <Footer />
    </Box>
  );
}

export default MainLayout;
