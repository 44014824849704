import axios from 'axios';
import tratarErro from '../utils/tratarError';
import { getStorage, removeStorage } from '../../hooks/useStorage';
import * as Sentry from "@sentry/browser";

const AUTH_HEADER = () => {
    return {
        auth: {
            username: process.env.REACT_APP_API_AUTH_USERNAME,
            password: process.env.REACT_APP_API_AUTH_PASSWORD,
        },
        headers: {
            Authorization: "Basic QWNjZXNzTGVhcFN0eWxlREVWQVBJOk1qQk1SV0Z3VTNSNVRHVlNiMk5yZEZOMGRXUnBiekl4",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }
};

const AUTH_HEADER_SERVICOS_ROBO = () => {
    return {
        headers: {
            Authorization: `Basic ${process.env.REACT_APP_API_TOKEN_SERVICOS_ROBO}`,
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    }
};

const AUTH_HEADER_FILE = () => {
    return {
        auth: {
            username: process.env.REACT_APP_API_AUTH_USERNAME,
            password: process.env.REACT_APP_API_AUTH_PASSWORD,
        },
        headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*"
        }
    }
};

const AUTH_HEADER_AZURE = () => {
    return {
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`
        }
    }
};

const AUTH_HEADER_FILE_AZURE = () => {
    return {
        headers: {
            "type": "formData",
            'Content-Type': 'multipart/form-data',
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Basic ${process.env.REACT_APP_API_AZURE_TOKEN}`
        }
    }
};


export const PUT_AUTH = async (email, senha) => {
    try {
        const endpoint = `${process.env.REACT_APP_API}/Usuario/Login?email=${email}&senha=${encodeURIComponent(senha)}&idUsuarioLoja=188`;

        const response = await axios.put(endpoint, {}, AUTH_HEADER());
        if (response.status >= 200 && response.status <= 299) return response?.data;

        return null;
    }
    catch (err) {
        SalvarLogSentry(err);
        throw tratarErro(err);
    }
}

export const POST_AUTH = async (obj = {}) => {
    try {
        const endpoint = process.env.REACT_APP_API_DESIGN.replace('/api', '/authentication');
        const response = await axios.post(endpoint, obj);
        if (response.status >= 200 && response.status <= 299) return response?.data;

        return null;
    }
    catch (err) {
        throw tratarErro(err);
    }
}

export const POST_DATA = async (endpoint, obj = {}) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API}/${endpoint}`, obj, AUTH_HEADER());
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        if (err?.response?.status !== 406 || err?.response?.status !== 401) {
            SalvarLogSentry(err);
        }

        if (err?.response?.status === 401) //Não autorizado
        {
            removeStorage('usrin');
            alert('Sessão expirada!\n Favor realizar o login novamente');
            window.location.href = "/login";
        }
        throw tratarErro(err);
    }
}

export const DELETE_DATA = async (endpoint) => {
    try {
        const response = await axios.delete(`${process.env.REACT_APP_API}/${endpoint}`, AUTH_HEADER());
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        if (err?.response?.status !== 406 || err?.response?.status !== 401) {
            SalvarLogSentry(err);
        }

        if (err?.response?.status === 401) //Não autorizado
        {
            removeStorage('usrin');
            alert('Sessão expirada!\n Favor realizar o login novamente');
            window.location.href = "/login";
        }
        throw tratarErro(err);
    }
}

export const POST_DATA_SERVICOS_ROBO = async (endpoint, obj = {}) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_SERVICOS_ROBO}${endpoint}`, obj, AUTH_HEADER_SERVICOS_ROBO());
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        SalvarLogSentry(err);
        throw err;
    }
}

export const GET_DATA_SERVICOS_ROBO = async (endpoint) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_SERVICOS_ROBO}${endpoint}`, AUTH_HEADER_SERVICOS_ROBO());
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        SalvarLogSentry(err);
        throw err;
    }
}

export const POST_DATA_DESING = async (endpoint, token, obj = {}) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_DESIGN}/${endpoint}`, obj, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        });
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        SalvarLogSentry(err);
        throw tratarErro(err);
    }
}

export const POST_DATA_FORM_DESING = async (endpoint, token, obj = {}) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_DESIGN}/${endpoint}`, obj, {
            headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        });
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        SalvarLogSentry(err);
        throw tratarErro(err);
    }
}

export const POST_DATA_FILE = async (endpoint, obj = {}) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API}/${endpoint}`, obj, AUTH_HEADER_FILE());
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        if (err?.response?.status !== 406 || err?.response?.status !== 401) {
            SalvarLogSentry(err);
        }

        if (err?.response?.status === 401) //Não autorizado
        {
            alert('Sessão expirada!\n Favor realizar o login novamente');
            removeStorage('usrin');
            window.location.href = "/login";
        }
        throw err?.response?.data;
    }
}

export const PUT_DATA_FILE = async (endpoint, obj = {}) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API}/${endpoint}`, obj, AUTH_HEADER_FILE());
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        if (err?.response?.status !== 406 || err?.response?.status !== 401) {
            SalvarLogSentry(err);
        }

        if (err?.response?.status === 401) //Não autorizado
        {
            alert('Sessão expirada!\n Favor realizar o login novamente');
            removeStorage('usrin');
            window.location.href = "/login";
        }
        throw err?.response?.data;
    }
}

export const PUT_DATA = async (endpoint, obj = {}) => {
    try {
        if(endpoint === 'Imagem/GetListImagemFilterPaginationCRM'){
            try{
                const response = await axios.post(`${process.env.REACT_APP_API_AZURE_FILE}/leappublish_pagination_web`, obj, {
                    headers: {
                        "type": "formData",
                        "Content-Type": "application/json",
                        "Accept": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Authorization": `Basic ${process.env.REACT_APP_API_AZURE_FILE_TOKEN}`
                    }
                });
    
                if (response.status >= 200 && response.status <= 299) return response?.data?.objetoRetorno;
    
                return null;
            }
            catch{
                const response = await axios.put(`${process.env.REACT_APP_API}/${endpoint}`, obj, AUTH_HEADER());
                if (response.status >= 200 && response.status <= 299) return response?.data;
                return null;
            }
        }

        const response = await axios.put(`${process.env.REACT_APP_API}/${endpoint}`, obj, AUTH_HEADER());
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        if (err?.response?.status !== 406 || err?.response?.status !== 401) {
            SalvarLogSentry(err);
        }

        if (err?.response?.status === 401) //Não autorizado
        {
            alert('Sessão expirada!\n Favor realizar o login novamente');
            removeStorage('usrin');
            window.location.href = "/login";
        }
        throw tratarErro(err);
    }
}

export const PUT_DATA_DESIGN = async (endpoint, token, obj = {}) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_DESIGN}/${endpoint}`, obj, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        });
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        if (err?.response?.status !== 406 || err?.response?.status !== 401) {
            SalvarLogSentry(err);
        }

        throw tratarErro(err);
    }
}

export const GET_DATA = async (endpoint) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API}/${endpoint}`, AUTH_HEADER());
        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        if (err?.response?.status !== 406 || err?.response?.status !== 401) {
            SalvarLogSentry(err);
        }

        if (err?.response?.status === 401) //Não autorizado
        {
            alert('Sessão expirada!\n Favor realizar o login novamente');
            removeStorage('usrin');
            window.location.href = "/login";
        }
        throw tratarErro(err);
    }
}

export const GET_DATA_DESIGN = async (endpoint, token) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_DESIGN}/${endpoint}`, {
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Authorization": `Bearer ${token}`
            }
        });

        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        SalvarLogSentry(err);
        throw tratarErro(err);
    }
}

export const UploadFileAzure = async (file, diretorio, nomeArquivo, contentType = `image/jpg`) => {
    try {
        if (file === null || file === undefined) return file;

        const obj = {
            raiz: 'cms',
            pasta: diretorio,
            nomeArquivo: nomeArquivo,
            contentType: contentType
        }

        let data = new FormData();
        data.append('Imagem', file);
        data.append('objImagem', JSON.stringify(obj));

        const result = await axios.post(process.env.REACT_APP_API_AZURE, data, AUTH_HEADER_FILE_AZURE());

        if (result.data.caminho && !contentType.includes('webp') && !contentType.includes('gif')) {
            return await saveImagemConvertedWebp(nomeArquivo, diretorio, result.data.caminho);
        }

        return result.data.caminho;
    }
    catch (err) {
        SalvarLogSentry(err);
        throw tratarErro(err);
    }
}

export const saveImagemConvertedWebp = async (nomeArquivo, diretorio, urlImage) => {
    try {
        const convert = await POST_DATA_AZURE_FILE('ConvertToWebp', { imageUrl: urlImage });
        const data = new FormData();
        data.append('objImagem', JSON.stringify({
            raiz: 'cms',
            pasta: diretorio,
            nomeArquivo: `${nomeArquivo}.webp`,
            contentType: 'image/webp',
            base64: convert.base64
        }));

        const result = await axios.post(process.env.REACT_APP_API_AZURE, data, AUTH_HEADER_FILE_AZURE());
        return result.data.caminho;
    }
    catch {
        return urlImage;
    }
}

const POST_DATA_AZURE_FILE = async (endpoint, obj = {}) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_AZURE_FILE}/${endpoint}`,
            obj,
            {
                headers: {
                    "type": "formData",
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Authorization": `Basic ${process.env.REACT_APP_API_AZURE_FILE_TOKEN}`
                }
            });

        if (response.status >= 200 && response.status <= 299) return response?.data;
        return null;
    }
    catch (err) {
        SalvarLogSentry(err);
        throw err;
    }
}

export const UploadFileAzureText = async (file, diretorio, nomeArquivo, contentType = `text/csv`) => {
    try {
        if (file === null || file === undefined) return file;

        const obj = {
            raiz: 'cms',
            pasta: diretorio,
            nomeArquivo: nomeArquivo,
            contentType: contentType,
            fileBase64: file
        }

        const result = await POST_DATA_AZURE_FILE('UploadFileForm', obj);

        if (result.success) {
            return result.caminho;
        }
        else {
            throw (result);
        }
    }
    catch (err) {
        SalvarLogSentry(err);
        throw tratarErro(err);
    }
}

//Vesti
export const INTEGRA_VESTI = async (metodo = 'GET', endPoint, company, apiKey, dados = null) => {
    try {
        const obj = {
            "sUrl": `${process.env.REACT_APP_API_VESTI}/v1/${endPoint}`,
            "sHttp": metodo,
            "sCompany": company,
            "sApiKey": apiKey,
            "body": dados
        }

        const result = await PUT_DATA(`FornecedoresVesti/ConsutaVesti`, obj);
        return result.objetoRetorno;
    }
    catch (err) {
        SalvarLogSentry(err);
        throw tratarErro(err);
    }
}

//Fim Vesti


export const SalvarLogSentry = (err) => {
    const user = JSON.parse(getStorage('usrin'));
    const msgErr = `Usuário logado: ${user?.Email} - Empresa Id: ${user?.IdUsuarioEmpresa}\n${err}`;
    Sentry.init({
        dsn: process.env.REACT_APP_TOKEN_SENTRY
    });

    Sentry.captureException(msgErr);
}

export const SalvarLogEventoSentry = (evento) => {
    const user = JSON.parse(getStorage('usrin'));
    const msgErr = `${evento}\nUsuário logado: ${user?.Email} - Empresa Id: ${user?.IdUsuarioEmpresa}`;
    Sentry.init({
        dsn: process.env.REACT_APP_TOKEN_SENTRY
    });

    Sentry.captureMessage(msgErr);
}