import * as moment from "moment";
import "moment/locale/pt"
import { PUT_DATA, UploadFileAzureText } from "../API";
import emptySvg from "../../assets/empty.svg";
import { OnboardingStore, OnboardingStorexOnboardingStoreItem } from "../../Model/OnboardingStore";

export const ImagemSemResultado = emptySvg;

export function isNumber(n: string) {
  return !isNaN(parseFloat(n)) && isFinite(Number(n));
}

export function isEmail(email: string) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
}

export function IsEmailValido(email: string){
  var exclude=/|^[_@.-]|[._-]{2}|[@.]{2}|(@)[^@]*1/;
  var check=/@[w-]+./;
  var checkend=/.[a-zA-Z]{2,3}$/;
  if(((email.search(exclude) != -1)||(email.search(check)) == -1)||(email.search(checkend) == -1)){return false;}
  else {return true;}
}

export function calcularPorcentagem(
  valorAtual: number,
  valorComparativo: number
): number {
  if (valorComparativo <= 0) {
    return 0;
  }
  const diferenca = valorAtual - valorComparativo;
  const porcentagem = (diferenca / valorComparativo) * 100;
  return porcentagem;
}

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
};

function subtractHours(date: any, hours: any) {
  date.setHours(date.getHours() - hours);

  return date;
}

export const getCategoryString = (categoryId: number) => {
  let categoryText;
  switch (categoryId) {
    case 0:
      return (categoryText = "Todos");
    case 1:
      return (categoryText = "Pedidos em Análise");
    case 2:
      return (categoryText = "Pedidos Aprovados");
    case 3:
      return (categoryText = "Pedidos Encaminhados para Entrega");
    case 4:
      return (categoryText = "Pedidos Entregues");
    case 6:
      return (categoryText = "Pedidos Reprovados");
    case 7:
      return (categoryText = "Pedidos Cancelados pelo Comprador");
    case 8:
      return (categoryText = "Pedidos Cancelados pelo Vendedor");
    case 10:
      return (categoryText = "Pedidos Cancelados");
    default:
      break;
  }

  return categoryText;
};

export const formatDate = (date: string) => {
  const parsedDate = moment(date).locale("pt-br");
  const daysAgo = parsedDate.fromNow();
  const day = parsedDate.format("dddd");
  const dayNumber = parsedDate.format("DD");
  const month = parsedDate.format("MMMM");
  const year = parsedDate.format("YYYY");

  return `${day}, ${dayNumber} de ${month} de ${year} (${daysAgo})`;
};

export const formatDateSimples = (date: string, mostrarDiferenca: false) => {
  const parsedDate = moment(date).locale("pt-br");
  const daysAgo = parsedDate.fromNow();

  return `${moment(date).format('DD/MM/YYYY')} ${mostrarDiferenca ? `(${daysAgo})` : ''}`;
};

export const formatCurrency = (value: number) => {
  return value.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });
}

export const salvarCSV = async (empresaId: number, nomeArquivo: string, dados: string) => {
  try {
    const file = btoa(dados);
    const pasta = `relatorios/${empresaId}/csv`;
    const url = await UploadFileAzureText(file, pasta, nomeArquivo, 'text/csv');
    return url;
  }
  catch (err) {
    throw err;
  }
}

export const atualizarOnboardStore = async (onboard: OnboardingStore, item: OnboardingStorexOnboardingStoreItem) => {
  await PUT_DATA(`OnboardingStore/CadastroOnboardingStore`, onboard);

  if (item !== null) {
    item.DataCadastro = null;
    item.DataAlteracao = null;
    await PUT_DATA(`OnboardingStorexOnboardingStoreItem/CadastroOnboardingStorexOnboardingStoreItem`, item);
  }
}

export const preventNonNumeric = (e: any) => {
  if ((e.which < 48 || e.which > 57) && e.which !== 8) {
    e.preventDefault();
  }
};

export const getCurrentYear = () => new Date().getFullYear();

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const getYears = (qtd: number) => {
  let years = [];
  let currentYear = getCurrentYear();

  years.push(currentYear);

  for (let i = currentYear; i <= currentYear + qtd; i++) {
    years.push(i);
  }

  return years;
};


function verifica_cpf_cnpj(valor: string) {
  // Garante que o valor é uma string
  valor = valor.toString();

  // Remove caracteres inválidos do valor
  valor = valor.replace(/[^0-9]/g, '');

  // Verifica CPF
  if (valor.length === 11) {
    return 'CPF';
  }

  // Verifica CNPJ
  else if (valor.length === 14) {
    return 'CNPJ';
  }

  // Não retorna nada
  else {
    return false;
  }
} // verifica_cpf_cnpj

/*
 calc_digitos_posicoes
 
 Multiplica dígitos vezes posições
 
 @param string digitos Os digitos desejados
 @param string posicoes A posição que vai iniciar a regressão
 @param string soma_digitos A soma das multiplicações entre posições e dígitos
 @return string Os dígitos enviados concatenados com o último dígito
*/
function calc_digitos_posicoes(digitos = "", posicoes = 10, soma_digitos = 0) {
  // Garante que o valor é uma string
  digitos = digitos.toString();

  // Faz a soma dos dígitos com a posição
  // Ex. para 10 posições:
  //   0    2    5    4    6    2    8    8   4
  // x10   x9   x8   x7   x6   x5   x4   x3  x2
  //   0 + 18 + 40 + 28 + 36 + 10 + 32 + 24 + 8 = 196
  for (let i = 0; i < digitos.length; i++) {
    // Preenche a soma com o dígito vezes a posição
    soma_digitos = soma_digitos + Number(digitos[i]) * posicoes;

    // Subtrai 1 da posição
    posicoes--;

    // Parte específica para CNPJ
    // Ex.: 5-4-3-2-9-8-7-6-5-4-3-2
    if (posicoes < 2) {
      // Retorno a posição para 9
      posicoes = 9;
    }
  }

  // Captura o resto da divisão entre soma_digitos dividido por 11
  // Ex.: 196 % 11 = 9
  soma_digitos = soma_digitos % 11;

  // Verifica se soma_digitos é menor que 2
  if (soma_digitos < 2) {
    // soma_digitos agora será zero
    soma_digitos = 0;
  } else {
    // Se for maior que 2, o resultado é 11 menos soma_digitos
    // Ex.: 11 - 9 = 2
    // Nosso dígito procurado é 2
    soma_digitos = 11 - soma_digitos;
  }

  // Concatena mais um dígito aos primeiro nove dígitos
  // Ex.: 025462884 + 2 = 0254628842
  var cpf = digitos + soma_digitos;

  // Retorna
  return cpf;
} // calc_digitos_posicoes

/*
 Valida CPF
 
 Valida se for CPF
 
 @param  string cpf O CPF com ou sem pontos e traço
 @return bool True para CPF correto - False para CPF incorreto
*/
function valida_cpf(valor: string) {
  // Garante que o valor é uma string
  valor = valor.toString();

  // Remove caracteres inválidos do valor
  valor = valor.replace(/[^0-9]/g, '');

  // Captura os 9 primeiros dígitos do CPF
  // Ex.: 02546288423 = 025462884
  var digitos = valor.substr(0, 9);

  // Faz o cálculo dos 9 primeiros dígitos do CPF para obter o primeiro dígito
  var novo_cpf = calc_digitos_posicoes(digitos);

  // Faz o cálculo dos 10 dígitos do CPF para obter o último dígito
  novo_cpf = calc_digitos_posicoes(novo_cpf, 11);

  // Verifica se o novo CPF gerado é idêntico ao CPF enviado
  if (novo_cpf === valor) {
    // CPF válido
    return true;
  } else {
    // CPF inválido
    return false;
  }
} // valida_cpf

/*
 valida_cnpj
 
 Valida se for um CNPJ
 
 @param string cnpj
 @return bool true para CNPJ correto
*/
function valida_cnpj(valor: string) {
  // Garante que o valor é uma string
  valor = valor.toString();

  // Remove caracteres inválidos do valor
  valor = valor.replace(/[^0-9]/g, '');

  // O valor original
  var cnpj_original = valor;

  // Captura os primeiros 12 números do CNPJ
  var primeiros_numeros_cnpj = valor.substr(0, 12);

  // Faz o primeiro cálculo
  var primeiro_calculo = calc_digitos_posicoes(primeiros_numeros_cnpj, 5);

  // O segundo cálculo é a mesma coisa do primeiro, porém, começa na posição 6
  var segundo_calculo = calc_digitos_posicoes(primeiro_calculo, 6);

  // Concatena o segundo dígito ao CNPJ
  var cnpj = segundo_calculo;

  // Verifica se o CNPJ gerado é idêntico ao enviado
  if (cnpj === cnpj_original) {
    return true;
  }

  // Retorna falso por padrão
  return false;
} // valida_cnpj

/*
 valida_cpf_cnpj
 
 Valida o CPF ou CNPJ
 
 @access public
 @return bool true para válido, false para inválido
*/
export function valida_cpf_cnpj(valor: string) {
  // Verifica se é CPF ou CNPJ
  var valida = verifica_cpf_cnpj(valor);

  // Garante que o valor é uma string
  valor = valor.toString();

  // Remove caracteres inválidos do valor
  valor = valor.replace(/[^0-9]/g, '');

  // Valida CPF
  if (valida === 'CPF') {
    // Retorna true para cpf válido
    return valida_cpf(valor);
  }

  // Valida CNPJ
  else if (valida === 'CNPJ') {
    // Retorna true para CNPJ válido
    return valida_cnpj(valor);
  }

  // Não retorna nada
  else {
    return false;
  }
} // valida_cpf_cnpj

/*
 formata_cpf_cnpj
 
 Formata um CPF ou CNPJ

 @access public
 @return string CPF ou CNPJ formatado
*/
export function formata_cpf_cnpj(valor: string) {
  valor = valor.replace(/\D/g, ''); //Remove tudo o que não é dígito

  if (valor.length <= 11) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  } else if (valor.length > 11 && valor.length <= 14) {
    return valor.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');
  }
  return valor;
}

export function formata_cpf_celular(valor: string) {
  valor = valor.replace(/\D/g, ''); //Remove tudo o que não é dígito

  return valor.replace(/(\d{2})(\d{5})(\d{2})/g, '($1) $2-$3');
}


export const bancos = [
  { id: 2, nome: 'Banco do Brasil', codigo: '001' },
  { id: 3, nome: 'Bradesco', codigo: '237' },
  { id: 4, nome: 'Caixa Econômica Federal', codigo: '104' },
  { id: 5, nome: 'Safra', codigo: '074' },
  { id: 6, nome: 'Santander', codigo: '033' },
  { id: 7, nome: 'Nubank', codigo: '260' },
  { id: 8, nome: 'Banco Inter', codigo: '077' },
  { id: 9, nome: 'Banco Votorantim', codigo: '655' },
  { id: 10, nome: 'Banco C6', codigo: '336' },
  { id: 11, nome: 'Banco Original', codigo: '212' },
  { id: 12, nome: 'Mercado Pago', codigo: '323' },
  { id: 13, nome: 'Pagseguro', codigo: '290' }
]

export const dynamicSort = (property: any) => {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a: any, b: any) {
    /* next line works with strings and numbers, 
     * and you may want to customize it to your needs
     */
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  }
}

export function setCookie(name: string, value: any, days: number) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

export function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}