import P from 'prop-types';
import { useEffect, useState } from 'react';
import { Container, StyleValueCart, StyledMenu } from './styles';
import { Box, CssBaseline } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { drawerWidth } from '../../../store/Customization/types';
import { Button, Divider, Menu, MenuItem, Typography } from '@mui/material';
import { removeStorage } from '../../../hooks/useStorage';

import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';
import { POST_DATA } from '../../../services/API';
import { CONTROLEMENSAGEM_AVISO } from '../../../store/ControleMensagemReducer/types';

import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import NotificacoesModal from '../../../pages/Notificacoes/NotificacoesModal';
import { NOTIFICACOES_OPEN } from '../../../store/NotificacoesModalReducer/types';
import * as moment from 'moment';

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const customization = useSelector((state) => state.customization);
    const notificacoesModal = useSelector((state) => state.notificacoesModal);
    const superUser = useSelector((state) => state.superUser);
    const usuarioLogado = useSelector((state) => state.usuarioLogado);
    const leapLovers = useSelector((state) => state.leapLovers);
    const onBoard = useSelector((state) => state.onBoard);
    const [dominio, setDominio] = useState({
        IdDominioLEAP: 0,
        IdUsuarioEmpresa: 0,
        IdProvedor: 0,
        IpServidor: '',
        NomeSiteLeap: '',
        NomeSiteDominioGeral: '',
        DataCriacao: '',
        DataAtualizacao: null,
        FlagAtivo: true
    });

    const [notificacoes, setNotificacoes] = useState([]);
    const [quantidadeNotificacoes, setQuantidadeNotificacoes] = useState(0);
    const [passouMousePorCima, setPassouMousePorCima] = useState(false);
    const [descricaoUserLogado, setDescricaoUserLogado] = useState('');

    const [mostrarItensHeader, setMostrarItensHeader] = useState(true);


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        removeStorage('usrin');
        removeStorage('superUsrin');
        removeStorage('onBoard');
        navigate('/login');
    }

    const getDominios = async () => {
        const result = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${usuarioLogado?.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP&Log=`);
        if (result !== null && result.length > 0) {
            setDominio(result[0]);
        }
    }

    let intervalId = null;
    useEffect(() => {
        (async () => {
            if (usuarioLogado === null) return;

            await getDominios();
            await fetchNotificacoes();

            intervalId = setInterval(async () => {
                await fetchNotificacoes();
            }, 1000 * 60)
        })();

        setMostrarItensHeader(!(location.pathname.replace('/', '') === 'onboard-lojista') && !(location.pathname.replace('/', '') === 'on-board-design-site') && onBoard && onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 1)[0]?.FlagStatus);

        if (leapLovers !== null)
            setDescricaoUserLogado(`${leapLovers.afiliado.Nome}`);
        else
            setDescricaoUserLogado(`${superUser !== null ? superUser?.Email : usuarioLogado?.NomeFantasia} ${superUser !== null && usuarioLogado?.NomeFantasia ? `- ${usuarioLogado?.NomeFantasia}` : ''}`);

        return () => {
            clearInterval(intervalId);
        }
    }, [usuarioLogado, leapLovers, location]);

    const fetchNotificacoes = async () => {        
        if (usuarioLogado === null) return;

        //console.log('fetchNotificacoes ', moment().format('YYYY-MM-DD HH:mm:ss:SSS'));

        const dataInicio = moment().add(-30, 'days').format('YYYY-MM-DD 00:00:00');
        const dataFim = moment().format('YYYY-MM-DD 23:59:59');

        const url = `Notificacao/GetAllNotificacaoIdUsuarioLoja?idUsuarioLoja=${usuarioLogado.IdUsuario}&dataInicio=${dataInicio}&dataFim=${dataFim}&palavraChave=pedido&maxInstances=20`;

        const notificacoes = await POST_DATA(url);
        setQuantidadeNotificacoes(notificacoes.filter(a => !a.FlagVisualizacao).length || 0);
        setNotificacoes(notificacoes);
    }

    return (
        <Container>
            {
                notificacoesModal.open && <NotificacoesModal />
            }
            <CssBaseline />
            <Box style={{ marginLeft: customization.opened ? drawerWidth - 240 : (mostrarItensHeader ? 120 : 20) , width: '100%', display: 'flex', justifyContent: 'start' }}>
                {
                    !(location.pathname.replace('/', '') === 'on-board') &&
                    <Typography style={{ fontWeight: 'bold' }}>({descricaoUserLogado}) - {leapLovers !== null ? leapLovers.afiliado.Loja.Nome : 'Gestão Loja'} {superUser !== null ? <Button onClick={() => navigate('/lojas')}>Mudar loja</Button> : null}</Typography>
                }
            </Box>
            <Box style={{ width: 600, marginRight: 5, cursor: 'pointer', display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                {
                    leapLovers === null &&
                    <>
                        {
                            mostrarItensHeader &&
                            <Button
                                onClick={() => window.open(`https://api.whatsapp.com/send/?phone=11976548907&text=Olá, preciso de ajuda&type=phone_number&app_absent=0`, '_blank')}
                                onMouseMove={() => setPassouMousePorCima(true)}
                                onMouseLeave={() => setPassouMousePorCima(false)}
                                variant='contained' style={{ background: passouMousePorCima ? '#f0f0f0' : 'white', color: '#f1416d', fontWeight: 'bold', marginRight: 15 }}>
                                <QuestionMarkIcon style={{ fontSize: 20, marginRight: 5 }} />
                                Ajuda
                            </Button>
                        }

                        {
                            (!onBoard?.FlagCompletado && mostrarItensHeader) &&
                            <span title='Pendências' >
                                <Box style={{ marginRight: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box style={{ display: 'flex' }}>
                                        <WarningAmberIcon
                                            onClick={() => navigate('/on-board')}
                                            style={{ fontSize: 30, color: '#757575' }}
                                        />
                                        <StyleValueCart>
                                            {onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => a.FlagStatus === false).length || 0}
                                        </StyleValueCart>
                                    </Box>
                                </Box>
                            </span>
                        }

                        {
                            mostrarItensHeader &&
                            <>
                                <span
                                    title='Site da loja'
                                    onClick={() => {
                                        if (dominio === null || dominio.IdDominioLEAP === 0) {
                                            dispatch({
                                                type: CONTROLEMENSAGEM_AVISO,
                                                tipoComponente: 'alert',
                                                titulo: 'Aviso',
                                                message: 'Domínio da loja não configurado',
                                            });
                                        }
                                        else {
                                            window.open(`https://${dominio.NomeSiteLeap}`, '_blank');
                                        }
                                    }}
                                >
                                    <LanguageIcon
                                        style={{ fontSize: 30, marginRight: 20, color: '#757575' }}
                                    />
                                </span>
                                <Box style={{ marginRight: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Box
                                        style={{ display: 'flex' }}
                                        onClick={() => dispatch({ type: NOTIFICACOES_OPEN })}
                                    >
                                        <NotificationsIcon style={{ fontSize: 30, color: '#757575' }} />
                                        {
                                            quantidadeNotificacoes > 0 &&
                                            <StyleValueCart>
                                                {quantidadeNotificacoes}
                                            </StyleValueCart>
                                        }
                                    </Box>
                                </Box>
                                <CalendarMonthIcon onClick={() => navigate('/calendario-vendas')} style={{ fontSize: 30, marginRight: 20, color: '#757575' }} />
                                <HelpOutlineIcon style={{ fontSize: 30, marginRight: 20, color: '#757575' }} />
                                <AccountCircleIcon style={{ fontSize: 30, marginRight: 20, color: '#757575' }} onClick={handleClick} />
                            </>
                        }


                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            style={{ padding: 20 }}
                        >
                            <MenuItem>
                                <Typography style={{ marginRight: 10, fontWeight: 'bold', textAlign: 'end', color: '#757575' }}>{usuarioLogado?.Email}</Typography>
                            </MenuItem>
                            <MenuItem onClick={() => {
                                navigate('/assinatura');
                                handleClose();
                            }}>
                                Assinatura
                            </MenuItem>
                            <MenuItem onClick={() => {
                                navigate('/config-loja');
                                handleClose();
                            }}>
                                Configurações
                            </MenuItem>
                        </Menu>
                    </>
                }

                <LogoutIcon onClick={() => handleLogout()} style={{ fontSize: 25, color: '#757575', marginRight: 20 }} />
            </Box>
        </Container>
    );
}

Header.propTypes = {
    handleLeftDrawerToggle: P.func
};

export default Header;
