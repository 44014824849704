import { Box, Button, Divider, MenuItem, Modal, Tab, Tabs, Typography } from "@mui/material";
import { CloseBtn, ModalContainer, NotificacaoModalContainer } from "./styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ListAltIcon from '@mui/icons-material/ListAlt';
import InfoIcon from '@mui/icons-material/Info';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { getCorByStatusIncludesDescricao } from "../../services/utils/statusPedido";
import * as moment from "moment";
import { POST_DATA } from "../../services/API";
import { NOTIFICACOES_CLOSE } from "../../store/NotificacoesModalReducer/types";
import { useNavigate } from "react-router";
import { CONTROLEMENSAGEM_SUCESSO } from "../../store/ControleMensagemReducer/types";
import Loading from "../../layout/Loader";

import ImgNotificacoes from '../../assets/Ecommerce-notification.png';
import { TimelineDot } from "@mui/lab";
import { truncate } from "../../services/utils/functions";

import LanguageIcon from '@mui/icons-material/Language';

const NotificacoesModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const usuarioLogado = useSelector((state) => state.usuarioLogado);
    const notificacoesModal = useSelector((state) => state.notificacoesModal);

    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false);
    const [quantidadeNotificacoes, setQuantidadeNotificacoes] = useState(0);
    const [notificacoes, setNotificacoes] = useState([]);
    const [notificacoesLojista, setNotificacoeslojista] = useState([]);

    useEffect(() => {
        (async () => {
            await fetchNotificacoes();
        })();
    }, []);

    const fetchNotificacoes = async () => {
        try {
            if (usuarioLogado === null) return;

            setLoading(true);
            const qtd = await POST_DATA(`Notificacao/GetQtdNovasNotificacoesByIdUsuario?idUsuario=${usuarioLogado.IdUsuario}`);
            setQuantidadeNotificacoes(qtd);

            const dataInicio = moment().add(-30, 'days').format('YYYY-MM-DD 00:00:00');
            const dataFim = moment().format('YYYY-MM-DD 23:59:59');

            const url = `Notificacao/GetAllNotificacaoIdUsuarioLoja?idUsuarioLoja=${usuarioLogado.IdUsuario}&dataInicio=${dataInicio}&dataFim=${dataFim}&palavraChave=pedido&maxInstances=20`;
            const notificacoes = await POST_DATA(url);
            setNotificacoes(notificacoes);

            const notificacoesLojista = await POST_DATA(`NotificacaoCRM/GetAllNotificacaoCRMByValorExato?strValorExato=-1&ColunaParaValorExato=idUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=100&order_by=id desc&Log=`);
            setNotificacoeslojista(notificacoesLojista);

            setLoading(false);
        }
        catch (err) {
            setLoading(false);
        }
    }

    const visualizarNotificacoes = async () => {
        if (quantidadeNotificacoes > 0)
            await POST_DATA(`Notificacao/MarcarNotificacoesComoLidas?idUsuario=${usuarioLogado.IdUsuario}`);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return <Modal
        open={notificacoesModal.open}
        onClose={() => [
            dispatch({ type: NOTIFICACOES_CLOSE })
        ]}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        keepMounted
        disableEscapeKeyDown
        disableAutoFocus
    >
        <ModalContainer>
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Notificações</Typography>
                <span style={{ marginRight: 20 }}>
                    <CloseBtn onClick={() => dispatch({ type: NOTIFICACOES_CLOSE })} />
                </span>
            </Box>

            <Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Notificações" {...a11yProps(0)} />
                    <Tab label="Novidades" {...a11yProps(1)} />
                </Tabs>
                {loading && <Loading texto="Buscando notificações" />}
                {
                    !loading && value === 0 &&
                    <Box style={{ marginTop: 10 }}>
                        {
                            notificacoes.length == 0 &&
                            <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={ImgNotificacoes} alt="Notificações" style={{ width: '300px' }} />
                                <Typography style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>Sem notificações</Typography>
                            </Box>
                        }
                        {
                            notificacoes.length > 0 && notificacoes.map((notificacao, index) => <MenuItem
                                key={index}
                                style={{ display: 'flex', justifyContent: 'flex-start' }}
                                disableRipple
                            >
                                <TimelineDot style={{ background: getCorByStatusIncludesDescricao(notificacao._NotificacaoTipo.sDescricao) }}>
                                    {
                                        notificacao._NotificacaoTipo.sDescricao.includes('Pedido') && <ListAltIcon style={{ fontSize: 25, fill: 'white' }} />
                                    }
                                    {
                                        !notificacao._NotificacaoTipo.sDescricao.includes('Pedido') && <InfoIcon style={{ fontSize: 25, fill: 'white' }} />
                                    }
                                </TimelineDot>
                                <Box
                                    onClick={() => {
                                        if (notificacao._NotificacaoTipo.sDescricao.includes('Pedido')) {
                                            navigate(`/pedidos`, { replace: true, state: { idPedido: notificacao.IdPedido } });
                                        }
                                        dispatch({ type: NOTIFICACOES_CLOSE });
                                    }}
                                    style={{ display: 'flex', marginLeft: 15, flexDirection: 'column', alignItems: 'flex-start' }}
                                >
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14, color: getCorByStatusIncludesDescricao(notificacao._NotificacaoTipo.sDescricao) }}>
                                        {notificacao.IdPedido ? `[${notificacao.IdPedido}] ` : ''}
                                        {notificacao._NotificacaoTipo.sDescricao} <span style={{ fontSize: 11, fontWeight: 450 }}>({moment(notificacao.dtNotificacao).format('DD/MM/YYYY HH:mm:ss')})</span>  <DoneAllIcon style={{ marginLeft: 5, fontSize: 18, fill: notificacao.FlagVisualizacao ? 'green' : 'black' }} />
                                    </Typography>
                                    <Typography style={{ fontSize: 12, fontWeight: 450, color: '#ababab' }}><span style={{ fontWeight: 'bold', fontSize: 13 }}>{notificacao._UsuarioAcao?.Nome ? `[${truncate(notificacao._UsuarioAcao?.Nome, 18)}] ` : ''}</span>{notificacao.sDescricao}</Typography>
                                    <Divider style={{ width: '100%', minWidth: 320, marginTop: 10 }} />
                                </Box>
                            </MenuItem>)
                        }
                        <Box style={{ width: 380, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 20, marginBottom: -20 }}>
                            <Button
                                disabled={notificacoes.length === 0}
                                variant="contained" onClick={async () => {
                                    await visualizarNotificacoes();
                                    dispatch({
                                        type: CONTROLEMENSAGEM_SUCESSO,
                                        tipoComponente: 'alert',
                                        titulo: 'Sucesso',
                                        message: 'Sucesso ao marcar notificações como lidas',
                                    });
                                    dispatch({ type: NOTIFICACOES_CLOSE });
                                }}>Marcar como lido</Button>
                            <Button variant="contained" onClick={() => {
                                navigate(`/notificacoes`, { replace: true });
                                dispatch({ type: NOTIFICACOES_CLOSE })
                            }}>Ver notificações</Button>
                        </Box>
                    </Box>
                }
                {
                    !loading && value === 1 &&
                    <Box style={{ marginTop: 10 }}>
                        {
                            !notificacoesLojista || notificacoesLojista.length == 0 &&
                            <Box style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={ImgNotificacoes} alt="Notificações" style={{ width: '300px' }} />
                                <Typography style={{ fontSize: 20, fontWeight: 'bold', textAlign: 'center' }}>Sem notificações</Typography>
                            </Box>
                        }
                        {
                            notificacoesLojista.length > 0 && notificacoesLojista.map((notificacao, index) => <MenuItem
                                key={index}
                                style={{ display: 'flex', justifyContent: 'flex-start' }}
                                disableRipple
                            >
                                <TimelineDot style={{ textWrap: 'wrap', background: getCorByStatusIncludesDescricao(notificacao?._NotificacaoTipoCRM?.sDescricao || '') }}>
                                    <LanguageIcon style={{ fontSize: 25, fill: 'white' }} />
                                </TimelineDot>
                                <Box
                                    onClick={() => {
                                        if (notificacao.sDescricao.includes('Pedido')) {
                                            navigate(`/pedidos`, { replace: true, state: { idPedido: notificacao.IdPedido } });
                                        }
                                        dispatch({ type: NOTIFICACOES_CLOSE });
                                    }}
                                    style={{ display: 'flex', marginLeft: 15, textWrap: 'wrap', flexDirection: 'column', alignItems: 'flex-start' }}
                                >
                                    <Typography style={{ fontWeight: 'bold', fontSize: 14, textWrap: 'wrap', color: getCorByStatusIncludesDescricao(notificacao?._NotificacaoTipoCRM?.sDescricao || '') }}>
                                        <span style={{ fontSize: 11, fontWeight: 450 }}>({moment(notificacao.dtCadastro).format('DD/MM/YYYY HH:mm:ss')})</span>  <DoneAllIcon style={{ marginLeft: 5, fontSize: 18, fill: notificacao.FlagVisualizacao ? 'green' : 'black' }} />
                                    </Typography>
                                    <Typography style={{ fontSize: 12, fontWeight: 450, color: '#ababab' }}><span style={{ fontWeight: 'bold', fontSize: 13 }}>{notificacao._UsuarioAcao?.Nome ? `[${truncate(notificacao._UsuarioAcao?.Nome, 18)}] ` : ''}</span>{notificacao.sDescricao}</Typography>
                                    <Divider style={{ width: '100%', minWidth: 320, marginTop: 10 }} />
                                </Box>
                            </MenuItem>)
                        }
                    </Box>
                }
            </Box>
        </ModalContainer>
    </Modal>
}

export default NotificacoesModal;