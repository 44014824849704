import HomeIcon from '@mui/icons-material/Home';
import GradingIcon from '@mui/icons-material/Grading';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PrintIcon from '@mui/icons-material/Print';
import StarsIcon from '@mui/icons-material/Stars';
import SettingsIcon from '@mui/icons-material/Settings';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import SchoolIcon from '@mui/icons-material/School';
import LanguageIcon from '@mui/icons-material/Language';
import ReportIcon from '@mui/icons-material/Report';
import StoreIcon from '@mui/icons-material/Store';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import StarIcon from '@mui/icons-material/Star';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

// ==============================|| MENU ITEMS ||============================== //

const dashboard = {
    id: 'initial',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Início',
            type: 'item',
            url: '/dashboard',
            icon: HomeIcon,
            breadcrumbs: false
        }
    ]
};

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'pendencias',
            title: 'Pendência',
            url: '/on-board',
            type: 'item',
            icon: ReportIcon,
            target: false
        },
        {
            id: 'pedidos',
            title: 'Pedido',
            type: 'collapse',
            url: '/pedidos',
            icon: GradingIcon,

            children: [
                {
                    id: 'pedidos',
                    title: 'Lista de Pedidos',
                    type: 'item',
                    url: '/pedidos',
                    target: false
                },
                {
                    id: 'carrinos-abandonados',
                    title: 'Carrinhos Abandonados',
                    type: 'item',
                    url: '/pedidos/carrinos-abandonados',
                    target: false
                },
                {
                    id: 'metas',
                    title: 'Metas',
                    type: 'item',
                    url: '/metas',
                    target: false
                },
            ]
        },
        {
            id: 'produto',
            title: 'Loja',
            type: 'collapse',
            url: '/produtos',
            icon: CheckroomIcon,
            children: [
                {
                    id: 'produto',
                    title: 'Produtos',
                    type: 'item',
                    url: '/produtos',
                    target: false
                },     
                {
                    id: 'cupom',
                    title: 'Cupom',
                    type: 'item',
                    url: '/cupons',
                    target: false
                },
                {
                    id: 'produtos-colecoes',
                    title: 'Coleções',
                    type: 'item',
                    url: '/produtos-colecoes',
                    target: false
                },

                {
                    id: 'estoque',
                    title: 'Estoque',
                    type: 'item',
                    url: '/estoque',
                    target: false
                },
                {
                    id: 'marca',
                    title: 'Marcas',
                    type: 'item',
                    url: '/marcas',
                    target: false
                },
                {
                    id: 'categoria-pecas',
                    title: 'Categorias/Peças',
                    type: 'item',
                    url: '/configurar-categorias',
                    target: false
                },
                {
                    id: 'ecommerce-destaque',
                    title: 'Destaques',
                    type: 'item',
                    url: '/ecommerce-destaque',
                    target: false
                },               

                // {
                //     id: 'genero',
                //     title: 'Gêneros',
                //     type: 'item',
                //     url: '/generos',
                //     target: false
                // },
                // {
                //     id: 'produto',
                //     title: 'Produtos',
                //     type: 'item',
                //     url: '/produtos',
                //     target: false
                // },
                // {
                //     id: 'ecommerce-promocoes',
                //     title: 'Promoçoes',
                //     type: 'item',
                //     url: '/ecommerce-promocoes',
                //     target: false
                // },
                // {
                //     id: 'ecommerce-lancamento',
                //     title: 'Lançamentos',
                //     type: 'item',
                //     url: '/ecommerce-lancamento',
                //     target: false
                // },       
                // {
                //     id: 'ecommerce-liquidacao',
                //     title: 'Liquidação',
                //     type: 'item',
                //     url: '/ecommerce-liquidacao',
                //     target: false
                // }, 
                // {
                //     id: 'ecommerce-novidades',
                //     title: 'Novidades',
                //     type: 'item',
                //     url: '/ecommerce-novidades',
                //     target: false
                // }, 
                // {
                //     id: 'ecommerce-mais-vendidos',
                //     title: 'Mais vendidos',
                //     type: 'item',
                //     url: '/ecommerce-mais-vendidos',
                //     target: false
                // }, 
            ]
        },

        {
            id: 'eCommerce',
            title: 'eCommerce',
            type: 'collapse',
            url: '/clientes',
            icon: ShoppingCartCheckoutIcon,

            children: [
                {
                    id: 'relatorio-clientes',
                    title: 'Clientes',
                    type: 'item',
                    url: '/relatorio-clientes',
                    target: false
                },                
                {
                    id: 'avaliacoes',
                    title: 'Avaliações',
                    type: 'item',
                    url: '/avaliacoes',
                    target: false
                },
                {
                    id: 'cupons-uso',
                    title: 'Cupons utilizados',
                    type: 'item',
                    url: '/relatorio-cupons-uso',
                    target: false
                },
                {
                    id: 'avise-me-produtos',
                    title: 'Avise-me',
                    type: 'item',
                    url: '/avise-me-produtos',
                    target: false
                },
                {
                    id: 'newsLetter',
                    title: 'Leads',
                    type: 'item',
                    url: '/newsLetter',
                    target: false
                },
            ]
        },

        {
            id: 'ecommerce-site',
            title: 'Personalizar site',
            type: 'collapse',
            url: '/configurar-ecommerce',
            icon: LanguageIcon,

            children: [
                {
                    id: 'configurar-ecommerce',
                    title: 'Personalizar',
                    type: 'item',
                    url: '/configurar-ecommerce',
                    target: false
                },
                // {
                //     id: 'configurar-ecommerce-onboard',
                //     title: 'Layout 2',
                //     type: 'item',
                //     url: '/configurar-ecommerce-onboard',
                //     target: false
                // },                 
                {
                    id: 'config-loja',
                    title: 'Informações da loja',
                    type: 'item',
                    url: '/config-loja',
                    target: false
                },
                {
                    id: 'redes-sociais',
                    title: 'Rede Sociais',
                    type: 'item',
                    url: '/pagina-em-dev',
                    target: false
                },      
            ]
        },

        {
            id: 'pdv',
            title: 'Ponto de venda',
            url: '/caixa-pdv',
            type: 'collapse',
            icon: LocalMallIcon,
            target: false,
            children: [
                {
                    id: 'caixa-pdv',
                    title: 'Caixa',
                    type: 'item',
                    url: '/caixa-pdv',
                    target: false
                },
                {
                    id: 'vendas-pdv',
                    title: 'Vendas',
                    type: 'item',
                    url: '/vendas-pdv',
                    target: false
                },                
            ]
        },

        {
            id: 'relatorios',
            title: 'Relatório',
            type: 'collapse',
            url: '/relatorio-clientes',
            icon: PrintIcon,

            children: [                
                {
                    id: 'clientes',
                    title: 'Lista de Clientes',
                    type: 'item',
                    url: '/clientes',
                    target: false
                },
                {
                    id: 'vendas-cliente',
                    title: 'Vendas Cliente',
                    type: 'item',
                    url: '/relatorio-vendas-cliente',
                    target: false
                },
                {
                    id: 'vendas-produto',
                    title: 'Vendas Produto',
                    type: 'item',
                    url: '/relatorio-vendas-produto',
                    target: false
                },
                {
                    id: 'relatorio-estoque',
                    title: 'Estoque',
                    type: 'collapse',
                    url: '/relatorio-estoque',
                    target: false,
                    icon: AllInboxIcon,
                    children: [
                        {
                            id: 'rel-relatorio-estoque',
                            title: 'Estoque',
                            type: 'item',
                            url: '/relatorio-estoque',
                            target: false
                        },
                        {
                            id: 'movimentos-estoque',
                            title: 'Movimentação',
                            type: 'item',
                            url: '/movimentos-estoque',
                            target: false
                        },
                    ]
                },                               
                
                {
                    id: 'clientes-vendas',
                    title: 'Detalhe Vendas',
                    type: 'item',
                    url: '/clientes-vendas',
                    target: false
                }
            ]
        },

        {
            id: 'indicadores',
            title: 'Indicador',
            type: 'collapse',
            url: '/indicador-resumo-loja',
            icon: StackedBarChartIcon,

            children: [
                {
                    id: 'indicador-resumo-loja',
                    title: 'Resumo Loja',
                    type: 'item',
                    url: '/indicador-resumo-loja',
                    target: false
                },
                {
                    id: 'indicador-estatistica-loja',
                    title: 'Estatística Loja',
                    type: 'item',
                    url: 'indicador-estatistica-loja',
                    target: false
                }
            ]
        },

        {
            id: 'integracoes',
            title: 'Integração',
            type: 'collapse',
            url: '/formas-pagamento',
            icon: SettingsIcon,

            children: [
                {
                    id: 'pagamentos',
                    title: 'Pagamentos',
                    type: 'item',
                    url: '/formas-pagamento',
                    target: false
                },
                /**
                 {
                    id: 'comunicacao',
                    title: 'Comunicação',
                    type: 'item',
                    url: '/pagina-em-dev',
                    target: false
                },
                 */
                {
                    id: 'fretes-envio',
                    title: 'Fretes e envios',
                    type: 'item',
                    url: '/formas-entrega',
                    target: false
                },
                {
                    id: 'dominios',
                    title: 'Domínios',
                    type: 'item',
                    url: '/config-dominio-leap',
                    target: false
                },
                {
                    id: 'configuracao-site',
                    title: 'Remessa de e-mails',
                    type: 'item',
                    url: '/config-emails',
                    target: false
                },
                {
                    id: 'dados-bancarios-pagamento',
                    title: 'Recebimentos $',
                    type: 'item',
                    url: '/dados-bancarios-pagamento',
                    target: false
                },
            ]
        },

        {
            id: 'marketing',
            title: 'Marketing',
            type: 'collapse',
            url: '/pagina-em-dev',
            icon: StarsIcon,

            children: [
                {
                    id: 'campanhas',
                    title: 'Campanhas',
                    type: 'item',
                    url: '/campanhas',
                    target: false
                },
                {
                    id: 'google-ads',
                    title: 'Google Ads',
                    type: 'item',
                    url: '/marketing-google-analytics',
                    target: false
                },
                {
                    id: 'seo',
                    title: 'SEO',
                    type: 'item',
                    url: '/marketing-seo',
                    target: false
                },

                // {
                //     id: 'recursos-marketing',
                //     title: 'Recursos de marketing',
                //     type: 'item',
                //     url: '/pagina-em-dev',
                //     target: false
                // },
                // {
                //     id: 'recursos-seo',
                //     title: 'Recursos de SEO',
                //     type: 'item',
                //     url: '/pagina-em-dev',
                //     target: false
                // },
                // {
                //     id: 'redes-sociais',
                //     title: 'Redes sociais',
                //     type: 'item',
                //     url: '/pagina-em-dev',
                //     target: false
                // }
            ]
        },

        {
            id: 'marketplace',
            title: 'Marketplace',
            type: 'collapse',
            url: '/marketplace-meus-produtos',
            icon: LocalGroceryStoreIcon,

            children: [
                {
                    id: 'marketplace-meus-produtos',
                    title: 'Meus produtos',
                    type: 'item',
                    url: '/marketplace-meus-produtos',
                    target: false
                },
                {
                    id: 'marketplace-dados-bancarios',
                    title: 'Dados bancários',
                    type: 'item',
                    url: '/marketplace-dados-bancarios',
                    target: false
                }
            ]
        },

        {
            id: 'atacado',
            title: 'Atacado',
            url: '/atacado',
            type: 'collapse',
            icon: StoreIcon,
            target: false,
            children: [
                {
                    id: 'shopping',
                    title: 'Shopping',
                    type: 'item',
                    url: '/atacado',
                    target: false
                },
                {
                    id: 'pedidos-atacado',
                    title: 'Meus pedidos',
                    type: 'item',
                    url: '/pedidos-atacado',
                    target: false
                },
                {
                    id: 'importar',
                    title: 'Importar',
                    type: 'item',
                    url: '/pagina-em-dev',
                    target: false
                }
            ]
        },

        {
            id: 'afiliados',
            title: 'Afiliados',
            type: 'collapse',
            url: '/afiliados',
            icon: StarIcon,

            children: [
                {
                    id: 'influencer',
                    title: 'Influencer',
                    type: 'item',
                    url: '/afiliados',
                    target: false
                },
                {
                    id: 'pedidos-influencers',
                    title: 'Pedidos',
                    type: 'item',
                    url: '/pedido-afiliado',
                    target: false
                }
            ]
        },

        {
            id: 'academy',
            title: 'Academy',
            url: '/academy',
            type: 'collapse',
            icon: SchoolIcon,

            children: [
                {
                    id: 'conteudos',
                    type: 'collapse',
                    title: 'Conteúdos',
                    url: '/academy',
                    target: false,
                    espaco: false,
                    children: [
                        {
                            id: 'conteudos-gestao',
                            title: 'Gestão',
                            type: 'item',
                            url: '/academy',
                            target: false
                        },
                        {
                            id: 'conteudos-financas',
                            title: 'Finanças',
                            type: 'item',
                            url: '/pagina-em-dev',
                            target: false
                        },
                        {
                            id: 'conteudos-marketing',
                            title: 'Marketing',
                            type: 'item',
                            url: '/pagina-em-dev',
                            target: false
                        },
                        {
                            id: 'conteudos-empreendedorismo',
                            title: 'Empreendedorismo',
                            type: 'item',
                            url: '/pagina-em-dev',
                            target: false
                        }
                    ]
                },
                {
                    id: 'ferramentas',
                    type: 'collapse',
                    title: 'Ferramentas',
                    url: '/pagina-em-dev',
                    target: false,
                    espaco: false,
                    children: [
                        {
                            id: 'ferramentas-ebooks',
                            title: 'Ebooks',
                            type: 'item',
                            url: '/pagina-em-dev',
                            target: false
                        },
                        {
                            id: 'ferramentas-ficha-tecnica',
                            title: 'Ficha técnica',
                            type: 'item',
                            url: '/pagina-em-dev',
                            target: false
                        },
                        {
                            id: 'ferramentas-fornecedores',
                            title: 'Fornecedores',
                            type: 'item',
                            url: '/pagina-em-dev',
                            target: false
                        }
                    ]
                },
                {
                    id: 'insights',
                    type: 'collapse',
                    title: 'Tendências',
                    url: '/pagina-em-dev',
                    target: false,
                    espaco: false,
                    children: [
                        {
                            id: 'insights-tendencias',
                            title: 'Tendências',
                            type: 'item',
                            url: '/pagina-em-dev',
                            target: false
                        }
                    ]
                },
            ]
        },

        {
            id: 'xgh',
            title: 'XGH',
            url: '/cadastros-gerais',
            type: 'item',
            icon: AddCircleOutlineIcon,
            target: false
        }
    ]
};


const menuItems = {
    items: [dashboard, pages]
};

export default menuItems;
