import { combineReducers } from 'redux';
import menuPopupRecuder from './MenuPopupReducer';
import popoverReducer from './PopoverReducer';
import modalDialogRecuder from './ModalDialogReducer';
import controleMensagemReducer from './ControleMensagemReducer';
import customizationReducer from './Customization';
import usuarioLogadoReducer from './UsuarioLogadoReducer';
import ordenacaoDashboardRecuder from './OrdenacaoDashboardReducer';
import indicadoresRecuder from './IndicadoresReducer';
import temaLojaReducer from './TemaLojaReducer';
import onBoardRecuder from './OnBoard';
import modalShoppingCartReducer from './ModalShoppingCartReducer';
import superUserReducer from './SuperUser';
import shoppingCartReducer from './ShoppingCartReducer';
import NotificacoesModalReducer from './NotificacoesModalReducer';
import leapLoversRecuder from './LeapLovers';
import dialogConfirmationRecuder from './DialogConfirmation';

const rootReducer = combineReducers({
    superUser: superUserReducer,
    usuarioLogado: usuarioLogadoReducer,
    leapLovers: leapLoversRecuder,
    menuPopup: menuPopupRecuder,
    popoverPopup: popoverReducer,
    modalDialog: modalDialogRecuder,
    controleMensagem: controleMensagemReducer,
    customization: customizationReducer,
    ordenacaoDashboard: ordenacaoDashboardRecuder,
    indicadores: indicadoresRecuder,
    temaLoja: temaLojaReducer,
    onBoard: onBoardRecuder,
    modalShoppingCart: modalShoppingCartReducer,
    shoppingCart: shoppingCartReducer,
    notificacoesModal: NotificacoesModalReducer,
    dialogConfirmation: dialogConfirmationRecuder
});
export default rootReducer;
