import * as React from 'react';

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Box,
  Button,
  CircularProgress,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Modal,
  Pagination,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Add,
  EditOutlined
} from "@mui/icons-material";
import { ImagemSemResultado, truncate } from "../../../services/utils/functions";
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';
import * as moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router";
import { POST_DATA, POST_DATA_FILE, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import { useDispatch, useSelector } from 'react-redux';
import { CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';
import Card from '../../../components/Card';
import { Descricao, Title } from './styles';

import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { convertUrlToImage } from '../../../services/utils/utils';
import DialogConfirmation from '../../../components/DialogConfirmation/DialogConfirmation';
import { DIALOGCONFIRMATION_SET_CLOSE, DIALOGCONFIRMATION_SET_OPEN } from '../../../store/DialogConfirmation/types';

import ImagemDuplicar from '../../../assets/duplicarIcon.png';
import LoadingAllPage from '../../../components/LoadingAllPage';

interface Column {
  id:
  | "descricao"
  | "sku"
  | "valor"
  | "imagem"
  | "disponivelVenda"
  | "dtAlteracao"
  | "dtInclusao"
  | "acoes"
  | "ativo";
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: "right" | "left" | "center";
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: "imagem", label: "Foto", minWidth: 120 },
  { id: "descricao", label: "Produto", minWidth: 150, maxWidth: 200 },
  { id: "sku", label: "SKU", minWidth: 70 },
  { id: "valor", label: "Valor", minWidth: 100 },
  {
    id: "disponivelVenda",
    label: "Estoque",
    minWidth: 100,
    align: "left",
    format: (value: number) => value.toLocaleString("en-US"),
  },
  {
    id: "dtAlteracao",
    label: "Última Alteração",
    minWidth: 170,
    align: "left",
    format: (value: number) => value.toFixed(2),
  },
  {
    id: "ativo",
    label: "Ativo",
    maxWidth: 100,
    align: "right",
  },
  {
    id: "acoes",
    label: "Ações",
    maxWidth: 100,
    align: "center",
  },
];

interface Data {
  imagem: any;
  descricao: string;
  sku: any;
  valor: any;
  disponivelVenda: any;
  dtAlteracao: any;
  acoes: any;
  ativo: any;
}

interface FiltroAPI {
  idUsuario: any,
  idUsuarioVendedor: any,
  idUsuarioComprador: any,
  iPagina: any,
  Sexo: any,
  FlagKids: any,
  Categoria: any,
  Peca: any,
  Tamanho: any,
  Marca: any,
  HashTags: any,
  ValorMinimo: any,
  ValorMaximo: any,
  FlgUsado: any,
  FlgFavoritos: any,
  order_by: any,
  FlgAtivo: any,
  Sku: any,
  sDescricaoEtiqueta: any
}

function createData(
  imagem: any,
  descricao: any,
  sku: any,
  valor: any,
  disponivelVenda: any,
  dtAlteracao: any,
  acoes: any,
  ativo: any
): Data {
  return {
    imagem,
    descricao,
    sku,
    valor,
    disponivelVenda,
    dtAlteracao,
    ativo,
    acoes,
  };
}

const BootstrapDialogTitle = (props: any) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 15,
            top: 35,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ListagemProdutos = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userLoggedIn = useSelector<any, any>((state) => state.usuarioLogado);
  const querySelector = useMediaQuery<any>('(max-width:600px)');

  const [idProdutoExcluir, setIdProdutoExcluir] = React.useState(0);

  const [loadingDuplicar, setLoadingDuplicar] = React.useState(false);
  const [idProdutoDuplicar, setIdProdutoDuplicar] = React.useState(0);

  const [rows, setRows] = React.useState([]);
  const [loadingProduto, setLoadingProduto] = React.useState(false);
  const [tipoFiltro, setTipoFiltro] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const [prods, setProds] = React.useState([]);
  const [totalprodutos, setTotalProdutos] = React.useState(0);
  const [configurouMarcas, setConfigurouMarcas] = React.useState(false);
  const [configurouCategoria, setConfigurouCategoria] = React.useState(false);

  const [ativos, setAtivos] = React.useState(true);

  //Filtros
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPage] = React.useState(0);
  const [totalImagemPorPagina, setTotalImagemPorPagina] = React.useState(0);

  const [abrirModalConfiguracao, setAbrirModalConfiguracao] = React.useState(false);

  const loadTela = React.useRef(false);

  const handleAtivarProduto = async (idImagem: string, currentState: boolean) => {
    try {
      setLoadingProduto(true);
      const endpoint = `Imagem/${currentState ? "Deletar" : "Ativar"}Imagem?Id=${idImagem}`;
      await PUT_DATA(endpoint);
      await buscarProdutos();

      dispatch({
        type: CONTROLEMENSAGEM_SUCESSO,
        tipoComponente: 'alert',
        titulo: 'Sucesso',
        message: `Sucesso ao ${!currentState ? 'ativar' : 'inativar'} o produto`,
      });

      setLoadingProduto(false);
    }
    catch (err) {
      setLoadingProduto(false);
      dispatch({
        type: CONTROLEMENSAGEM_ERRO,
        tipoComponente: 'alert',
        titulo: 'Sucesso',
        message: 'Algo de errado ao tentar alterar status do produto.',
      });
    }
  };

  React.useEffect(() => {
    (async () => {
      if (userLoggedIn === undefined || userLoggedIn === null) return;

      setLoadingProduto(true);

      const responseMarcasLoja = await POST_DATA(`UsuarioEmpresaXMarca/GetAllUsuarioEmpresaXMarcaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
      const responseCategoriasLoja = await POST_DATA(`UsuarioEmpresaXCategoria/GetAllUsuarioEmpresaXCategoriaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
      setConfigurouMarcas(responseMarcasLoja.length > 0);
      setConfigurouCategoria(responseCategoriasLoja.length > 0);
      await buscarProdutos();

      setLoadingProduto(false);

      loadTela.current = true;
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  React.useEffect(() => {
    if (!loadTela.current) return;

    (async () => {
      await buscarProdutos();
    })();
  }, [page]);

  React.useEffect(() => {
    if (!loadTela.current) return;

    (async () => {
      if (!ativos)
        setPage(1);

      if (ativos && page > 1)
        setPage(1);
      else if (page === 1) {
        await buscarProdutos();
      }
    })();
  }, [ativos]);

  const handleDeletarProduto = async (idImagem: number) => {
    try {
      setLoadingDuplicar(true);

      await PUT_DATA(`Imagem/DeletarImagemFisicaLista`, [ idImagem ]);

      dispatch({
        type: CONTROLEMENSAGEM_SUCESSO,
        tipoComponente: 'alert',
        titulo: 'Sucesso',
        message: 'Produto deletado com sucesso',
      });
    }
    catch (err) {
      SalvarLogSentry(err);

      dispatch({
        type: CONTROLEMENSAGEM_ERRO,
        tipoComponente: 'alert',
        titulo: 'Erro ao deletar o produto',
        message: 'Motivo: ' + err.message,
      });
    }
    finally{
      setLoadingDuplicar(false);
    }
  }

  React.useEffect(() => {
    if (prods) {
      let produtos = prods.map((produto) => {
        return createData(
          <img
            style={{ borderRadius: "8px", width: "80px", height: "120px" }}
            src={produto.LocalIIS}
            alt="Produto imagem"
          />,
          <Typography dangerouslySetInnerHTML={{ __html: truncate(produto?._Etiquetas[0]?.sDescricao || '-', 50) }} />,
          <Typography style={{ minWidth: 300, wordWrap: 'break-word' }}>{produto.SkusEtiquetas === null || produto.SkusEtiquetas.length === 0 ? '-' : produto.SkusEtiquetas}</Typography>,
          <Typography>{produto._Etiquetas === null || produto._Etiquetas.length === 0 ? 'R$ 0,00' : produto._Etiquetas[0].mValor.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          })}</Typography>,
          <Typography style={{ textAlign: 'center' }}>
            {/* `${produto.QtdEtiquetasDisponivelVenda}/${produto.QtdTotalEtiquetas}` */}
            {`${produto?._Etiquetas.reduce((soma: number, item: any) => {
              return soma += Number(item._EtiquetaComplemento.reduce((total: number, etc: any) => { return total += etc.iQuantidade }, 0))
            }, 0)}`}
          </Typography>,

          <Typography sx={{ fontSize: 15 }}>
            {produto.DtAlteracao === produto.DtInclusao
              ? moment(produto.DtInclusao).format('DD/MM/YYYY')
              : moment(produto.DtAlteracao).format('DD/MM/YYYY')}
          </Typography>,

          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={() => handleEdit(produto.idImagem)}>
              <EditOutlined />
            </IconButton>
            <IconButton onClick={() => {
              setIdProdutoExcluir(produto.idImagem);
              dispatch({
                type: DIALOGCONFIRMATION_SET_OPEN
              });
            }}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={async () => {
              setIdProdutoDuplicar(produto.idImagem);
              dispatch({
                type: DIALOGCONFIRMATION_SET_OPEN
              });
            }}>
              <img src={ImagemDuplicar} alt='Duplicar' width={'25px'} />
            </IconButton>
          </Box>,

          <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Switch
              onClick={() =>
                handleAtivarProduto(produto.idImagem, produto.FlgAtivo)
              }
              checked={produto.FlgAtivo}
            ></Switch>
          </Box>,
        );
      });

      setRows(produtos);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prods]);

  const handleEdit = (imageIdToEdit: string) => navigate(`./cadastro`, { state: { idProduto: imageIdToEdit } });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleNovoProduto = () => {
    if (!configurouCategoria || !configurouMarcas) {
      setAbrirModalConfiguracao(true);
      return;
    }

    navigate('/produtos/cadastro');
  }

  const buscarProdutos = async (filtro: string = null) => {
    if (userLoggedIn === undefined || userLoggedIn === null) return;
    setLoadingProduto(true);

    let filtroApi: FiltroAPI = {
      idUsuario: userLoggedIn?.IdUsuario,
      idUsuarioVendedor: userLoggedIn?.IdUsuario,
      idUsuarioComprador: 0,
      iPagina: page,
      Sexo: '0,1,2',
      FlagKids: false,
      Categoria: null,
      Peca: null,
      Tamanho: "",
      Marca: "",
      HashTags: null,
      ValorMinimo: 0,
      ValorMaximo: 0,
      FlgUsado: false,
      FlgFavoritos: false,
      order_by: '_Etiquetas.sDescricao asc',
      FlgAtivo: ativos,
      Sku: null,
      sDescricaoEtiqueta: null
    }

    let filtroStr = search;
    if (filtro !== null) filtroStr = filtro;

    let endPoint = `Imagem/GetListImagemFilterPaginationCRM`;

    if (filtroStr.trim().length > 0 && tipoFiltro == 2)
      filtroApi = { ...filtroApi, Sku: filtroStr.trim() }
    else if (filtroStr.trim().length > 0 && tipoFiltro == 1)
      filtroApi = { ...filtroApi, sDescricaoEtiqueta: filtroStr.trim() }

    const result = await PUT_DATA(endPoint, filtroApi);
    result._Imagens.forEach((item: any) => {
      item.SkusEtiquetas = item._Etiquetas.map((et: any) => {
        return et._EtiquetaComplemento.map((etc: any) => {
          return etc.Sku;
        });
      });

      item.SkusEtiquetas = item.SkusEtiquetas.join(',');
      item.SkusEtiquetas = item.SkusEtiquetas.replaceAll(',', ' / ');
    });

    setTotalPage(result.iTotalPagina);
    setTotalImagemPorPagina(result.iTotalImagemPorPagina);
    setProds(result._Imagens);
    setTotalProdutos(result.itotalGeral || 0);
    setLoadingProduto(false);
  }

  const handleDuplicarProduto = async (idImagem: number) => {
    try {
      setLoadingDuplicar(true);

      const produto = await POST_DATA(`Imagem/GetImagemById?Id=${idImagem}&join=true`);

      produto.IdUsuario = userLoggedIn.IdUsuario;
      produto.idImagem = 0;
      produto.DtInclusao = new Date();
      for (const etiqueta of produto._Etiquetas) {
        etiqueta.sDescricao = `${etiqueta.sDescricao} - DUPLICADO`;
        etiqueta.IdEtiqueta = 0;
        etiqueta.DtInclusao = new Date();

        for (const etiquetaComplemento of etiqueta._EtiquetaComplemento) {
          etiquetaComplemento.IdEtiquetaComplemento = 0;
        }
      }

      let imagem = await convertUrlToImage('ImagemPrincipal', produto.LocalIIS);
      let data = new FormData();
      data.append(`imagem`, imagem);
      data.append("objImagem", JSON.stringify(produto));

      const imagemPrincipal = await POST_DATA_FILE(`Imagem/CadastroImagemJs_Loja`, data);
      let idx = 1;
      for (const imagemComplementar of produto._ImagensComplementares) {
        imagemComplementar.idImagem = 0;
        imagemComplementar.IdUsuario = userLoggedIn.IdUsuario;
        imagemComplementar.DtInclusao = new Date();
        imagemComplementar.IdImagemPrincipal = imagemPrincipal.obj.idImagem;
        imagem = await convertUrlToImage(`ImagemComplementar${idx}`, imagemComplementar.LocalIIS);

        data = new FormData();
        data.append("objImagem", JSON.stringify(imagemComplementar));
        data.append(`imagem`, imagem);

        await POST_DATA_FILE(`Imagem/CadastroImagemJs_Loja`, data);
        idx++;
      }

      setLoadingDuplicar(false);
    }
    catch (err) {
      SalvarLogSentry(err);
      setLoadingDuplicar(false);
    }
  }


  return (
    <>
      {
        loadingDuplicar &&
        <LoadingAllPage text={'Aguarde...'} />
      }

      {
        idProdutoDuplicar > 0 &&
        <DialogConfirmation
          title="Duplicar produto?"
          mensagem="Confirma que deseja realizar essa operação ?"
          btn1={{
            title: "Sim", onClick: async () => {
              dispatch({
                type: DIALOGCONFIRMATION_SET_CLOSE
              });

              await handleDuplicarProduto(idProdutoDuplicar);
              await buscarProdutos();
              setIdProdutoDuplicar(0);
            }
          }}
          btn2={{ title: "Cancelar", onClick: null }}
        />
      }

      {
        idProdutoExcluir > 0 &&
        <DialogConfirmation
          title="Deletar produto?"
          mensagem="Confirma que deseja realizar essa operação?"
          btn1={{
            title: "Sim", onClick: async () => {
              dispatch({
                type: DIALOGCONFIRMATION_SET_CLOSE
              });

              await handleDeletarProduto(idProdutoExcluir);
              await buscarProdutos();
              setIdProdutoExcluir(0);
            }
          }}
          btn2={{ title: "Cancelar", onClick: null }}
        />
      }

      <Modal
        key="modal"
        open={abrirModalConfiguracao}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: '0%',
          left: '10%'
        }}
        keepMounted
        disableEscapeKeyDown
        disableAutoFocus
      >
        <Box
          sx={[
            {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
              width: '50%',
              height: 'auto',
              backgroundColor: 'rgb(253, 253, 253)',
            },
          ]}
        >
          <BootstrapDialogTitle id="componenteMensagem" onClose={() => setAbrirModalConfiguracao(false)}>
            <Box sx={{ fontSize: 20, marginLeft: -2 }}>Aviso</Box>
          </BootstrapDialogTitle>
          <Typography>É necessário configurar os seguintes itens para prosseguir:</Typography>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 5 }}>
            {!configurouCategoria && <Button variant='contained' onClick={() => navigate('/categorias', { state: { veioTelaProduto: true } })}>Categorias</Button>}
            {configurouCategoria && !configurouMarcas && <Button variant='contained' onClick={() => navigate('/marcas', { state: { veioTelaProduto: true } })}>Marcas</Button>}
          </Box>
        </Box>
      </Modal>
      <Box sx={{ width: "100%" }}>
        <Box style={{ width: "100%", display: 'flex', justifyContent: 'space-between' }}>
          <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Produtos</Typography>

          <Card style={{ marginLeft: 10, width: 180, minHeight: '80px !important' }}>
            <Title>Total de produtos</Title>
            <Descricao>{totalprodutos}</Descricao>
          </Card>
        </Box>
      </Box>
      <Stack
        direction="row"
        sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap' }}
        justifyContent="space-between"
      >
        <Stack direction="row" gap={2}>
          <FormControl sx={{ marginTop: -2 }}>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={1}
              value={tipoFiltro}
              name="radio-buttons-group"
              onChange={(e) => { setTipoFiltro(Number(e.target.value)) }}
            >
              <FormControlLabel value={1} control={<Radio />} label="Nome" />
              <FormControlLabel value={2} control={<Radio />} label="Sku" />
            </RadioGroup>
          </FormControl>
          <TextField
            label="Pesquisar"
            placeholder={tipoFiltro == 1 ? 'Nome' : 'Sku'}
            size="small"
            sx={{ width: querySelector ? "200px" : "250px" }}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            onKeyDownCapture={(e) => {
              if (e.key === 'Enter') {
                buscarProdutos();
              }
            }}
            InputProps={{
              endAdornment: search.length > 0 && <InputAdornment
                style={{ cursor: 'pointer' }}
                position="end"
                onClick={async () => {
                  setSearch(() => '');
                  await buscarProdutos('');
                }}
              >
                <ClearIcon />
              </InputAdornment>
            }}
          />
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ height: 43 }}
            onClick={async () => {
              if (page === 1)
                await buscarProdutos();
              else
                setPage(1);
            }}
          >
            Pesquisar
          </Button>
        </Stack>

        <Box>
          <FormControlLabel labelPlacement="top" control={<Switch checked={ativos} onChange={() => setAtivos(!ativos)} />} label="Ativos" />
          <Button
            size="large"
            variant="contained"
            disabled={loadingProduto}
            startIcon={<Add />}
            color="primary"
            style={{ marginTop: querySelector ? 10 : 0, height: 43 }}
            onClick={() => handleNovoProduto()}
          >
            NOVO PRODUTO
          </Button>
        </Box>
      </Stack>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        {loadingProduto ? (
          <Stack
            sx={{ height: "150px" }}
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
            <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
          </Stack>
        ) : (
          <>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                          fontWeight: 'bold'
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .map((row, i) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={i}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              {rows.length === 0 && (
                <Stack
                  sx={{ width: "100%", minHeight: "300px", p: 2 }}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                  <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                    Nenhum resultado encontrado.
                  </Typography>
                </Stack>
              )}
            </TableContainer>

            <Box style={{ width: '100%', marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
              <Pagination
                size="large"
                color="primary"
                showFirstButton
                showLastButton
                count={totalPages}
                page={page}
                onChange={handleChangePage}
              />
            </Box>
          </>
        )}
      </Paper>
    </>
  );
}

export default ListagemProdutos;
