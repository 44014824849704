import React from 'react';

// material-ui
import { Box, Paper } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';
import { drawerWidth } from '../../../store/Customization/types';

// project imports
import MenuList from './MenuList';
import logoLeapBranco from '../../../assets/leaplogoblackandwhite.png';
import smallLogoLeap from "../../../assets/Badge.svg";
import { SectionLogo, LogoImagem } from './styles';

import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import SubscriptionPlan from './SubscriptionPlan';
import { styled } from '@mui/material';

// ==============================|| SIDEBAR DRAWER ||============================== //

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    background: '#181818',
    color: '#ffff',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    background: '#181818',
    color: '#ffff',
    width: 95,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: open ? '88px' : drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
    const ehLeapLovers = process.env.REACT_APP_LEAP_LOVERS === 'true';

    //https://codesandbox.io/s/fxxfk0?file=/demo.tsx:1346-1357
    return (
        <Drawer variant="permanent" open={drawerOpen} className='d-print-none'>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: 'auto',
                        minHeight: '100vh',
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        background: '#181818',
                        color: '#ffff'
                    }}
                >
                    <SectionLogo drawerOpen={drawerOpen}>
                        <LogoImagem component='img' drawerOpen={drawerOpen} src={drawerOpen ? logoLeapBranco : smallLogoLeap} />
                        <Paper className="expand-btn" onClick={() => drawerToggle()}>
                            {drawerOpen ? (
                                <KeyboardDoubleArrowLeftIcon style={{ color: 'black' }} />
                            ) : (
                                <KeyboardDoubleArrowRightIcon style={{ color: 'black' }} />
                            )}
                        </Paper>
                    </SectionLogo>

                    {
                        !ehLeapLovers &&
                        <>
                            {drawerOpen && <SubscriptionPlan />}
                        </>
                    }

                    <MenuList />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <SectionLogo drawerOpen={drawerOpen}>
                    <LogoImagem component='img' drawerOpen={drawerOpen} src={drawerOpen ? logoLeapBranco : smallLogoLeap} />
                    <Paper className="expand-btn" onClick={() => drawerToggle()}>
                        {drawerOpen ? (
                            <KeyboardDoubleArrowLeftIcon />
                        ) : (
                            <KeyboardDoubleArrowRightIcon />
                        )}
                    </Paper>
                </SectionLogo>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                </Box>
            </MobileView>
        </Drawer>
    )
};

export default Sidebar;
