import { Fragment, useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import { DELETE_DATA, POST_DATA } from "../../../services/API";
import { Container } from "./styles";
import { Box, Paper, Stack, Typography, CircularProgress, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Switch, TextField, InputAdornment, useMediaQuery, FormControl, Autocomplete, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { pt } from 'date-fns/locale'

import { NumericFormat } from "react-number-format";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import * as moment from "moment";

import IconButton from "@mui/material/IconButton";

import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogConfirmation from "../../../components/DialogConfirmation/DialogConfirmation";
import { DIALOGCONFIRMATION_SET_CLOSE, DIALOGCONFIRMATION_SET_OPEN } from "../../../store/DialogConfirmation/types";

const columns = [
    { id: "descricao", label: "Código", minWidth: 120, maxWidth: 200 },
    {
        id: "FlgPorcentagem",
        label: "Percentual",
        maxWidth: 100,
        align: "left",
    },
    {
        id: "valor",
        label: "Valor",
        minWidth: 145,
        align: "left",
        format: (value) => value.toLocaleString("pt-BR"),
    },
    {
        id: "dataValidade",
        label: "Data de Validade",
        minWidth: 170,
        align: "left",
        format: (value) => value.toFixed(2),
    },
    {
        id: "flgCompra",
        label: "Compra",
        maxWidth: 100,
        align: "left",
    },
    {
        id: "flgFrete",
        label: "Frete",
        maxWidth: 100,
        align: "left",
    },
    {
        id: "quantidade",
        label: "Quantidade",
        minWidth: 100,
        align: "left"
    },
    {
        id: "valorMininoCompra",
        label: "Compras a partir de",
        minWidth: 145,
        align: "left",
        format: (value) => value.toLocaleString("pt-BR"),
    },
    {
        id: "influencer",
        label: "Influencer",
        minWidth: 300,
        align: "left"
    },
    {
        id: "porcentualInfluencer",
        label: "Porcentual influencer",
        minWidth: 165,
        align: "left"
    },
    {
        id: "ativo",
        label: "Ativo",
        maxWidth: 100,
        align: "left",
    },
    {
        id: "acoes",
        label: "Ações",
        maxWidth: 100,
        align: "left",
    },
];


const Cupom = () => {
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([]);

    const [fieldFocus, setFieldFocus] = useState('');

    const querySelector = useMediaQuery('(max-width:600px)');
    const [search, setSearch] = useState('');

    const [afiliados, setAfiliados] = useState([]);

    const [idCupomExcluir, setIdCupomExcluir] = useState(0);
    const [cupons, setCupons] = useState();

    const [somenteAtivos, setSomenteAtivos] = useState(true);

    useEffect(() => {
        (async () => {
            await fetchDados();
        })();
    }, [userLoggedIn, somenteAtivos]);

    useEffect(() => {
        const tmpCupons = cupons?.map(cupom => {
            return <Row cupom={cupom} />
        });

        setRows(tmpCupons || []);
    }, [cupons]);

    const fetchDados = async (filtro = null) => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        setLoading(true);

        let filtroStr = search;
        if (filtro !== null) filtroStr = filtro;

        const resultAfiliados = await POST_DATA(`UsuarioLoginInfluencer/GetUsuarioLoginInfluencerByIdUsuarioLoja?IdUsuarioLoja=${userLoggedIn.IdUsuario}&join=true&Log=`);
        setAfiliados([{ IdUsuarioInfluencer: 0, Nome: 'Nenhum(a)' }, ...resultAfiliados]);

        if (filtroStr.trim().length > 0) {
            const result = cupons.filter(item => item.Descricao.toString().toUpperCase().includes(filtroStr.toUpperCase()));

            result.forEach(item => {
                item.dataValidade = new Date(item.dataValidade);

                if (resultAfiliados.length > 0) {
                    item.InputInfluencer = resultAfiliados.filter(a => a.IdUsuarioLoginInfluencer == item.IdUsuarioInfluencer)[0];
                }
            });
            setCupons(result);
            setLoading(false);
        }
        else {
            const result = await POST_DATA(`Cupom/GetAllCupomByIdUsuario?idUsuario=${userLoggedIn.IdUsuario}&flagAtivo=${somenteAtivos}`);
            result.forEach(item => {
                item.dataValidade = new Date(item.dataValidade);
                if (resultAfiliados.length > 0) {
                    item.InputInfluencer = resultAfiliados.filter(a => a.IdUsuarioLoginInfluencer == item.IdUsuarioInfluencer)[0];
                }
            });
            setCupons(result);
            setLoading(false);
        }
    }

    const handleAdicionar = () => {
        const cupom = {
            IdCupom: 0,
            Descricao: 'NOVO CUPOM',
            Valor: 0,
            FlgCompra: false,
            FlgFrete: false,
            FlgAtivo: true,
            Quantidade: 0,
            dataValidade: null,
            IdUsuarioLoja: userLoggedIn.IdUsuario,
            FlgPorcentagem: false,
            ValorMinimoCompra: 0
        }

        setCupons([cupom, ...cupons]);
        setFieldFocus('Descricao0');
    }

    const handleChangeCupom = (id, descricao, property, value) => {
        const tmpCupons = [...cupons];
        tmpCupons.forEach(item => {
            if (item.IdCupom === id && item.Descricao === descricao) {
                item[property] = value;
            }
        });

        setCupons(tmpCupons);
        setFieldFocus(() => `${property}${id}`);
    }

    const deleteCupom = async () => {
        try {
            const result = await DELETE_DATA(`Cupom/DeleteFisicoCupom?idCupom=${idCupomExcluir}`);
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Cupom excluído com sucesso!',
            });

            await fetchDados();
        }
        catch (err) {
            setLoading(false);
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: err.message,
            });
        }
    }

    const Row = ({ cupom }) => {
        const dispatch = useDispatch();
        const bloquearEdicao = false;

        const handlSalvar = async () => {
            setLoading(true);

            const cupomToSave = { ...cupom };
            cupomToSave.Valor = Number(cupomToSave.Valor.toString().replace(',', '.'));
            cupomToSave.ValorMinimoCompra = Number(cupomToSave.ValorMinimoCompra.toString().replace(',', '.'));
            cupomToSave.IdUsuarioLoja = userLoggedIn.IdUsuario;

            //Verificar se existe cupom na base de dados
            const cupomBase = await POST_DATA(`Cupom/ValidaCupomXUsuario?idUsuario=${userLoggedIn.IdUsuario}&cupom=${cupom.Descricao}`);
            if (cupomToSave.Descricao === null || cupomToSave.Descricao.trim().length < 5 || cupomToSave.Descricao.trim().length > 20) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Cupom deve ter entre 5 a 20 caracteres!',
                });
                setLoading(false);
                return;
            }

            if (cupomToSave.IdCupom === 0 && cupomBase) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Cupom já existente!',
                });
                setLoading(false);
                return;
            }

            if ((cupomToSave.FlgCompra && cupomToSave.FlgFrete) || (!cupomToSave.FlgCompra && !cupomToSave.FlgFrete)) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor selecionar uma opção entre frete ou compra!',
                });
                setLoading(false);
                return;
            }

            if (cupomToSave.FlgPorcentagem && cupomToSave.Valor > 100) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Valor em percentual máximo é de 100%!',
                });
                setLoading(false);
                return;
            }

            if (cupomToSave.IdUsuarioInfluencer > 0 && (cupomToSave.PorcentagemRepasseIndicacao === null || cupomToSave.PorcentagemRepasseIndicacao === 0)) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Um influencer foi vinculado ao cupom. É necessário informar o percentual de comissão!',
                });
                setLoading(false);
                return;
            }

            if (!moment(cupomToSave.dataValidade).isAfter(moment())) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Data de validade deve ser maior ou igual ao dia de hoje!',
                });
                setLoading(false);
                return;
            }

            await POST_DATA(cupomToSave.IdCupom > 0 ? `Cupom/UpdateCupom` : `Cupom/InsertCupom`, cupomToSave);
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Cupom salvo!',
            });

            setLoading(false);
            await fetchDados();
        }

        const handleDesativar = async (status) => {
            setLoading(true);
            const cupomOld = await POST_DATA(`Cupom/GetCupomById?id=${cupom.IdCupom}`);
            cupomOld.FlgAtivo = status;

            await POST_DATA(`Cupom/UpdateCupom`, cupomOld);
            setLoading(false);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: status ? 'Cupom ativado!' : 'Cupom desativado!',
            });

            somenteAtivos(true);
            await fetchDados();

            return true;
        }

        return <Fragment key={cupom.IdCupom}>
            <TableRow>
                <TableCell align="left" style={{ minWidth: 160 }}>
                    <TextField
                        id={`Descricao${cupom.IdCupom}`}
                        disabled={bloquearEdicao}
                        readOnly={bloquearEdicao}
                        type='text'
                        autoFocus={`Descricao${cupom.IdCupom}` === fieldFocus}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        InputProps={{ style: { fontSize: 14 } }}
                        variant='outlined'
                        onClick={() => {
                            setFieldFocus(() => `Descricao${cupom.IdCupom}`);
                        }}
                        onChange={(e) => {
                            handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'Descricao', e.target.value)
                        }}
                        value={cupom.Descricao}
                    />
                </TableCell>
                <TableCell align="left">
                    <Switch
                        id={`FlgPorcentagem${cupom.IdCupom}`}
                        disabled={bloquearEdicao}
                        readOnly={bloquearEdicao}
                        onClick={(e) => handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'FlgPorcentagem', !cupom.FlgPorcentagem)}
                        checked={cupom.FlgPorcentagem}
                    ></Switch>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 50, maxWidth: 50 }}>
                    <NumericFormat
                        id={`Valor${cupom.IdCupom}`}
                        disabled={bloquearEdicao}
                        readOnly={bloquearEdicao}
                        autoFocus={`Valor${cupom.IdCupom}` === fieldFocus}
                        prefix={cupom.FlgPorcentagem ? "% " : "R$ "}
                        defaultValue={0}
                        value={cupom.Valor}
                        variant="outlined"
                        customInput={TextField}
                        decimalSeparator=","
                        onClick={() => {
                            setFieldFocus(() => `Valor${cupom.IdCupom}`);
                        }}
                        onChange={(e) => {
                            const valor = e.target.value.replace(/\D[,]/g, "").replace('R$ ', '').replace('% ', '').trim();
                            handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'Valor', valor)
                        }}
                    />
                </TableCell>
                <TableCell align="left" style={{ minWidth: 195 }}>
                    <LocalizationProvider localeText={pt} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            id={`dataValidade${cupom.IdCupom}`}
                            disabled={bloquearEdicao}
                            readOnly={bloquearEdicao}
                            format="dd/MM/yyyy"
                            value={cupom.dataValidade}
                            onClick={() => {
                                setFieldFocus(() => `dataValidade${cupom.IdCupom}`);
                            }}
                            onChange={(newValue) => handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'dataValidade', newValue)}
                        />
                    </LocalizationProvider>
                </TableCell>
                <TableCell align="left">
                    <Switch
                        id={`FlgCompra${cupom.IdCupom}`}
                        disabled={bloquearEdicao}
                        readOnly={bloquearEdicao}
                        onClick={(e) => handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'FlgCompra', !cupom.FlgCompra)}
                        checked={cupom.FlgCompra}
                    ></Switch>
                </TableCell>
                <TableCell align="left">
                    <Switch
                        id={`FlgFrete${cupom.IdCupom}`}
                        disabled={bloquearEdicao}
                        readOnly={bloquearEdicao}
                        onClick={(e) => handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'FlgFrete', !cupom.FlgFrete)}
                        checked={cupom.FlgFrete}
                    ></Switch>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 50, maxWidth: 50 }}>
                    <TextField
                        id={`Quantidade${cupom.IdCupom}`}
                        disabled={bloquearEdicao}
                        readOnly={bloquearEdicao}
                        type='number'
                        autoFocus={`Quantidade${cupom.IdCupom}` === fieldFocus}
                        InputLabelProps={{ style: { fontSize: 14 } }}
                        InputProps={{ style: { fontSize: 14 } }}
                        variant='outlined'
                        onClick={() => {
                            setFieldFocus(() => `Quantidade${cupom.IdCupom}`);
                        }}
                        onChange={(e) => {
                            handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'Quantidade', Number(e.target.value))
                        }}
                        value={cupom.Quantidade}
                    />
                </TableCell>
                <TableCell align="left" style={{ minWidth: 170 }}>
                    <NumericFormat
                        id={`ValorMinimoCompra${cupom.IdCupom}`}
                        disabled={bloquearEdicao}
                        readOnly={bloquearEdicao}
                        autoFocus={`ValorMinimoCompra${cupom.IdCupom}` === fieldFocus}
                        prefix="R$ "
                        defaultValue={0}
                        value={cupom.ValorMinimoCompra}
                        label="Valor"
                        variant="outlined"
                        customInput={TextField}
                        decimalSeparator=","
                        onClick={() => {
                            setFieldFocus(() => `ValorMinimoCompra${cupom.IdCupom}`);
                        }}
                        onChange={(e) => {
                            const valor = e.target.value.replace(/\D[,]/g, "").replace('R$ ', '').replace('% ', '').trim();
                            handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'ValorMinimoCompra', valor)
                        }}
                    />
                </TableCell>
                <TableCell align="left" style={{ minWidth: 300 }}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="influencer-id"
                            options={afiliados}
                            value={cupom.InputInfluencer || afiliados[0]}
                            defaultValue={0}
                            getOptionLabel={(option) => option?.Nome || ''}
                            onChange={(event, newValue) => {
                                handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'IdUsuarioInfluencer', newValue?.IdUsuarioLoginInfluencer);
                                handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'InputInfluencer', newValue);
                            }}
                            inputValue={cupom?.InputValueInfluencer}
                            onInputChange={(event, newInputValue) => {
                                if (newInputValue === '') handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'IdUsuarioInfluencer', 0);
                                handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'InputValueInfluencer', newInputValue);
                            }}
                            renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Influencer" placeholder="Influencer" />
                            )}
                        />
                    </FormControl>
                </TableCell>
                <TableCell align="left" style={{ minWidth: 170 }}>
                    <NumericFormat
                        id={`PorcentagemRepasseIndicacao${cupom.IdCupom}`}
                        disabled={bloquearEdicao}
                        readOnly={bloquearEdicao}
                        autoFocus={`PorcentagemRepasseIndicacao${cupom.IdCupom}` === fieldFocus}
                        prefix="% "
                        defaultValue={0}
                        value={cupom.PorcentagemRepasseIndicacao}
                        label="% influencer"
                        variant="outlined"
                        customInput={TextField}
                        decimalSeparator=","
                        onClick={() => {
                            setFieldFocus(() => `PorcentagemRepasseIndicacao${cupom.IdCupom}`);
                        }}
                        onChange={(e) => {
                            const valor = e.target.value.replace(/\D[,.]/g, "").replace('R$ ', '').replace('% ', '').replace(',', '.').trim();
                            handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'PorcentagemRepasseIndicacao', valor)
                        }}
                    />
                </TableCell>
                <TableCell align="left">
                    <Switch
                        id={`FlgAtivo${cupom.IdCupom}`}
                        disabled={cupom.IdCupom === 0}
                        readOnly={cupom.IdCupom === 0}
                        onClick={async (e) => {
                            const result = await handleDesativar(!cupom.FlgAtivo);
                            if (result)
                                handleChangeCupom(cupom.IdCupom, cupom.Descricao, 'FlgAtivo', !cupom.FlgAtivo);
                        }}
                        checked={cupom.FlgAtivo}
                    ></Switch>
                </TableCell>
                <TableCell align="left">
                    <Box style={{ width: '100%', display: 'flex' }}>
                        <Button disabled={!moment(cupom.dataValidade, 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD')) && !moment(cupom.dataValidade, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))} onClick={() => handlSalvar()} variant="contained">Salvar</Button>
                        <IconButton onClick={() => {
                            setIdCupomExcluir(cupom.IdCupom);
                            dispatch({
                                type: DIALOGCONFIRMATION_SET_OPEN
                            });
                        }}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                </TableCell>
            </TableRow>
        </Fragment>
    }

    return (
        <Container>
            <DialogConfirmation
                title="Excluir cupom?"
                mensagem="Confirma que deseja realizar essa operação ?"
                btn1={{
                    title: "Sim", onClick: async () => {
                        setLoading(true);

                        dispatch({
                            type: DIALOGCONFIRMATION_SET_CLOSE
                        });

                        await deleteCupom();
                    }
                }}
                btn2={{ title: "Cancelar", onClick: null }}
            />

            <Box sx={{ width: "100%" }}>
                <Box style={{ width: "100%" }}>
                    <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Cupons</Typography>
                </Box>
            </Box>

            <Stack
                direction="row"
                sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap' }}
                justifyContent="space-between"
            >
                <Stack direction="row" gap={2} style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <TextField
                            label="Pesquisar"
                            placeholder='Nome Produto/SKU'
                            size="small"
                            sx={{ width: querySelector ? "200px" : "250px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        await fetchDados('');
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>
                            }}
                        />
                        <Button
                            variant="contained"
                            onClick={() => fetchDados()}
                            style={{ marginLeft: 10, height: '100%', height: 43 }}
                        >
                            Pesquisar
                        </Button>
                    </Box>

                    <Box>
                        <FormControlLabel labelPlacement="top" control={
                            <Switch
                                color="primary"
                                checked={somenteAtivos}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setSomenteAtivos(!somenteAtivos);
                                }}
                            />}
                            label="Ativos"
                        />
                        <Button onClick={() => handleAdicionar()} variant="contained">Adicionar</Button>
                    </Box>
                </Stack>
            </Stack>

            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                {loading ? (
                    <Stack
                        sx={{ height: "150px" }}
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <CircularProgress />
                        <Typography sx={{ mt: 1 }}>Carregando cupons</Typography>
                    </Stack>
                ) : (
                    <>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{
                                                    minWidth: column.minWidth,
                                                    maxWidth: column.maxWidth,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows
                                        .map(row => {
                                            return row;
                                        })}
                                </TableBody>
                            </Table>
                            {rows.length === 0 && (
                                <Stack
                                    sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography fontWeight={800} variant="h6">
                                        Nenhum resultado encontrado.
                                    </Typography>
                                </Stack>
                            )}
                        </TableContainer>
                    </>
                )}
            </Paper>
        </Container>
    )
}

export default Cupom;