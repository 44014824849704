import { Avatar, Box, Typography } from '@mui/material';
import { Container, ContainerImagem } from './styles'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ImageIcon from '@mui/icons-material/Image';
import { useNavigate } from 'react-router';

const CardLoja = ({loja = null}) => {
    const navigate = useNavigate();

    return loja !== null && 
    <Container style={{ cursor: 'pointer', marginTop: 0 }} onClick={() => navigate('/atacado', { state: { loja: loja } }) }>
        {/* {
            (loja.fotoCapaLoja === null || loja.fotoCapaLoja === undefined || loja.fotoCapaLoja.includes('http')) &&
            <ContainerImagem imgSrc={ loja.fotoCapaLoja ? loja.fotoCapaLoja : <ImageIcon /> } alt='Imagem capa' />
        }
        {
            !loja?.fotoCapaLoja?.includes('http') &&
            <img src={loja.fotoLogo} style={{ width: '100%', padding: 10, minHeight: 85, border: '.3px solid #e6e6e6', borderRadius: 10, maxHeight: 85, marginBottom: 60, backgroundRepeat: 'no-repeat' }} />
        } */}
        <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>
            <Avatar style={{ width: 120, height: 120, marginTop: 0 }} src={loja.fotoLogo ? loja.fotoLogo : <AccountCircleIcon /> } />
            <hr style={{ marginBottom: 2 }} />

            <Typography style={{ textAlign: 'center', marginTop: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}>{loja.nome}</Typography>
            <hr style={{ marginTop: 2, marginBottom: 4 }} />
            <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                    <Typography style={{ color: 'gray' }}>à venda</Typography>
                    <Typography style={{ fontWeight: 'bold' }}>{loja.qtdProdutos}</Typography>
                </Box>                
            </Box>
        </Box>
        <hr style={{ marginTop: 4, marginBottom: 2 }} />
    </Container>
}

export default CardLoja;
