import { useEffect, useState } from "react";
import { Container } from "../styles";
import { INTEGRA_VESTI, POST_DATA } from "../../../services/API";
import * as moment from "moment";
import { Box, Button, CircularProgress, IconButton, InputAdornment, Paper, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip, Typography } from "@mui/material";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ManageSearch } from "@mui/icons-material";
import ClearIcon from '@mui/icons-material/Clear';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { CONTROLEMENSAGEM_AVISO } from "../../../store/ControleMensagemReducer/types";
import { ImagemSemResultado, formatDateSimples, salvarCSV } from "../../../services/utils/functions";


const columnsFornecedor = [
    { id: "logo", label: "Logo", minWidth: 90, maxWidth: 100 },
    { id: "id", label: "Id", minWidth: 100 },
    {
        id: "nome",
        label: "Nome",
        maxWidth: 120,
        align: "left",
    }
];

const columnsPedidos = [
    { id: "id", label: "Id", minWidth: 100 },
    { id: "data", label: "Data", minWidth: 90, maxWidth: 100 },
    {
        id: "pagamento",
        label: "Pagamento",
        maxWidth: 120,
        align: "left",
    },
    {
        id: "valor",
        label: "Valor",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "status",
        label: "Status",
        maxWidth: 150,
        align: "left",
    },
    {
        id: "acoes",
        label: "",
        maxWidth: 150,
        align: "left",
    }
];


const PedidosAtacado = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);

    const [pedidosFornecedor, setPedidosFornecedor] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        (async () => {
            const cnpj = userLoggedIn.Cnpj.replace('.', '').replace('/', '').replace('-', '').replace('.', '');
            if (cnpj !== '50108381000103') {
                navigate('/pagina-em-dev');
                return;
            }

            await fecthPedidos();
        })();
    }, []);

    const fecthPedidos = async () => {
        const fornecedores = await POST_DATA(`FornecedoresVesti/GetListFornecedoresVesti`);
        const arrPedidos = [];

        const dataInicio = moment().add(-30, 'days').format('YYYY-MM-DD');
        const dataFim = moment().format('YYYY-MM-DD');

        for (const fornecedorItem of fornecedores) {
            const pedidos = JSON.parse(await INTEGRA_VESTI('GET', `orders/company/${fornecedorItem.Company}?start_date=${dataInicio} 00:00:00&end_date=${dataFim} 23:59:59&page=1&perpage=15`, fornecedorItem.Company, fornecedorItem.ApiKey)).response;
            if(pedidos){
                const arrPeds = [];
                for (const pedido of pedidos) {
                    arrPeds.push(pedido);
                }
    
                arrPedidos.push({
                    fornecedor: fornecedorItem,
                    pedidos: arrPeds
                });
            }
        }

        setPedidosFornecedor(arrPedidos);
        setRows(arrPedidos);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const fetchExcel = async () => {
        if (pedidosFornecedor === null || pedidosFornecedor.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sem dados para gerar o csv!',
            });
            return;
        }

        let dados = `Id; Fornecedor`;
        pedidosFornecedor?.forEach(pedidoFornecedor => {
            dados += `\n${pedidoFornecedor.fornecedor.IdFornecedor}; ${pedidoFornecedor.fornecedor.Nome}`;
            dados += `\nId; Data; Pagamento; Valor; Status`;
            pedidoFornecedor.pedidos.forEach((pedido) => {
                dados += `\n${pedido.id}; ${moment(pedido.created_at).format('DD/MM/YYYY HH:mm:ss')}; ${(pedido.payment.type)}; ${(pedido.summary.total_value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                }))}; ${(pedido.status)} `;
            });
        });

        const url = await salvarCSV(userLoggedIn.IdUsuario, 'PedidosFornecedor.csv', dados);
        window.open(url, '_blank');
    }

    return <Container>
        <Box style={{ width: "100%" }}>
            <Typography style={{ fontWeight: 'bold' }}>Atacado / Pedidos</Typography>
        </Box>
        {/* <Stack
            direction="row"
            sx={{ my: 2, width: "100%", display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}
            justifyContent="space-between"
        >
            <TextField
                label="Pesquisar por nome/cpf"
                placeholder='Pesquisar por nome/cpf'
                size="small"
                sx={{ width: 250 }}
                value={search}
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <ManageSearch style={{ cursor: 'pointer' }} onClick={async () => await fecthPedidos()} />
                    </InputAdornment>,
                    endAdornment: search.length > 0 && (
                        <InputAdornment
                            style={{ cursor: 'pointer', color: 'black' }}
                            position="end"
                            onClick={async () => {
                                setSearch(() => '');
                                await fecthPedidos();
                            }}
                        >
                            <ClearIcon />
                        </InputAdornment>
                    )
                }}
            />

        </Stack> */}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
            {loading ? (
                <Stack
                    sx={{ height: "150px" }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress />
                    <Typography sx={{ mt: 1 }}>Carregando lista</Typography>
                </Stack>
            ) : (
                <>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columnsFornecedor.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                                fontWeight: 'bold'
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    rows.map((row) => {
                                        return <>
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={`fornecedor-${row.fornecedor.IdFornecedor}`}
                                            >
                                                <TableCell key={`logo-fornecedor-${row.fornecedor.IdFornecedor}`} align='left'>
                                                    <img src={row.fornecedor.Logo} alt={'Logo'} style={{ width: 40, marginTop: 5 }} />
                                                </TableCell>
                                                <TableCell key={`id-fornecedor-${row.fornecedor.IdFornecedor}`} align='left'>
                                                    {row.fornecedor.IdFornecedor}
                                                </TableCell>
                                                <TableCell key={`nome-fornecedor-${row.fornecedor.IdFornecedor}`} align='left'>
                                                    {row.fornecedor.Nome}
                                                </TableCell>
                                            </TableRow>

                                            <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={`itens-pedido-${row.fornecedor.IdFornecedor}`}
                                                rowspan={1}
                                            >
                                                <TableCell colSpan={3} key={`itens-itens-${row.fornecedor.IdFornecedor}`} align='left'>
                                                    <TableContainer style={{ width: '100%' }}>
                                                        <Table stickyHeader aria-label="sticky table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {columnsPedidos.map((column) => (
                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                            style={{
                                                                                minWidth: column.minWidth,
                                                                                maxWidth: column.maxWidth,
                                                                                fontWeight: 'bold'
                                                                            }}
                                                                        >
                                                                            {column.label}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {row.pedidos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((roww) => {
                                                                    return (
                                                                        <TableRow
                                                                            hover
                                                                            role="checkbox"
                                                                            tabIndex={-1}
                                                                            key={`pedido-${roww.id}`}
                                                                        >
                                                                            <TableCell key={`id-${roww.id}`} align='left'>
                                                                                {roww.id}
                                                                            </TableCell>
                                                                            <TableCell key={`data-${roww.id}`} align='left'>
                                                                                {moment(roww.created_at).format('DD/MM/YYYY HH:mm:ss')}
                                                                            </TableCell>
                                                                            <TableCell key={`pagamento-${roww.id}`} align='left' style={{ minWidth: 140 }}>
                                                                                {roww.payment.type}
                                                                            </TableCell>
                                                                            <TableCell key={`data-inclusao-${roww.IdUsuarioLoginInfluencer}`} align='left'>
                                                                                {roww.summary.total_value.toLocaleString("pt-BR", {
                                                                                    style: "currency",
                                                                                    currency: "BRL",
                                                                                })}
                                                                            </TableCell>
                                                                            <TableCell key={`flgAtivo-${roww.IdUsuarioLoginInfluencer}`} align='left' style={{ minWidth: 140 }}>
                                                                                {roww.status}
                                                                            </TableCell>
                                                                            <TableCell key={`acoes-${roww.IdUsuarioLoginInfluencer}`} align='left' style={{ minWidth: 140 }}>
                                                                                <Button variant="contained">Importar</Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </TableCell>
                                            </TableRow>
                                        </>
                                    })
                                }

                            </TableBody>
                        </Table>
                        {rows.length === 0 && (
                            <Stack
                                sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                    Nenhum resultado encontrado.
                                </Typography>
                            </Stack>
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage={
                            search
                                ? `${rows.length}/${pedidosFornecedor.length} resultados encontrados para pesquisa "${search}" - pedidosFornecedor por página: `
                                : "pedidosFornecedor por página"
                        }
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
        </Paper>
        <Box style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
                size="small"
                variant="contained"
                style={{ height: '100%', background: 'green', paddingLeft: 10, paddingRight: 10 }}
                onClick={() => fetchExcel()}
            >
                <FileDownloadIcon />
                Excel
            </Button>
        </Box>
    </Container>;
}

export default PedidosAtacado;