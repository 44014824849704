import { Container } from "./styles";
import ImagemOnboard from '../../../assets/imagem-onboard-ljista.png'
import { Box, Grid, TextField, Typography, Button, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import { SET_MENU } from "../../../store/Customization/types";
import { POST_DATA, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import * as moment from "moment";

import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { useNavigate } from "react-router";

import fireworks from '../../../assets/fireworks.gif';
import LoadingAllPage from "../../../components/LoadingAllPage";
import { CONTROLEMENSAGEM_ERRO } from "../../../store/ControleMensagemReducer/types";
import { isMobile } from "../../../services/DeviceServices";
import GoWhatsApp from "../../../components/GoWhatsApp";

const OnBoardLojista = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);

    const [dadosOnboard, setDadosOnboard] = useState(null);
    const [ultimaPagina, setUltimaPagina] = useState(0);

    const [openModalAviso, setOpenModalAviso] = useState(false);

    const [loadingMensagem, setLoadingMensagem] = useState(false);
    const [verificandoLoadingInicial, setVerificandoLoadingInicial] = useState(true);

    useEffect(() => {
        if (userLoggedIn === null) return;

        try {
            (async () => {
                //Onboard Lojista
                setLoadingMensagem(true);

                let onboardLojistaResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=1&idUsuario=${userLoggedIn.IdUsuario}`);

                setLoadingMensagem(false);
                setVerificandoLoadingInicial(false);

                if (onboardLojistaResult.totalPerguntaRespondida >= onboardLojistaResult.totalPergunta) {
                    navigate('/on-board');
                }

                onboardLojistaResult = { ...onboardLojistaResult, paginaAtual: 0 };

                let contador = 1;
                for (const resposta of onboardLojistaResult._QuestionarioPergunta) {
                    resposta.pagina = contador;
                    contador++;
                }

                setUltimaPagina(contador - 1);
                setDadosOnboard(onboardLojistaResult);

                setOpenModalAviso(isMobile());
            })();

            dispatch({ type: SET_MENU, opened: false });

            const handleBeforeUnload = (event) => {
                // Perform actions before the component unloads

                event.preventDefault();
                event.returnValue = 'Você realmente deseja sair do site sem finalizar?';
            };

            return () => {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            }
        }
        catch (err) {
            SalvarLogSentry(err);
        }
        finally {
            setVerificandoLoadingInicial(false);
        }
    }, [userLoggedIn]);

    const handleCheckResposta = (idPergunta, idResposta, text = '') => {
        const perguntas = [...dadosOnboard._QuestionarioPergunta];

        dadosOnboard._QuestionarioPergunta.forEach(pergunta => {
            if (pergunta.IdQuestionarioPergunta === idPergunta) {
                pergunta._QuestionarioResposta.forEach(resposta => {
                    if (pergunta.Tipo === 'RADIO') {
                        if (resposta.IdQuestionarioResposta === idResposta) {
                            resposta.Checked = !resposta.Checked
                        }
                        else {
                            resposta.Checked = false;
                        }
                    }
                    else if (pergunta.Tipo === 'CHECK' && resposta.IdQuestionarioResposta === idResposta) {
                        resposta.Checked = !resposta.Checked
                    }
                    else if (pergunta.Tipo === 'TEXT' && resposta.IdQuestionarioResposta === idResposta) {
                        resposta.RespostaTexto = text;
                    }
                });
            }
        });

        setDadosOnboard({ ...dadosOnboard, _QuestionarioPergunta: perguntas });
    }

    const handleConcluir = async () => {
        try {
            const respostasSalvar = [];

            for (const pergunta of dadosOnboard._QuestionarioPergunta) {
                for (const resposta of pergunta._QuestionarioResposta.filter(a => a.Checked || a.RespostaTexto?.length > 0)) {
                    respostasSalvar.push({
                        "IdQuestionario": pergunta.IdQuestionario,
                        "IdQuestionarioPergunta": pergunta.IdQuestionarioPergunta,
                        "IdQuestionarioResposta": resposta.IdQuestionarioResposta,
                        "IdUsuario": userLoggedIn.IdUsuario,
                        "RespostaTexto": resposta.RespostaTexto || '',
                        "FlagAtivo": true,
                        "DataInclusao": moment().format('YYYY-MM-DD HH:mm:ss')
                    });
                }
            }

            await PUT_DATA(`Questionario/InsertQuestionarioUsuario`, respostasSalvar);

            Swal.fire({
                backdrop: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                title: "Parabéns, Bem Vindo a LeapStyle. Agora vamos configurar sua Loja.",
                width: 600,
                padding: "3em",
                color: "#33cccc",
                imageUrl: fireworks,
                imageHeight: 200,
                imageAlt: "Finalizar",
                confirmButtonText: "Continuar",
                confirmButtonColor: '#F1416C'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate(`/on-board-design-site`);
                }
            });
        }
        catch (err) {
            SalvarLogSentry(err);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                titulo: 'Erro',
                message: 'Ocorreu um erro ao salvar os dados',
                tipoComponente: 'alert'
            });
        }
    }

    const container = {
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    return verificandoLoadingInicial ? <LoadingAllPage text={loadingMensagem ? "Agora vamos configurar a sua loja" : "Buscando dados..."} /> : <Container style={{ paddingLeft: isMobile() ? 20 : 70, paddingRight: isMobile() ? 20 : 70 }}>

        <GoWhatsApp whatsNumber={`11976548907`} />

        {/* {
            openModalAviso && <Modal
                key="modal"
                open={openModalAviso}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '50%',
                    left: '0%'
                }}
            >
                <Box
                    sx={[
                        container,
                        {
                            width: '100%',
                            maxWidth: 350,
                            height: '100%',
                            maxHeight: 300,
                            overflowY: 'auto',
                            borderRadius: 2,
                            backgroundColor: 'white',
                            display: 'flex',
                            flexDirection: 'column'
                        },
                    ]}
                >
                    <Typography style={{ fontWeight: 'bold', fontSize: 16, textAlign: 'center' }}>Para continuar, recomendamos o uso de um computador ou tablet para melhor desempenho e experiência.</Typography>

                    <Button variant="outlined" style={{ marginTop: 70 }} onClick={() => setOpenModalAviso(false)}>Continuar</Button>
                </Box>
            </Modal>
        } */}

        {
            dadosOnboard?.paginaAtual === 0 &&
            <Grid container spacing={2} style={{ paddingRight: isMobile() ? 10 : null }}>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: isMobile() ? 24 : 44, fontWeight: 'bold' }}>Olá, {userLoggedIn.NomeFantasia}</Typography>
                </Grid>

                <Grid item xs={isMobile() ? 12 : 6} style={{ marginTop: isMobile() ? 30 : null }}>
                    {
                        !isMobile() && <Typography style={{ fontSize: 20, width: '100%', paddingRight: isMobile() ? 10 : null, minWidth: isMobile() ? 290 : null, textAlign: isMobile() ? 'justify' : null }}>Divulgue seus produtos, potencialize
                            suas vendas, tenha um estoque
                            controlado. Crie uma história de sucesso!
                        </Typography>
                    }

                    {
                        isMobile() &&
                        <Typography style={{ fontSize: 20, marginTop: 20, width: '100%', paddingRight: isMobile() ? 10 : null, minWidth: isMobile() ? 290 : null, textAlign: isMobile() ? 'justify' : null }}>
                            Estamos prontos para configurar sua loja. Responda algumas perguntas simples para deixá-la quase pronta.
                        </Typography>
                    }
                </Grid>

                <Grid item xs={isMobile() ? 12 : 6} style={{ marginTop: 20, display: 'flex', justifyContent: isMobile() ? 'center' : 'flex-end', marginTop: isMobile() ? 50 : null }}>
                    <Button style={{ background: 'white', color: 'black', fontSize: 16, width: isMobile() ? 240 : 160, borderRadius: 20, fontWeight: 'bold' }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: 1 })}>
                        {isMobile() && <span>Configurar sua loja</span>}
                        {!isMobile() && <span>Começar</span>}
                    </Button>
                </Grid>

                <Grid item xs={12} style={{ marginTop: 20, display: 'flex', justifyContent: 'center', marginTop: isMobile() ? 50 : null }}>
                    <img src={ImagemOnboard} alt='Imagem Onboard Lojista' width={'100%'} style={{ width: 'auto', maxWidth: isMobile() ? 280 : 920 }} />
                </Grid>
            </Grid>
        }

        {
            dadosOnboard?.paginaAtual > 0 && dadosOnboard && dadosOnboard?.paginaAtual !== ultimaPagina && dadosOnboard._QuestionarioPergunta.filter(a => a.Posicao === dadosOnboard.paginaAtual).map(pergunta => {
                return <Grid key={pergunta.IdQuestionarioPergunta} container spacing={2}>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: 24 }}>{pergunta.Descricao}</Typography>
                        {
                            pergunta.Tipo === 'RADIO' &&
                            <Typography style={{ fontSize: isMobile() ? 14 : 18 }}>Marque pelo menos 1 para seguir</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20, display: 'flex', flexDirection: 'column', alignItems: isMobile() ? 'center' : null }}>
                        {
                            pergunta._QuestionarioResposta.map(resposta => {
                                return <Button key={resposta.IdQuestionarioResposta} style={{ background: resposta.Checked ? 'black' : 'white', color: resposta.Checked ? 'white' : 'black', padding: 10, fontSize: isMobile() ? 11.5 : 12.95, width: isMobile() ? 280 : 520, borderRadius: 20, fontWeight: 'bold', marginBottom: 15 }} onClick={() => handleCheckResposta(pergunta.IdQuestionarioPergunta, resposta.IdQuestionarioResposta)}>{resposta.Descricao}</Button>
                            })
                        }
                    </Grid>
                    <Grid item xs={12} style={{ position: 'absolute', right: isMobile() ? 36 : 100, bottom: 60, display: 'flex', justifyContent: 'flex-end' }}>
                        <img src='https://leapstyle.me/wp-content/uploads/2022/12/Logo-Leap-Branco-1024x407.png' width={isMobile() ? 100 : 150} style={{ marginRight: 100 }} />
                        <Box>
                            <Button style={{ background: 'white', color: 'black', fontSize: isMobile() ? 10 : 12.95, width: isMobile() ? 40 : 60, borderRadius: 20, marginRight: 10 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: pergunta.pagina - 1 })}><ArrowBackIosIcon style={{ fontSize: isMobile() ? 18 : null }} /></Button>
                            <Button disabled={pergunta._QuestionarioResposta.filter(a => a.Checked).length === 0} style={{ background: pergunta._QuestionarioResposta.filter(a => a.Checked).length === 0 ? '#dbdbdb' : 'white', color: 'black', fontSize: isMobile() ? 10 : 12.95, width: isMobile() ? 40 : 60, borderRadius: 20 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: pergunta.pagina + 1 })}><ArrowForwardIosIcon style={{ fontSize: isMobile() ? 18 : null }} /></Button>
                        </Box>
                    </Grid>
                </Grid>
            })
        }

        {
            dadosOnboard?.paginaAtual === ultimaPagina &&
            <Grid container spacing={2}>
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ fontSize: 20, width: isMobile() ? 290 : 700 }}><span style={{ fontWeight: 'bold' }}>Temos um treinamento AO VIVO e totalmente GRATUITO</span> para você participar e iniciar da sua loja.</Typography>
                    <Typography style={{ fontSize: 20, width: isMobile() ? 290 : 700, marginTop: isMobile() ? 50 : 30 }}>Não fique de fora, entre agora mesmo no grupo de WhatsApp da nossa equipe para receber todas as informações sobre o treinamento e mais dicas para acelerar a montagem da sua loja virtual.</Typography>
                    <Typography style={{ fontSize: 17, width: isMobile() ? 290 : 700, marginTop: isMobile() ? 50 : 20 }}>Informe seu número WhatsApp</Typography>
                    <TextField
                        inputProps={{
                            maxLength: 11,
                        }}
                        style={{ width: isMobile() ? 290 : 350, marginTop: 10 }}
                        label={'Número whatsapp'}
                        value={dadosOnboard?._QuestionarioPergunta.filter(a => a.pagina === ultimaPagina)[0]?._QuestionarioResposta[0]?.RespostaTexto || ''}
                        onChange={(e) => {
                            handleCheckResposta(dadosOnboard?._QuestionarioPergunta.filter(a => a.pagina === ultimaPagina)[0].IdQuestionarioPergunta, dadosOnboard?._QuestionarioPergunta.filter(a => a.pagina === ultimaPagina)[0]?._QuestionarioResposta[0].IdQuestionarioResposta, e.target.value.replace(/([^\d])+/gim, ''));
                        }}
                    />
                    <Button disabled={dadosOnboard?._QuestionarioPergunta.filter(a => a.pagina === ultimaPagina)[0]?._QuestionarioResposta[0]?.RespostaTexto === undefined || dadosOnboard?._QuestionarioPergunta.filter(a => a.pagina === ultimaPagina)[0]?._QuestionarioResposta[0]?.RespostaTexto?.length < 11} variant="contained" style={{ fontSize: 14, width: isMobile() ? 200 : 220, borderRadius: 20, marginRight: 10, marginTop: 15 }} onClick={() => handleConcluir()}>Concluir</Button>

                    {/* <Button variant="contained" style={{ background: 'white', color: 'black', fontSize: 14, width: 220, borderRadius: 20, marginTop: 15, marginRight: 10 }}>Não quero agora</Button>
                    <Button variant="contained" style={{ background: 'black', color: 'white', fontSize: 14, width: 220, borderRadius: 20, marginTop: 15, marginRight: 10 }}>Agendar treinamento</Button> */}
                </Grid>
                <Grid item xs={12} style={{ position: 'absolute', right: isMobile() ? 26 : 100, bottom: 60, display: 'flex', justifyContent: 'flex-end' }}>
                    <img src='https://leapstyle.me/wp-content/uploads/2022/12/Logo-Leap-Branco-1024x407.png' width={isMobile() ? 100 : 150} style={{ marginRight: 150 }} />
                    <Button style={{ background: 'white', color: 'black', fontSize: 12.95, width: 60, borderRadius: 20, marginRight: 10 }} onClick={() => setDadosOnboard({ ...dadosOnboard, paginaAtual: ultimaPagina - 1 })}><ArrowBackIosIcon /></Button>
                </Grid>
            </Grid>
        }

    </Container>
}

export default OnBoardLojista;