import { Autocomplete, Box, Button, FormControl, Modal, Paper, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Stack, InputAdornment } from "@mui/material";
import Card from "../../../components/Card";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import BootstrapDialogTitle from "../../../components/Modal/BootstrapDialogTitle";
import { POST_DATA, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import { useDispatch, useSelector } from "react-redux";
import { SketchPicker } from 'react-color';
import { ImagemSemResultado } from "../../../services/utils/functions";
import LoadingAllPage from "../../../components/LoadingAllPage";

import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from "react-router";

const columns = [
    { id: "IdCategoria", label: "Id", minWidth: 100 },
    { id: "descricao", label: "Descrição", maxWidth: 120, align: "left", },
    { id: "ativo", label: "Ativo", minWidth: 90, maxWidth: 100 }
];

const CadastrosGerais = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [openModal, setOpenModal] = useState(false);
    const [categorias, setCategorias] = useState([]);
    const [pecas, setPecas] = useState([]);
    const [tipoCadastro, setTipoCadastro] = useState(1);

    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [columnsGrid, setColumnsGrid] = useState(columns);
    const [rows, setRows] = useState([]);
    const [random, setRandom] = useState(null);

    const [cor, setCor] = useState(null);
    const [idAutocomplete, setIdAutoComplete] = useState(0);
    const [inputAutocomplete, setInputAutocomplete] = useState(null);
    const [inputValueAutocomplete, setInputValueAutocomplete] = useState(null);

    const [valorSalvar, setValorSalvar] = useState('');

    const container = {
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        (async () => {
            await fetchDados();
        })();
    }, [tipoCadastro, random]);

    const fetchDados = async () => {
        try {
            setLoading(true);

            setRows([]);
            setColumnsGrid(columns);

            let dados = [];
            let result = [];            

            if (tipoCadastro === 1) {
                result = await POST_DATA(`Categoria/GetAllCategoria?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCategoria&Log=`);
                dados = result.map(r => { return { id: r.IdCategoria, descricao: r.Descricao, flgAtivo: r.FlagAtivo } });
                setCategorias(dados);
            }
            else if (tipoCadastro === 2) {
                result = await POST_DATA(`Material/GetAllMaterial`);
                dados = result.map(r => { return { id: r.IdMaterial, descricao: r.Descricao, flgAtivo: r.FlgAtivo } });
            }
            else if (tipoCadastro === 3) {
                result = await POST_DATA(`Marca/GetAllMarca?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdMarca&Log=`);
                dados = result.map(r => { return { id: r.IdMarca, descricao: r.Nome, flgAtivo: r.FlgAtivo } });
            }
            else if (tipoCadastro === 4) {
                setColumnsGrid([
                    { id: "IdCategoria", label: "Id", minWidth: 100 },
                    { id: "descricao", label: "Descrição", maxWidth: 120, align: "left", },
                    { id: "categoria", label: "Categoria", maxWidth: 120, align: "left", },
                    { id: "ativo", label: "Ativo", minWidth: 90, maxWidth: 100 }
                ]);

                result = await POST_DATA(`Peca/GetAllPeca?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdPeca&Log=`);
                dados = result.map(r => { return { id: r.IdPeca, descricao: r.Nome, categoria: r.IdCategoria, flgAtivo: r.FlgAtivo } });
            }
            else if (tipoCadastro === 5) {
                result = await POST_DATA(`Cor/GetAllCor?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCor&Log=`);
                dados = result.map(r => { return { id: r.IdCor, descricao: r.Descricao, flgAtivo: r.FlagAtivo } });
            }
            else if (tipoCadastro === 6) {
                result = await POST_DATA(`Estampa/GetAllEstampa?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdEstampa&Log=`);
                dados = result.map(r => { return { id: r.IdEstampa, descricao: r.Descricao, flgAtivo: r.FlagAtivo } });
            }
            else if (tipoCadastro === 7) {
                result = await POST_DATA(`NotificacaoCRM/GetAllNotificacaoCRMByValorExato?strValorExato=-1&ColunaParaValorExato=idUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=id&Log=`);
                dados = result.map(r => { return { id: r.id, descricao: r.sDescricao, flgAtivo: r.FlagAtivo } });
            }
            else if (tipoCadastro === 8) {
                setColumnsGrid([
                    { id: "IdCategoria", label: "Id", minWidth: 100 },
                    { id: "peca", label: "Peça", maxWidth: 120, align: "left", },
                    { id: "descricao", label: "Sub Peça", maxWidth: 120, align: "left", },
                    { id: "ativo", label: "Ativo", minWidth: 90, maxWidth: 100 }
                ]);

                result = await POST_DATA(`SubPeca/GetAllSubPeca?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdSubPeca&Log=`);
                dados = result.map(r => { return { id: r.IdSubPeca, idPeca: r.IdPeca, descricao: r.Nome, flgAtivo: r.FlgAtivo } });
            }

            if (search.length > 0)
                setRows(dados.filter(a => a.descricao.toUpperCase().includes(search.toUpperCase())));
            else
                setRows(dados);

            setLoading(false);
        } catch (error) {
            SalvarLogSentry(error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (openModal) {
            setValorSalvar('');
        }
    }, [openModal]);

    const getLabel = () => {
        let label = '';

        switch (tipoCadastro) {
            case 1:
                label = 'Categoria'
                break;
            case 2:
                label = 'Material'
                break;
            case 3:
                label = 'Marca'
                break;
            case 4:
                label = 'Peça'
                break;
            case 5:
                label = 'Cor'
                break;
            case 6:
                label = 'Estampa'
                break;
            case 7:
                label = 'Notificação'
                break;
            default:
                label = '';
                break;
        }

        return label;
    }

    const handleSalvar = async () => {
        if (valorSalvar.length === 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: `Favor informar a ${getLabel()}`,
            });
            return;
        }

        let result = null;
        if (tipoCadastro == 1) { //Categoria
            result = await POST_DATA(`Categoria/GetAllCategoriaByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Descricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCategoria&Log=`, {});

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Categoria/CadastroCategoria`, { Descricao: valorSalvar, FlagAtivo: true });
        }
        else if (tipoCadastro == 2) { //Material
            result = await POST_DATA(`Material/GetAllMaterialByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Descricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdMaterial&Log=`, {});

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Material/InsertMaterial`, { Descricao: valorSalvar, FlgAtivo: true });
        }
        else if (tipoCadastro == 3) { //Marca
            result = await POST_DATA(`Marca/GetAllMarcaByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Nome&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdMarca&Log=`, {});

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Marca/CadastroMarca`, { Nome: valorSalvar, FlgAtivo: true, IdIncluidoPor: userLoggedIn.IdUsuario });
        }
        else if (tipoCadastro == 4) { //Peça            
            result = await POST_DATA(`Peca/GetAllPecaByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Nome&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdPeca&Log=`, {});

            const pecaaXCategoria = result.filter(a => a.IdCategoria == idAutocomplete);
            if (pecaaXCategoria != null && pecaaXCategoria.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Peca/CadastroPeca`, { Nome: valorSalvar, IdCategoria: idAutocomplete, FlgAtivo: true, IdIncluidoPor: userLoggedIn.IdUsuario });
        }
        else if (tipoCadastro == 5) { //Cor
            result = await POST_DATA(`Cor/GetAllCorByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Descricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCor&Log=`, {});

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Cor/CadastroCor`, { Descricao: valorSalvar, hexadecimal: cor, FlagAtivo: true });
        }
        else if (tipoCadastro == 6) { //Estampa
            result = await POST_DATA(`Estampa/GetAllEstampaByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Descricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdEstampa&Log=`, {});

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`Estampa/CadastroEstampa`, { Descricao: valorSalvar, FlagAtivo: true });
        }
        else if (tipoCadastro == 7) { //Notificação
            result = await POST_DATA(`NotificacaoCRM/GetAllNotificacaoCRMByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=sDescricao&fSomenteAtivos=true&join=true&maxInstances=0&order_by=id&Log=`);

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`NotificacaoCRM/CadastroNotificacaoCRM`, { idTipoNotificacaoCRM: 1, idUsuarioEmpresa: -1, sDescricao: valorSalvar, FlagAtivo: true });
        }
        else if (tipoCadastro == 8) { //Sub Peças
            result = await POST_DATA(`SubPeca/GetAllSubPecaByValorExato?strValorExato=${valorSalvar}&ColunaParaValorExato=Nome&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdSubPeca&Log=`);

            if (result != null && result.length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: `Já existem o seguinte item salvo`,
                });
                return;
            }

            result = await PUT_DATA(`SubPeca/CadastroSubPeca`, { IdPeca: idAutocomplete, IdIncluidoPor: -1, Nome: valorSalvar, FlgAtivo: true });
        }

        if (result) {
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: `Sucesso ao salvar o item`,
            });
            setOpenModal(false);
        }

        await fetchDados();
    }

    return (
        <Container>
            {
                loading &&
                <LoadingAllPage text={`Buscando por ${getLabel()}...`} />
            }

            {
                openModal &&
                <Modal
                    key="modal"
                    open={openModal}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '10%'
                    }}
                    keepMounted
                    disableAutoFocus
                >
                    <Box
                        sx={[
                            container,
                            {
                                width: '50%',
                                height: 'auto',
                                borderRadius: 2,
                                backgroundColor: 'white',
                            },
                        ]}
                    >
                        <BootstrapDialogTitle id="componenteMensagem" onClose={() => setOpenModal(false)}>
                            <Box sx={{ fontSize: 20, marginLeft: -2 }}>Cadastrar {getLabel()}</Box>
                        </BootstrapDialogTitle>

                        <Box style={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                id="cadastrp"
                                label={getLabel()}
                                placeholder={getLabel()}
                                variant="outlined"
                                type='text'
                                value={valorSalvar}
                                onChange={(e) => {
                                    setValorSalvar(e.target.value);
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 16 }
                                }}
                            />
                        </Box>

                        {
                            tipoCadastro === 4 &&
                            <Box style={{ width: '100%', marginTop: 10 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id={`categoria`}
                                        options={categorias}
                                        value={inputAutocomplete || categorias[0]}
                                        defaultValue={0}
                                        getOptionLabel={(option) => option?.Descricao || 'Acessórios'}
                                        onChange={(event, newValue) => {
                                            setIdAutoComplete(newValue?.IdCategoria);
                                            setInputAutocomplete(newValue);
                                        }}
                                        inputValue={inputValueAutocomplete}
                                        onInputChange={(event, newInputValue) => {
                                            if (newInputValue === '') setIdAutoComplete(0);
                                            setIdAutoComplete(newInputValue?.IdCategoria);
                                            setInputValueAutocomplete(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Categoria" placeholder="Categoria" />
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        }

                        {
                            tipoCadastro === 8 &&
                            <Box style={{ width: '100%', marginTop: 10 }}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        id={`peca`}
                                        options={pecas}
                                        value={inputAutocomplete || pecas[0]}
                                        defaultValue={0}
                                        getOptionLabel={(option) => option?.Nome}
                                        onChange={(event, newValue) => {
                                            setIdAutoComplete(newValue?.IdPeca);
                                            setInputAutocomplete(newValue);
                                        }}
                                        inputValue={inputValueAutocomplete}
                                        onInputChange={(event, newInputValue) => {
                                            if (newInputValue === '') setIdAutoComplete(0);
                                            setIdAutoComplete(newInputValue?.IdPeca);
                                            setInputValueAutocomplete(newInputValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" label="Peça" placeholder="Peça" />
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        }

                        {
                            tipoCadastro === 5 &&
                            <Box style={{ marginTop: 10 }}>
                                <SketchPicker
                                    color={cor || '#fffff'}
                                    onChangeComplete={(color) => {
                                        setCor(color.hex);
                                    }}
                                />
                            </Box>
                        }

                        <Box style={{ width: '100%', marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button variant="contained" onClick={() => handleSalvar()}>Salvar</Button>
                        </Box>
                    </Box>
                </Modal>
            }

            <Box style={{ width: '100%' }}>
                <Typography style={{ fontWeight: 'bold', fontSize: 18 }}>Cadastros gerais</Typography>
            </Box>

            <Box style={{ width: '100%', display: 'flex', marginTop: 10 }}>
                <Button variant="contained" style={{ background: tipoCadastro === 1 ? 'black' : null }} onClick={() => {
                    setTipoCadastro(1);
                }}>Categoria</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 2 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(2);
                }}>Material</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 3 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(3);
                }}>Marca</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 4 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(4);
                }}>Peça</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 8 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(8);
                }}>Sub Peça</Button>

                <Button style={{ marginLeft: 5, background: tipoCadastro === 5 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(5);
                }}>Cor</Button>
                <Button style={{ marginLeft: 5, background: tipoCadastro === 6 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(6);
                }}>Estampa</Button>
                <Button style={{ marginLeft: 5, background: tipoCadastro === 7 ? 'black' : null }} variant="contained" onClick={() => {
                    setTipoCadastro(7);
                }}>Notificação</Button>
                <Button style={{ marginLeft: 5, background: tipoCadastro === -1 ? 'black' : null }} variant="contained" onClick={() => {
                    navigate('/onboard-lojista');
                }}>Onboard Lojista</Button>
                <Button style={{ marginLeft: 5, background: tipoCadastro === -1 ? 'black' : null }} variant="contained" onClick={() => {
                    navigate('/on-board-design-site');
                }}>Onboard Site</Button>
            </Box>

            <Box style={{ width: '100%' }}>
                <Card style={{ marginTop: 10 }}>
                    <Box style={{ width: '100%', marginBottom: 15, display: 'flex', justifyContent: 'space-between' }}>
                        <TextField
                            label="Pesquisar"
                            placeholder='Descrição'
                            size="small"
                            sx={{ width: "300px" }}
                            value={search}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            InputProps={{
                                startAdornment: search.length > 0 && <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="start"
                                    onClick={async () => {
                                        setSearch(() => '');
                                        setRandom(Math.random());
                                    }}
                                >
                                    <ClearIcon />
                                </InputAdornment>,
                                endAdornment: <InputAdornment
                                    style={{ cursor: 'pointer' }}
                                    position="end"
                                    onClick={async () => {
                                        await fetchDados();
                                    }}
                                >
                                    <SearchIcon />
                                </InputAdornment>
                            }}
                        />

                        <Button style={{ marginLeft: 5 }} variant="contained" onClick={() => {
                            setOpenModal(true);
                        }}>Novo</Button>
                    </Box>
                    <TableContainer sty>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columnsGrid.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                minWidth: column.minWidth,
                                                maxWidth: column.maxWidth,
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell key={`img-${row.id}`} align='left'>
                                                {row.id}
                                            </TableCell>
                                            {
                                                tipoCadastro === 8 &&
                                                <TableCell key={`categoria-${row.id}`} align='left'>
                                                    {pecas.filter(a => a.IdPeca === row.IdPeca)[0]?.Nome || "-"}
                                                </TableCell>
                                            }
                                            <TableCell key={`nome-${row.id}`} align='left'>
                                                {row.descricao}
                                            </TableCell>
                                            {
                                                tipoCadastro === 4 &&
                                                <TableCell key={`categoria-${row.id}`} align='left'>
                                                    {row.categoria}
                                                </TableCell>
                                            }
                                            <TableCell key={`ativo-${row.id}`} align='left'>
                                                {row.flgAtivo ? 'Sim' : 'Não'}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                        {rows.length === 0 && (
                            <Stack
                                sx={{ width: "100%", minHeight: "300px", p: 2 }}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <img style={{ width: "120px" }} src={ImagemSemResultado} alt='Sem resultados' />
                                <Typography fontWeight={800} variant="h6" style={{ marginTop: 10 }}>
                                    Nenhum resultado encontrado.
                                </Typography>
                            </Stack>
                        )}
                    </TableContainer>
                </Card>
            </Box>

        </Container>
    )
}

export default CadastrosGerais;