import * as Yup from 'yup';
import * as Styled from './styles';
import { v4 as uuidv4 } from 'uuid';
import {
    Alert,
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Pagination,
    Radio,
    RadioGroup,
    Select,
    Slide,
    Stack,
    Switch,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from '../../../store/ControleMensagemReducer/types';
import { useLocation, useNavigate } from 'react-router';
import ColorPicker from '../../../components/ColorPicker';
import InputText from '../../../components/InputText';
import { NumericFormat } from "react-number-format";
import { POST_DATA, POST_DATA_FILE, POST_DATA_SERVICOS_ROBO, PUT_DATA, SalvarLogSentry, saveImagemConvertedWebp } from '../../../services/API';

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import UploadImagem from '../../../components/UploadImagem';
import TableTamanhosSelecionados from './TableTamanhosSelecionados';
import Loader from '../../../ui-component/Loader';
import * as moment from 'moment';
import { SET_DADOS_ON_BOARD } from '../../../store/OnBoard/types';
import { atualizarOnboardStore } from '../../../services/utils/functions';
import useDialogConfirmationAsync from '../../../components/DialogConfirmation/DialogConfirmationAsync';

import './styles';
import LoadingAllPage from '../../../components/LoadingAllPage';
import Editor from '../../../components/QuillEditor';
import ProductList from '../../../components/ProductList';
import Loading from '../../../layout/Loader';

const getObjEtiqueta = (data = null) => {
    const verificacao = data?.IdEtiqueta !== undefined && data?.IdEtiqueta !== null;

    if (verificacao) {
        data._EtiquetaComplemento = data._EtiquetaComplemento.map((item) => {
            return { ...item, Descricao: item?.Tamanho?.Descricao, qtd: item.iQuantidade, visivelListagem: true };
        });
    }

    const dados = {
        Index: 1,
        IdEtiqueta: verificacao ? data.IdEtiqueta : 0,
        idImagem: verificacao ? data.idImagem : 0,
        IdUsuario: verificacao ? data.IdUsuario : 0,
        IdPeca: verificacao ? data._Peca.IdPeca : 0,
        IdSubPeca: verificacao ? data._Peca.IdSubPeca : 0,
        IdMarca: verificacao ? data._Marca.IdMarca : 0,
        IdEstampa: verificacao ? data?._Estampa?.IdEstampa : 0,
        IdMaterial: verificacao ? data?._Material?.IdMaterial : 0,
        _EtiquetaComplemento: verificacao ? data._EtiquetaComplemento : [],
        IdCor: verificacao ? data?._Cor?.IdCor : 0,
        X: verificacao ? data.X : '',
        Y: verificacao ? data.Y : '',
        FlagAtivo: verificacao ? data.FlagAtivo : true,
        sDescricao: verificacao ? data.sDescricao : '',
        iSexo: verificacao ? data.iSexo : 0,
        FlagPecaNova: verificacao ? data.FlagPecaNova : true,
        FlagKids: verificacao ? data.FlagKids : false,
        FlagDisponivelVenda: true,
        FlagNovidade: verificacao ? data.FlagNovidade : false,
        FlagMaisVendido: verificacao ? data.FlagMaisVendido : false,
        FlagPecaUnica: verificacao ? data.FlagPecaUnica : false,
        mPorcentoDesconto: verificacao ? data.mPorcentoDesconto : 0,
        mValor: verificacao ? data.mValor : 0,
        mValorCusto: verificacao ? data.mValorCusto : 0,
        sTamanho: verificacao ? data.sTamanho : 0,
        Peso: verificacao ? data.Peso : 0,
        Altura: verificacao ? data.Altura : 0,
        Largura: verificacao ? data.Largura : 0,
        Profundidade: verificacao ? data.Profundidade : 0,
        idPublicoAlvo: verificacao ? data.idPublicoAlvo : -1,
        Sku: verificacao ? data.Sku : '',
        _Peca: verificacao ? data._Peca : null,

        iDisponibilidade: verificacao ? (data.iDisponibilidade || 0) : 3,

        Material: verificacao ? data._Material : null,
        InputMaterial: verificacao ? data._Material : '',
        InputValueMaterial: verificacao ? data?._Material?.Descricao : '',

        IdCategoria: verificacao ? data._Peca.Categoria.IdCategoria : null,
        Categoria: verificacao ? data._Peca.Categoria : null,
        InputCategoria: verificacao ? data._Peca.Categoria : '',
        InputValueCategoria: verificacao ? data._Peca.Categoria.Descriccao : '',

        Peca: verificacao ? data._Peca : null,
        InputPeca: verificacao ? data._Peca : '',
        InputValuePeca: verificacao ? data._Peca.Nome : '',

        SubPeca: verificacao ? data._SubPeca : null,
        InputSubPeca: verificacao ? data._SubPeca : '',
        InputValueSubPeca: verificacao ? data._SubPeca?.Nome || '' : '',

        Cor: verificacao ? data.Cor : null, //Cor da etiqueta

        _Cor: verificacao ? data._Cor : null, //Cor da peça
        InputCor: verificacao ? data._Cor : '',
        InputValueCor: verificacao ? data?._Cor?.Descricao : '',

        Estampa: verificacao ? data._Estampa : null,
        InputEstampa: verificacao ? data._Estampa : '',
        InputValueEstampa: verificacao ? data?._Estampa?.Descricao : '',

        Marca: verificacao ? data._Marca : '',
        InputMarca: verificacao ? data._Marca : '',
        InputValueMarca: verificacao ? data._Marca.Nome : '',

        // InputTamanho: null,
        // InputValueTamanho: null,

        // InputCorPeca: null,
        // InputValueCorPeca: null,

        // InputEstampa: null,
        // InputValueEstampa: null,

        FlagPromocao: false,
        FlgLoja: false
    }

    return dados;
}

const Transition = forwardRef(function Transition(
    props,
    ref
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const produtoInitial = (data = null) => {
    const verificacao = data?.idImagem !== undefined && data?.idImagem !== null;
    let etiquetas = [getObjEtiqueta()];

    if (verificacao && data._Etiquetas) {
        etiquetas = [];
        data._Etiquetas.map((etq) => {
            return etiquetas.push(getObjEtiqueta(etq));
        });
    }

    if (etiquetas.length === 0) {
        const etq = getObjEtiqueta();
        etq.idImagem = data?.idImagem;
        etq.IdUsuario = data?.IdUsuario;

        etiquetas.push(etq);
    }

    const descricaoDetalhePadrao = `<p>Modelagem:</p><p>Manga:</p><p>Condição:</p><p>Estampa:</p><p><br>Medidas da peça no tamanho Único</p><ul><li>Comprimento da manga: 0cm</li><li>Largura da manga: 0cm</li><li>Comprimento da peça: 0cm</li><li>Busto: 0cm</li></ul>`;
    const descricaoMedidasModeloPadrao = `<p>Medidas do Modelo: Altura 0cm/ Busto: 0cm/ Cintura: 0cm/ Quadril: 0cm</p>`;
    const arrayImagens = [
        { idImagem: 0, LocalIIS: null, FlgAtivo: true, FlgLoja: true },
        { idImagem: 0, LocalIIS: null, FlgAtivo: true, FlgLoja: true },
        { idImagem: 0, LocalIIS: null, FlgAtivo: true, FlgLoja: true },
        { idImagem: 0, LocalIIS: null, FlgAtivo: true, FlgLoja: true },
        { idImagem: 0, LocalIIS: null, FlgAtivo: true, FlgLoja: true },
        { idImagem: 0, LocalIIS: null, FlgAtivo: true, FlgLoja: true }
    ];

    const imagensComplementares = [];
    if (data?._ImagensComplementares) {
        let countInativo = 0;
        data?._ImagensComplementares.map(item => {
            if (item.FlgAtivo)
                imagensComplementares.push(item);
            else
                countInativo++;
        });
        if (countInativo > 0) {
            for (let index = 0; index < countInativo; index++) {
                imagensComplementares.push({ idImagem: 0, LocalIIS: null, FlgAtivo: true, FlgLoja: true });
            }
        }

        arrayImagens.slice(data?._ImagensComplementares?.length, 6).filter(item => item.FlgAtivo === true).map(item => imagensComplementares.push(item));
    }

    const dados = {
        idImagem: verificacao ? data.idImagem : 0,
        NomeProduto: verificacao ? etiquetas[0].sDescricao : '',
        IdUsuario: verificacao ? data.IdUsuario : 0,
        Descricao: verificacao ? data.Descricao : '',
        DescricaoDetalhe: verificacao ? data.DescricaoDetalhe !== null && data.DescricaoDetalhe !== undefined ? data.DescricaoDetalhe : descricaoDetalhePadrao : descricaoDetalhePadrao,
        DescricaoMedidasModelo: verificacao ? data.DescricaoMedidasModelo !== null && data.DescricaoMedidasModelo !== undefined ? data.DescricaoMedidasModelo : descricaoMedidasModeloPadrao : descricaoMedidasModeloPadrao,
        FlgAtivo: verificacao ? data.FlgAtivo : false,
        FlgDestaque: verificacao ? data.FlgDestaque : false,
        FlgLiberadoLeap: verificacao ? data.FlgLiberadoLeap : false,
        FlgLiberadoShopLeap: verificacao ? data.FlgLiberadoShopLeap : false,
        FlgLoja: true,

        Tag: verificacao ? data.Tag : '',
        QuantidadeEtiquetas: verificacao ? etiquetas.length : 1,
        Etiquetas: etiquetas,
        ImagemSrc: verificacao ? data.LocalIIS : null,
        _ImagensComplementares: verificacao ? data?._ImagensComplementares.length > 0 ? imagensComplementares : arrayImagens : arrayImagens
    }

    return dados;
}

export const ProdutoCadastro = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLoggedIn = useSelector((store) => store.usuarioLogado)
    const customization = useSelector((state) => state.customization);
    const onBoard = useSelector((state) => state.onBoard);
    const themeStorage = useSelector((store) => store.temaLoja);

    const carregouTela = useRef(null);

    const [getConfirmationUpdateCategoria, ConfirmationUpdateCategoria] = useDialogConfirmationAsync();
    const [getConfirmationProdutoAtivo, ConfirmationProdutoAtivo] = useDialogConfirmationAsync();
    const [getConfirmationExibirShopLeap, ConfirmationExibirShopLeap] = useDialogConfirmationAsync();
    const [getConfirmationExibirNoApp, ConfirmationExibirNoApp] = useDialogConfirmationAsync();
    const querySelector = useMediaQuery('(max-width:600px)');


    const [loading, setLoading] = useState(false);
    const [loadingOpenIA, setLoadingOpenIA] = useState(false);
    const [salvando, setSalvando] = useState(false);
    const [etiquetaSelecionada, setEtiquetaSelecionada] = useState(0);
    const [produto, setProduto] = useState(produtoInitial());
    const [materiais, setMateriais] = useState([]);
    const [categorias, setCategorias] = useState([]);
    const [pecas, setPecas] = useState([]);
    const [subPecas, setSubPecas] = useState([]);
    const [coresPecas, setCoresPecas] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [estampas, setEstampas] = useState([]);

    const [tabSelecionada, setTabSelecionada] = useState(0);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPage] = useState(0);
    const [loadingRelacionados, setLoadingRelacionados] = useState(false);
    const [listaProdutosRelacionados, setListaProdutosRelacionados] = useState([]);
    const [produtosRelacionados, setProdutosRelacionados] = useState([]);

    const [imagensComplementares, setImagensComplementares] = useState([]);

    const [isEdicao, setIsEdicao] = useState(false);
    const [tamanhoSelecionadoEC, setTamanhoSelecionadoEC] = useState([]);
    const [corPecaSelecionadaEC, setCorPecaSelecionadaEC] = useState([]);
    const [estampaSelecionadaEC, setEstampaSelecionadaEC] = useState([]);
    const [skuEC, setSkuEC] = useState([]);
    const [listaTamanhos, setListaTamanhos] = useState([]);
    const [changeCategoria, setChangeCategoria] = useState(null);
    const [changePeca, setChangePeca] = useState(null);
    const [open, setOpen] = useState(false);

    const [idsCategoriaOld, setIdsCategoriaOld] = useState([]);
    const [novaCategoriaSelecionada, setNovaCategoriaSelecionada] = useState(null);
    const [novoInputValueCategoriaSelecionada, setNovoInputValueCategoriaSelecionada] = useState(null);

    const [sexosLoja, setSexosLoja] = useState([]);
    const [categoriasLoja, setCategoriasLoja] = useState([]);

    const [temaMenus, setTemaMenus] = useState([]);
    const [temaMenusImagem, setTemaMenusImagem] = useState([]);

    useEffect(() => {
        if (userLoggedIn === null) return;
        if (carregouTela.current) return;

        carregouTela.current = true;

        (async () => {
            setLoading(true);

            const respSexosLoja = await POST_DATA(`UsuarioEmpresaXSexo/GetAllUsuarioEmpresaXSexoByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);

            //Feito isso dia 02/08/2024 pq na API ele verifica de outra forma 
            respSexosLoja.forEach((item) => {
                item.IdSexo = item.IdSexo + 1;
                item._Sexo.SEX_Id = item._Sexo.SEX_Id + 1;
            });

            setSexosLoja(respSexosLoja);

            const responseTemaMenuEmpresa = await POST_DATA(`UsuarioEmpresaXTemaMenu/GetAllUsuarioEmpresaXTemaMenuByIdUsuarioEmpresa?idUsuarioEmpresa=${userLoggedIn.IdUsuarioEmpresa}`);
            setTemaMenus(responseTemaMenuEmpresa);

            const responseTemaMenuImagens = await POST_DATA(`UsuarioEmpresaXTemaMenuXImagem/GetAllUsuarioEmpresaXTemaMenuXImagensByIdUsuarioEmpresaIdImagem?idUsuarioEmpresa=${userLoggedIn.IdUsuarioEmpresa}&idImagem=${(location?.state?.idProduto || 0)}`);            
            setTemaMenusImagem(responseTemaMenuImagens);

            const responseMarcasLoja = await POST_DATA(`UsuarioEmpresaXMarca/GetAllUsuarioEmpresaXMarcaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
            const responseCategoriasLoja = await POST_DATA(`UsuarioEmpresaXCategoria/GetAllUsuarioEmpresaXCategoriaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=Id&Log=`);
            setCategoriasLoja(() => responseCategoriasLoja);

            await fetchMaterial();
            await fetchMarcas(responseMarcasLoja);
            setPecas([{ IdPeca: 0, Nome: 'Nenhum(a)' }]);
            await fetchCorPeca();
            await fetchEstampas();

            if (location.state !== null) {
                const produtoFetch = await fetchProduto(location.state.idProduto);
                const prod = produtoInitial(produtoFetch);
                setImagensComplementares(prod._ImagensComplementares);

                setProduto(prod);
                if (prod.Etiquetas.length > 0 && prod.Etiquetas[0]._Peca) {
                    await fetchPecas(prod.Etiquetas[0]._Peca.Categoria.IdCategoria);
                    await fetchSubPecas(prod.Etiquetas[0]._Peca.IdPeca);

                    setTimeout(async () => {
                        await fetchListaTamanhos(etiquetaSelecionada, prod.Etiquetas[0]._Peca.Categoria.IdCategoria, prod.Etiquetas[0].FlagKids);
                    }, 300);

                    const idsCategorias = [];
                    prod.Etiquetas.forEach((etq) => {
                        idsCategorias.push(etq.IdCategoria);
                    });
                    setIdsCategoriaOld(idsCategorias);
                }

                setIsEdicao(true);
                formik.resetForm();
            }

            setLoading(false);

            await fetchPordutosRelacionados();
            await fetchProdutos();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoggedIn]);

    useEffect(() => {
        (async () => {
            await fetchProdutos();
        })();
    }, [page]);

    useEffect(() => {
        (async () => {
            if (categoriasLoja.length > 0) {
                await fetchCategorias(formik.values.Etiquetas[etiquetaSelecionada]?.idPublicoAlvo);
            }
        })();
    }, [categoriasLoja]);


    const validationSchema = Yup.object().shape({
        ImagemSrc: Yup.string().required('*Favor selecionar uma imagem para prosseguir'),
        Descricao: Yup.string().required('*Favor informar uma descrição para o look'),

        idPublicoAlvo: Yup.string().test(
            'validateValor',
            '*Favor informar o valor o gênero',
            (idPublicoAlvo) => {
                return idPublicoAlvo !== -1;
            }
        ),

        Etiquetas: Yup.array().of(
            Yup.object().shape({
                sDescricao: Yup.string().required('*Favor informar o nome da etiqueta'),
                //Sku:  Yup.string().required('*Favor informar o sku da etiqueta'),
                X: Yup.string().required('Favor marcar a etiqueta na imagem'),

                //IdMaterial: Yup.number().min(1, "Favor selecionar o material").required("Favor selecionar o material"),

                IdMaterial: Yup.string().test(
                    'validateMaterial',
                    '*Favor selecionar o material',
                    (mValor) => {
                        if (themeStorage?.tipoThema === 10) return true;

                        return !(mValor === '0');
                    }
                ),

                IdCategoria: Yup.number().min(1, "Favor selecionar a categoria").required("Favor selecionar a categoria"),
                IdMarca: Yup.number().min(1, "Favor selecionar a marca").required("Favor selecionar a marca"),
                IdPeca: Yup.number().min(1, "Favor selecionar a peça").required("Favor selecionar a peça"),
                //IdCor: Yup.number().min(1, "Favor selecionar a cor da peça").required("Favor selecionar a cor da peça"),
                //IdEstampa: Yup.number().min(1, "Favor selecionar a estampa").required("Favor selecionar a estampa"),
                _EtiquetaComplemento: Yup.array().min(1, 'Favor selecionar ao menos um tamanho').required('Favor selecionar ao menos um tamanho'),
                /*_EtiquetaComplemento: Yup.array().of(
                    Yup.object().shape({                        
                        IdCor: Yup.number().min(1, "Favor selecionar a cor da peça").required("Favor selecionar a cor da peça"),                        
                    })
                ),*/

                _EtiquetaComplemento: Yup.number().when('IdCategoria', (IdCategoria) => {
                    return Yup.array().min(1, 'Favor selecionar ao menos uma variação').required('Favor selecionar ao menos uma variação')
                }),

                mValor: Yup.string().test(
                    'validateValor',
                    '*Favor informar o valor da etiqueta',
                    (mValor) => {
                        return !(mValor === '0');
                    }
                ),
                Altura: Yup.number().min(1, "Favor informar a altura").required("Favor informar a altura"),
                Largura: Yup.number().min(1, "Favor informar a largura").required("Favor informar a largura"),
                Profundidade: Yup.number().min(1, "Favor informar a profundidade").required("Favor informar a profundidade"),
                Peso: Yup.number().test(
                    'is-decimal',
                    'invalid decimal',
                    value => {
                        if (Number.isInteger(value)) return true;
                        return (value + "").match(/^\d*\.{1}\d*$/)
                    },
                ).required("Favor informar o peso"),
            })
        )
    });

    const fetchProduto = async (idImagem) => {
        const result = await POST_DATA(`Imagem/GetImagemById?Id=${idImagem}&join=true`);
        return result;
    }

    const fetchMaterial = async () => {
        const result = await POST_DATA(`Material/GetAllMaterial`);
        const opcoes = [{ IdMaterial: 0, Descricao: 'Nenhum(a)' }, ...result];
        setMateriais(opcoes);
    }

    const onChangeSelected = (idImagem, idEtiqueta, idEtiquetaComplemento, possuiRelacao) => {
        let itens = [];
        if (possuiRelacao) {
            itens = produtosRelacionados.filter(a => a.idEtiquetaComplemento !== idEtiquetaComplemento);
        }
        else {
            itens = [...produtosRelacionados, { idImagem, idEtiqueta, idEtiquetaComplemento }]
        }

        setProdutosRelacionados(itens);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const fetchPordutosRelacionados = async () => {
        if (!location.state?.idProduto) return;

        const produtosRelacionadosPrincipal = await POST_DATA(`Imagem/GetImagensRelacionadaByIdPrincipal?id=${location.state.idProduto}&idEtiqueta=null&idEtiquetaComplemento=null`);
        const arrProdutos = [];

        for (const item of produtosRelacionadosPrincipal) {
            arrProdutos.push({
                idImagemRelacionada: item.IdImagensRelacionadas,
                idImagem: item.idImagem,
                idEtiqueta: item.IdEtiquetaRelacionamento,
                idEtiquetaComplemento: item.IdEtiquetaComplementoRelacionamento
            });
        }

        setProdutosRelacionados(arrProdutos);
    }

    const fetchProdutos = async () => {
        if (userLoggedIn === undefined || userLoggedIn === null) return;
        setLoadingRelacionados(true);

        let filtroApi = {
            idUsuario: userLoggedIn?.IdUsuario,
            idUsuarioVendedor: userLoggedIn?.IdUsuario,
            idUsuarioComprador: 0,
            iPagina: page,
            Sexo: '0,1,2',
            FlagKids: false,
            Categoria: null,
            Peca: null,
            Tamanho: "",
            Marca: "",
            HashTags: null,
            ValorMinimo: 0,
            ValorMaximo: 0,
            FlgUsado: false,
            FlgFavoritos: false,
            order_by: '_Etiquetas.sDescricao asc',
            FlgAtivo: true,
            Sku: null,
            sDescricaoEtiqueta: null
        }

        const result = await PUT_DATA(`Imagem/GetListImagemFilterPaginationCRM`, filtroApi);
        result._Imagens.forEach((item) => {
            item.SkusEtiquetas = item._Etiquetas.map((et) => {
                return et._EtiquetaComplemento.map((etc) => {
                    return etc.Sku;
                });
            });

            item.SkusEtiquetas = item.SkusEtiquetas.join(',');
            item.SkusEtiquetas = item.SkusEtiquetas.replaceAll(',', ' / ');
        });
        const produtosRelacionados = result._Imagens.filter(a => a.idImagem !== location.state?.idProduto);

        setTotalPage(result.iTotalPagina);
        setListaProdutosRelacionados(produtosRelacionados);
        setLoadingRelacionados(false);
    }

    const fetchMarcas = async (marcasLoja) => {
        const result = await POST_DATA(`Marca/GetAllMarca?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=9999&order_by=IdMarca`);
        const arrMarcas = [];

        result.map(item => {
            marcasLoja.map(marca => {
                if (item.IdMarca === marca.IdMarca) {
                    arrMarcas.push(item);
                }
            });
        });

        const opcoes = [{ IdMarca: 0, Nome: 'Nenhum(a)' }, ...arrMarcas];
        setMarcas(opcoes);
    }

    const fetchCategorias = async (idSexo) => {
        //const result = await POST_DATA(`Categoria/GetCategoriaByIdSexoIN?sIdSexo=${idSexo}&Log=`);

        //Removendo o acrescimo feito no buscar o sexo
        idSexo = idSexo - 1;

        const arrCategorias = [];
        categoriasLoja.filter(a => a.IdSexo === idSexo).map(item => {
            arrCategorias.push(item.Categoria);
        });

        const opcoes = [{ IdCategoria: 0, Descricao: 'Nenhum(a)' }, ...arrCategorias];
        setCategorias(opcoes);
    }

    const fetchPecas = async (categoriaId) => {
        const respPecasLoja = await POST_DATA(`UsuarioEmpresaXPeca/GetAllUsuarioEmpresaXPecaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=Up.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=100&order_by=Up.idSexo&Log=`);
        const respPecas = await POST_DATA(`Peca/GetPecasByIdCategorias?ids=${categoriaId}`);

        //Removendo o acrescimo feito no buscar o sexo
        const idSexo = formik.values.Etiquetas[etiquetaSelecionada]?.idPublicoAlvo - 1;
        const opcoes = [{ IdPeca: 0, Nome: 'Nenhum(a)' }, ...respPecasLoja.filter(a => a.IdSexo === idSexo && (a.IdSubPeca === null || a.IdSubPeca === 0) && respPecas.filter(b => b.IdPeca === a.IdPeca).length > 0 ).map(pecaLoja => pecaLoja._Peca)];

        setPecas(opcoes);
        setSubPecas([{ IdPeca: 0, Nome: 'Nenhum(a)' }]);
    }

    const fetchSubPecas = async (pecaId) => {
        const respSubPecasLoja = await POST_DATA(`UsuarioEmpresaXPeca/GetAllUsuarioEmpresaXPecaByValorExato?strValorExato=${userLoggedIn.IdUsuarioEmpresa}&ColunaParaValorExato=Up.IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=100&order_by=Up.idSexo&Log=`);
        const result = await POST_DATA(`SubPeca/GetSubPecaByIdsPecas?ids=${pecaId}`);
        const idSexo = formik.values.Etiquetas[etiquetaSelecionada]?.idPublicoAlvo - 1;
        const opcoes = [{ IdPeca: 0, Nome: 'Nenhum(a)' }, ...respSubPecasLoja.filter(a => a.IdPeca === pecaId && a.IdSubPeca > 0 && result.filter(b => b.IdSubPeca === a.IdSubPeca && b.IdSexo === idSexo).length > 0 ).map(pecaLoja => pecaLoja._SubPeca)];

        setSubPecas(opcoes);
    }

    const fetchCorPeca = async () => {
        const result = await POST_DATA(`Cor/GetAllCor?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdCor`);
        setCoresPecas(result);
    }

    const fetchEstampas = async () => {
        const result = await POST_DATA(`Estampa/GetAllEstampa?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdEstampa`);
        setEstampas(result);
    }

    const fetchListaTamanhos = async (indexEtiqueta, categoriaId, flagKids) => {
        const arr = [...listaTamanhos];
        const result = await POST_DATA(`Tamanho/GetAllTamanhoByValorExato?strValorExato=${categoriaId}&ColunaParaValorExato=IdCategoria&fSomenteAtivos=true&join=true&flagKids=${flagKids}&maxInstances=0&order_by=IdTamanho`);
        arr[indexEtiqueta] = result;

        setListaTamanhos(arr);
    }

    const urlToFile = (url, filename) => {
        let blob = new Blob([url], { type: "image/jpeg" });
        return new File([blob], `${filename}.jpeg`, { type: "image/jpeg" });
    }

    const parsePesoPacoteCrmToApp = (peso) => {
        switch (peso) {
            case 300:
                return 0.3;
            case 500:
                return 0.5;
            case 800:
                return 0.8;
            case 1000:
                return 1.0;
            case 1300:
                return 1.3;
            case 1500:
                return 1.5;
            case 1800:
                return 1.8;
            case 2000:
                return 2.0;
            default:
                return peso;
        }
    }

    const parsePesoPacoteAppToCrm = (peso) => {
        switch (peso) {
            case 0.3:
                return 300;
            case 0.5:
                return 500;
            case 0.8:
                return 800;
            case 1:
                return 1000;
            case 1.3:
                return 1300;
            case 1.5:
                return 1500;
            case 1.8:
                return 1800;
            case 2:
                return 2000;
            default:
                return peso;
        }
    }

    const onSubmit = async (values) => {
        try {
            setSalvando(true);
            if (!isMaxDimensionsValid) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'A soma das dimensões (Altura + Largura + Profundidade) não pode ultrapassar o limite máximo de 200cm.',
                });
                return;
            }

            let imagem = values.ImagemSrc;
            const objSave = JSON.parse(JSON.stringify({ ...values, IdUsuario: userLoggedIn.IdUsuario, IdUsuarioInclusao: userLoggedIn.IdUsuario, _Etiquetas: values.Etiquetas, FlgPrincipal: true }));
            delete objSave.Etiquetas;
            delete objSave._ImagensComplementares;
            objSave.LocalIIS = null;

            //objSave.Descricao = JSON.stringify(objSave.Descricao);

            objSave._Etiquetas.forEach((item, index) => {
                item.X = Number(item.X) * (index + 1);
                item.Y = Number(item.Y) * (index + 1);
                item.idImagem = objSave.idImagem;
                item.IdUsuario = objSave.IdUsuario;
                item.mValor = Number(item.mValor?.toString()?.replace(",", "."));
                item.mValorCusto = Number(item.mValorCusto?.toString()?.replace(",", "."));

                item._EtiquetaComplemento = item._EtiquetaComplemento.map((item) => {
                    return {
                        Descricao: item.Descricao,
                        IdEtiquetaComplemento: isEdicao ? item?.IdEtiquetaComplemento ? item?.IdEtiquetaComplemento : 0 : 0,
                        idEtiqueta: formik.values.Etiquetas[etiquetaSelecionada].IdEtiqueta,
                        iQuantidade: item.qtd,
                        IdTamanho: item.IdTamanho,
                        FlagPromocao: item.FlagPromocao,
                        FlagLancamento: item.FlagLancamento,
                        FlagLiquidacao: item.FlagLiquidacao,
                        sTamanho: item.Descricao,
                        mValor: formik.values.Etiquetas[etiquetaSelecionada].mValor,
                        mValorCusto: formik.values.Etiquetas[etiquetaSelecionada].mValorCusto || 0,
                        FlagAtivo: item.FlagAtivo,
                        IdUsuarioInclusao: userLoggedIn.IdUsuario,
                        IdCor: item?._Cor ? item._Cor.IdCor : null,
                        _Cor: item?._Cor,
                        _Estampa: item?._Estampa,
                        IdEstampa: item?._Estampa ? item._Estampa.IdEstampa : null,
                        Sku: item?.Sku,
                        Altura: item?.Altura?.replace(/\D/g, "").replace('.', '').replace(',', '').replace('R$ ', '').trim(),
                        Largura: item?.Largura?.replace(/\D/g, "").replace('.', '').replace(',', '').replace('R$ ', '').trim(),
                        Profundidade: item?.Profundidade?.replace(/\D/g, "").replace('.', '').replace(',', '').replace('R$ ', '').trim(),
                    };
                });
            });

            let data = new FormData();
            data.append("imagem", imagem);
            data.append("objImagem", JSON.stringify(objSave));

            //const endPoint = `Imagem/${isEdicao ? "CadastroImagemJs_Loja" : "CadastroImagemJs" }`;
            const endPoint = `Imagem/CadastroImagemJs_Loja`;
            const imagemPrincipal = await POST_DATA_FILE(endPoint, data);
            const envioOk = imagemPrincipal || (imagemPrincipal.obj !== null && imagemPrincipal.obj !== undefined);
            const idImagemPrincipal = values.idImagem > 0 ? values.idImagem : imagemPrincipal.obj.idImagem;

            for (const temaMenuImagem of temaMenusImagem) {
                temaMenuImagem.IdImagem = idImagemPrincipal;

                await PUT_DATA(`UsuarioEmpresaXTemaMenuXImagem/CadastroUsuarioEmpresaXTemaMenuXImagem`, temaMenuImagem);
            }

            if (produtosRelacionados.length > 0) {
                produtosRelacionados.forEach(async (item) => {
                    await PUT_DATA(`Imagem/DesabilitarImagemRelacionada?IdImagensRelacionadas=${item.idImagemRelacionada}`);
                });

                produtosRelacionados.forEach(async (item) => {
                    await PUT_DATA(`Imagem/InsertImagemRelacionada`, {
                        "IdImagensRelacionadas": 0,
                        "IdImagemPrincipal": imagemPrincipal.idImagem,
                        "IdImagemRelacionada": item.idImagem,
                        "IdEtiqueta": item.idEtiqueta,
                        "idEtiquetaComplemento": item.idEtiquetaComplemento,
                        "IdUsuario": userLoggedIn.IdUsuario,
                        "FlgAtivo": true,
                        "DtInclusao": moment().format('YYYY-MM-DD HH:mm:ss'),
                        "IdIncluidoPor": userLoggedIn.IdUsuario,
                        "IdAlteradoPor": userLoggedIn.IdUsuario
                    });
                });
            }

            try {
                if (objSave.idImagem === 0) {
                    const pasta = `${userLoggedIn.Cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')}/imagens`;
                    const nomeArquivo = `POST_${uuidv4()}`;
                    imagemPrincipal.obj.LocalIIS = await saveImagemConvertedWebp(nomeArquivo, pasta, imagemPrincipal.obj.LocalIIS);

                    data = new FormData();
                    data.append("objImagem", JSON.stringify(imagemPrincipal.obj));

                    await POST_DATA_FILE(`Imagem/CadastroImagemJs_Loja_ComUrlImagem`, data);
                }
                else if (imagemPrincipal.LocalIIS !== null) {
                    const produtoPrincipal = imagemPrincipal;
                    produtoPrincipal.IdUsuario = userLoggedIn.IdUsuario;

                    if (produtoPrincipal.LocalIIS !== null) {
                        const pasta = `${userLoggedIn.Cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '')}/imagens`;
                        const nomeArquivo = `POST_${uuidv4()}`;
                        produtoPrincipal.LocalIIS = await saveImagemConvertedWebp(nomeArquivo, pasta, produtoPrincipal.LocalIIS);

                        data = new FormData();
                        data.append("objImagem", JSON.stringify(produtoPrincipal));

                        await POST_DATA_FILE(`Imagem/CadastroImagemJs_Loja_ComUrlImagem`, data);
                    }
                }
            }
            catch (err) {
                SalvarLogSentry(err);
            }

            if (envioOk) {
                imagensComplementares.forEach(async item => {
                    if (item.idImagem > 0) {
                        await PUT_DATA(`Imagem/DeletarImagem?Id=${item.idImagem}&Log={}`);
                    }
                });

                values._ImagensComplementares.map(async item => {
                    const imagemComplementar = values.idImagem > 0 ? { ...item, IdUsuario: userLoggedIn.IdUsuario, IdImagemPrincipal: idImagemPrincipal, ImagemSrc: item.LocalIIS } : { ...values, IdUsuario: userLoggedIn.IdUsuario, IdImagemPrincipal: idImagemPrincipal, ImagemSrc: item.LocalIIS };
                    delete imagemComplementar.Etiquetas;
                    delete imagemComplementar._Etiquetas;
                    delete imagemComplementar._ImagensComplementares;
                    imagemComplementar.LocalIIS = null;

                    if (imagemComplementar.ImagemSrc === null || imagemComplementar.ImagemSrc === undefined) return null;

                    data = new FormData();
                    data.append(`imagem`, imagemComplementar.ImagemSrc);
                    data.append("objImagem", JSON.stringify(imagemComplementar));

                    await POST_DATA_FILE(endPoint, data);
                });
            }

            setSalvando(false);
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: isEdicao ? 'Produto editado com sucesso!' : 'Produto cadastrado com sucesso!',
            });

            if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 2)[0].FlagStatus) { //Adicione os produtos
                const objOnboard = { ...onBoard };
                objOnboard.IdUltimoOnboardingStoreItem = 2;

                const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 2)[0];
                itemOnboard.FlagStatus = true;

                atualizarOnboardStore(objOnboard, itemOnboard);

                dispatch({
                    type: SET_DADOS_ON_BOARD,
                    data: objOnboard
                });

                navigate('/on-board');
            }
            else {
                navigate("/produtos", { replace: true });
            }
        }
        catch (error) {
            SalvarLogSentry(error);
            setSalvando(false);
            setLoading(false);
            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: error?.titulo,
                message: error?.message,
            });
        }
    }

    const formik = useFormik({
        onSubmit,
        initialValues: produto,
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
    });

    const isMaxDimensionsValid =
        (formik.values.Etiquetas[etiquetaSelecionada]?.Altura || 0) +
        (formik.values.Etiquetas[etiquetaSelecionada]?.Largura || 0) +
        (formik.values.Etiquetas[etiquetaSelecionada]?.Profundidade || 0) <= 200;

    const isMaxWeightValid = formik.values.Etiquetas[etiquetaSelecionada]?.Peso <= 30000;

    useEffect(() => {
        if (!isMaxDimensionsValid) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'O peso deve ser inferior ou igual 30000.',
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMaxWeightValid]);

    useEffect(() => {
        if (!isMaxDimensionsValid) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'A soma das dimensões (Altura + Largura + Profundidade) não pode ultrapassar o limite máximo de 200cm.',
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMaxDimensionsValid]);

    useEffect(() => {
        //console.log('formik.errors', formik.errors);

        if (Object.keys(formik.errors).length === 0) return;

        dispatch({
            type: CONTROLEMENSAGEM_AVISO,
            tipoComponente: 'alert',
            titulo: 'Aviso',
            message: 'Favor verificar os campos obrigatórios',
        });

        window.scrollTo(0, 0);
    }, [formik.errors]);

    useEffect(() => {
        //console.log('values', formik.values);
    }, [formik.values]);

    const pesoOptions = [
        { label: "300g", value: 300 },
        { label: "500g", value: 500 },
        { label: "800g", value: 800 },
        { label: "1kg", value: 1000 },
        { label: "1,3kg", value: 1300 },
        { label: "1,5kg", value: 1500 },
        { label: "1,8kg", value: 1800 },
        { label: "2kg", value: 2000 },
    ];

    useEffect(() => {
        (async () => {
            if (changeCategoria > 0) {
                await fetchPecas(changeCategoria);

                const flagKids = formik.values.Etiquetas[etiquetaSelecionada]?.FlagKids || false;
                await fetchListaTamanhos(etiquetaSelecionada, changeCategoria, flagKids);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeCategoria]);

    useEffect(() => {
        (async () => {
            if (changePeca > 0) {
                await fetchSubPecas(changePeca);
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changePeca]);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const handleChangeTab = (event, newValue) => {
        setTabSelecionada(newValue);
    };

    const handleChangeTabEtiqueta = (event, newValue) => {
        setEtiquetaSelecionada(newValue);
    };

    const handleChangeCorEtiqueta = (objCor) => {
        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdCor`, objCor.index);
        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].Cor`, objCor.cor);
    }

    const handleChangeImagem = (imagem) => {
        formik.setFieldValue(`ImagemSrc`, imagem);
    }

    const handleChangeImagemComplemente = (imagem, index) => {
        if (imagem === null) {
            formik.setFieldValue(`_ImagensComplementares[${index}]`, {
                FlgAtivo: true,
                FlgLoja: true,
                LocalIIS: null,
                idImagem: 0
            });
        }
        else {
            formik.setFieldValue(`_ImagensComplementares[${index}].LocalIIS`, imagem);
        }

    }

    const handleAddTamanho = () => {
        const tamanho = listaTamanhos[etiquetaSelecionada].filter(a => a.IdTamanho === tamanhoSelecionadoEC[etiquetaSelecionada])[0];
        let cor = coresPecas.filter(item => item.IdCor === corPecaSelecionadaEC[etiquetaSelecionada])[0];
        const estampa = estampas.filter(item => item.IdEstampa === estampaSelecionadaEC[etiquetaSelecionada])[0];
        const sku = skuEC[etiquetaSelecionada];

        if (cor === undefined) {
            cor = { IdCor: 0, Descricao: '-' };
        }

        let msgValidacao = '';
        if (!tamanho?.IdTamanho || tamanho.IdTamanho === 0) msgValidacao += 'Favor selecionar um tamanho!'

        if (themeStorage?.tipoThema !== 10) //Depois remover do Barry
        {
            if (!cor?.IdCor || cor.IdCor === 0) msgValidacao += '\nFavor selecionar uma cor!'
        }

        if (!sku || sku?.trim()?.length === 0) msgValidacao += '\nFavor informar o sku!'

        if (msgValidacao.trim().length > 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: msgValidacao,
            });
            return;
        }

        const alreadyExistsSku = formik.values.Etiquetas[etiquetaSelecionada]?._EtiquetaComplemento.find(
            (item) => item.Sku === sku && item.visivelListagem
        );

        if (alreadyExistsSku) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sku já existe na listagem!',
            });
            return;
        }

        const alreadyExists = formik.values.Etiquetas[etiquetaSelecionada]?._EtiquetaComplemento.find(
            (item) => item.IdTamanho === tamanho.IdTamanho && item.IdCor === cor?.IdCor && item.IdEstampa === estampa?.IdEstampa
        );

        if (alreadyExists) {
            if (formik.values.Etiquetas[etiquetaSelecionada]?._EtiquetaComplemento.find((item) => item.IdTamanho === tamanho.IdTamanho && item.IdCor === cor?.IdCor && item.IdEstampa === estampa?.IdEstampa && item.Sku === sku && !item.visivelListagem)) {
                const tamanhosAtualizados = formik.values.Etiquetas[etiquetaSelecionada]?._EtiquetaComplemento?.map((item) => {
                    if (estampa?.IdEstampa) {
                        if ((item.IdTamanho === tamanho.IdTamanho && item.IdCor === cor?.IdCor && item.IdEstampa === estampa?.IdEstampa) || item.Sku === sku) {
                            item.visivelListagem = true;
                            item.FlagAtivo = true;
                            item._Cor = cor;
                            item._Estampa = estampa;
                            item.Sku = sku;
                            item.IdCor = cor?.IdCor;
                            item.IdEstampa = estampa?.IdEstampa;
                        }
                        return item;
                    }
                    else {
                        if ((item.IdTamanho === tamanho.IdTamanho && item.IdCor === cor?.IdCor) || item.Sku === sku) {
                            item.visivelListagem = true;
                            item.FlagAtivo = true;
                            item._Cor = cor;
                            item._Estampa = estampa;
                            item.Sku = sku;
                            item.IdCor = cor?.IdCor;
                            item.IdEstampa = estampa?.IdEstampa;
                        }
                        return item;
                    }
                });
                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}]._EtiquetaComplemento`, tamanhosAtualizados);
            }
            else {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Variação já adicionada!',
                });
                return;
            }
        } else {
            const tamanhosAntigos = formik.values.Etiquetas[etiquetaSelecionada]?._EtiquetaComplemento;
            const etiquetaComplemento = [...tamanhosAntigos,
            {
                ...tamanho,
                qtd: 1,
                visivelListagem: true,
                _Cor: cor,
                _Estampa: estampa,
                IdCor: cor?.IdCor,
                IdEstampa: estampa?.IdEstampa,
                Sku: sku
            }];

            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}]._EtiquetaComplemento`, etiquetaComplemento);
        }

        setTamanhoSelecionadoEC([]);
        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputTamanho`, null);
        setCorPecaSelecionadaEC([]);
        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputCorPeca`, null);
        setEstampaSelecionadaEC([]);
        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputEstampa`, null);
        setSkuEC([]);
    }

    const handleSetListaTamanhosSelecionados = (tamanhos) => {
        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}]._EtiquetaComplemento`, tamanhos);
    }

    const handleRemoverTamanho = (idTamanho, idCor, idEstampa, sku) => {
        const novosTamanhos = formik.values.Etiquetas[etiquetaSelecionada]?._EtiquetaComplemento?.map((item) => {
            if (idEstampa) {
                if (item.IdTamanho === idTamanho && item.IdCor === idCor && item.IdEstampa === idEstampa && item.Sku === sku) {
                    item.visivelListagem = false;
                    item.FlagAtivo = false;
                }
            }
            else {
                if (item.IdTamanho === idTamanho && item.IdCor === idCor && item.Sku === sku) {
                    item.visivelListagem = false;
                    item.FlagAtivo = false;
                }
            }
            return item;
        });
        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}]._EtiquetaComplemento`, novosTamanhos);
    }

    const handleMarcaEtiqueta = (e) => {
        const dim = e.target.getBoundingClientRect();
        setTimeout(() => {
            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].X`, e.clientX - dim.left);
            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].Y`, e.clientY - dim.top);
        }, 1000);
    };

    const fetchDesricaoOpenIA = async () => {
        try {
            if (formik.values.Etiquetas[etiquetaSelecionada]?.InputMaterial === ""
                || formik.values.Etiquetas[etiquetaSelecionada]?.InputCategoria === ""
                || formik.values.Etiquetas[etiquetaSelecionada]?.InputMarca === ""
                || formik.values.Etiquetas[etiquetaSelecionada]?.InputPeca === ""
            ) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor preencher os detalhes da peça.',
                });
                return;
            }

            if (formik.values.Etiquetas[etiquetaSelecionada]?._EtiquetaComplemento.length === 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor preencher ao menos uma variação da peça.',
                });
                return;
            }

            setLoadingOpenIA(true);

            const primeiraVariacao = formik.values.Etiquetas[etiquetaSelecionada]?._EtiquetaComplemento[0];
            let publicoAlvo = "feminino";
            if (formik.values.Etiquetas[etiquetaSelecionada]?.idPublicoAlvo == 1)
                publicoAlvo = "masculino";
            else if (formik.values.Etiquetas[etiquetaSelecionada]?.idPublicoAlvo == 1)
                publicoAlvo = "geral";

            const obj = {
                "idScript": 1,
                "idUsuario": userLoggedIn.IdUsuario,
                "material": formik.values.Etiquetas[etiquetaSelecionada]?.InputMaterial.Descricao,
                "categoria": formik.values.Etiquetas[etiquetaSelecionada]?.InputCategoria.Descricao,
                "marca": formik.values.Etiquetas[etiquetaSelecionada]?.InputMarca.Nome,
                "peca": formik.values.Etiquetas[etiquetaSelecionada]?.InputPeca.Nome,
                "publicoAlvo": publicoAlvo,
                "fPecaInfantil": formik.values.Etiquetas[etiquetaSelecionada].FlagKids,
                "fNova": formik.values.Etiquetas[etiquetaSelecionada]?.FlagPecaNova,
                "cor": primeiraVariacao._Cor.Descricao,
                "tamanho": primeiraVariacao.Tamanho.Descricao,
                "estampa": primeiraVariacao._Estampa?.Descricao || '',
                "fPromocao": primeiraVariacao.FlagPromocao,
                "fLancamento": primeiraVariacao.FlagLancamento,
                "fLiquidacao": primeiraVariacao?.FlagLiquidacao || false
            };

            const result = await POST_DATA_SERVICOS_ROBO('OpenAI?route=ChatGPT4&method=produtoDescricao', obj);
            const descricao = result?.body?.message.content?.replaceAll('\n\n', '<br><br>');
            formik.setFieldValue(`Descricao`, descricao);

            setLoadingOpenIA(false);
        }
        catch (err) {
            setLoadingOpenIA(false);
        }
    }

    const getImagem = (isEdition, imagem) => {
        if (isEdicao && typeof (formik.values?.ImagemSrc) !== 'string') {
            return URL.createObjectURL(formik.values?.ImagemSrc);
        }
        else if (isEdicao && typeof (formik.values?.ImagemSrc) === 'string') {
            return formik.values?.ImagemSrc;
        }

        return formik.values?.ImagemSrc ? URL.createObjectURL(formik.values?.ImagemSrc) : null;
    }

    try {

        const componente = <form onSubmit={formik.handleSubmit}>
            {
                loadingOpenIA &&
                <LoadingAllPage />
            }
            <ConfirmationUpdateCategoria
                title="Alterar categoria da etiqueta?"
                mensagem="Ao alterar a categoria da etiqueta as variáções e peça vinculadas serão perdidas, deseja continuar?"
                btn1={{ title: "Não", onClick: null }}
                btn2={{
                    title: "Sim", onClick: () => {
                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdCategoria`, novaCategoriaSelecionada?.IdCategoria);
                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputCategoria`, novaCategoriaSelecionada);
                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].Categoria`, novaCategoriaSelecionada);
                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputPeca`, { IdPeca: 0, Nome: 'Nenhum(a)' });
                        setChangeCategoria(novaCategoriaSelecionada?.IdCategoria);
                        setChangePeca(0);

                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValueCategoria`, novoInputValueCategoriaSelecionada);
                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValuePeca`, 'Nenhum(a)');

                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}]._EtiquetaComplemento`, []);
                    }
                }}
            />

            <ConfirmationProdutoAtivo
                title="Ativar produto?"
                mensagem="Ao ativar o produto o mesmo já estará disponível para venda. Deseja ativar ?"
                btn1={{ title: "Não", onClick: null }}
                btn2={{
                    title: "Sim", onClick: () => {
                        formik.setFieldValue(`FlgAtivo`, !formik.values?.FlgAtivo);
                    }
                }}
            />

            <ConfirmationExibirShopLeap
                title="Exibir ShopLeap"
                mensagem="Seu produto está em análise pela ShopLeap para inclusão em nosso Marketplace. Este processo de avaliação será concluído em até 24 horas."
                btn1={{ title: "Cancelar", onClick: null }}
                btn2={{
                    title: "Confirmar", onClick: () => {
                        formik.setFieldValue(`FlgLiberadoShopLeap`, !formik.values?.FlgLiberadoShopLeap);
                    }
                }}
            />

            <ConfirmationExibirNoApp
                title="Exibir no App Leap"
                mensagem="Seu produto está em análise pelo Aplicativo da Leap para inclusão em nosso Marketplace Móvel. Este processo de avaliação será concluído em até 24 horas."
                btn1={{ title: "Cancelar", onClick: null }}
                btn2={{
                    title: "Confirmar", onClick: () => {
                        formik.setFieldValue(`FlgLiberadoLeap`, !formik.values?.FlgLiberadoLeap);
                    }
                }}
            />

            {loading ? (
                <Stack
                    sx={{ height: "150px", marginTop: '10%' }}
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <CircularProgress />
                    <Typography sx={{ mt: 1 }}>Carregando dados do produto</Typography>
                </Stack>
            ) : (
                <Styled.Container>
                    <Box style={{ width: '100%', marginBottom: '10px', display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between' }}>
                        <Box style={{ display: 'flex' }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                size="large"
                                style={{ marginRight: 20, fontSize: 10 }}
                                onClick={() => navigate("/produtos", { replace: true })}
                            >
                                Voltar
                            </Button>
                            <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>Cadastro de produtos</Typography>
                        </Box>
                        <Button
                            disabled={salvando}
                            readOnly={salvando}
                            variant="contained"
                            color="primary"
                            type='submite'
                        >
                            {salvando && <Loader />}
                            Salvar Produto
                        </Button>
                    </Box>
                    <Tabs
                        value={tabSelecionada}
                        onChange={handleChangeTab}
                        aria-label="Selecione a tab"
                    >
                        <Tab label="Dados do produto" {...a11yProps(1)} />
                        <Tab label="Produtos relacionados" {...a11yProps(2)} />
                    </Tabs>

                    {
                        tabSelecionada === 0 &&
                        <Grid container spacing={1} style={{ marginTop: 15 }}>
                            <Grid item xs={9}>
                                <Styled.Section>
                                    <Box style={{ width: '100%', marginTop: 10 }}>
                                        <Typography style={{ fontWeight: 'bold' }}>*Nome do produto</Typography>
                                        <TextField
                                            style={{ width: '100%' }}
                                            value={formik.values?.NomeProduto}
                                            onChange={(e) => {
                                                formik.setFieldValue(`NomeProduto`, e.target.value);
                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].sDescricao`, e.target.value);
                                            }}
                                        />
                                        {etiquetaSelecionada == 0 && <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.sDescricao}</Styled.AlertError>}
                                    </Box>
                                    <Box style={{ width: '100%', marginTop: 10 }}>
                                        <Typography style={{ fontWeight: 'bold' }}>*Descrição do produto</Typography>
                                        <hr />
                                        <Editor
                                            showWand={true}
                                            onClickCustom={() => fetchDesricaoOpenIA()}
                                            toolbarId='descricao-produto'
                                            className='ql-editor2'
                                            text={formik.values?.Descricao}
                                            handleChange={(valor) => formik.setFieldValue(`Descricao`, valor)}
                                        />
                                        <Styled.AlertError>{formik.errors.Descricao}</Styled.AlertError>
                                    </Box>
                                    <Box style={{ width: '100%', marginTop: 10 }}>
                                        <Typography style={{ fontWeight: 'bold' }}>Descrição detalhada</Typography>
                                        <hr />
                                        <Editor
                                            toolbarId='descricao-detalhada-produto'
                                            className='ql-editor2'
                                            text={formik.values?.DescricaoDetalhe}
                                            handleChange={(valor) => formik.setFieldValue(`DescricaoDetalhe`, valor)}
                                        />
                                        <Styled.AlertError>{formik.errors.DescricaoDetalhe}</Styled.AlertError>
                                    </Box>
                                    <Box style={{ width: '100%', marginTop: 10 }}>
                                        <Typography style={{ fontWeight: 'bold' }}>Medidas modelo</Typography>
                                        <hr />
                                        <Editor
                                            toolbarId='descricao-medida-modelo'
                                            className='ql-editor2'
                                            text={formik.values?.DescricaoMedidasModelo}
                                            handleChange={(valor) => formik.setFieldValue(`DescricaoMedidasModelo`, valor)}
                                        />
                                        <Styled.AlertError>{formik.errors.DescricaoMedidasModelo}</Styled.AlertError>
                                    </Box>
                                </Styled.Section>

                                <Styled.Section>
                                    <Typography style={{ fontWeight: 'bold' }}>Quantidades de etiqueta</Typography>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 11, color: 'gray' }}>*Selecione a quantidade de produtos no mesmo anúncio</Typography>
                                    <Typography style={{ fontWeight: 'bold', fontSize: 11, color: 'gray' }}>*Você pode anunciar mais de uma peça na mesma foto.</Typography>
                                    <hr />
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="1"
                                            name="QuantidadeEtiquetas"
                                            value={formik.values.QuantidadeEtiquetas}
                                            onChange={(e) => {
                                                const index = Number(e.target.value) - 1;
                                                const quantidadeEtiquetas = Number(e.target.value);
                                                formik.setFieldValue("QuantidadeEtiquetas", quantidadeEtiquetas);
                                                const etiqueta = formik.values.Etiquetas[index];

                                                if (!etiqueta) {
                                                    for (let i = 0; i <= index; i++) {
                                                        if (!formik.values.Etiquetas[i]) {
                                                            formik.setFieldValue(`Etiquetas[${i}]`, getObjEtiqueta(null));
                                                        }
                                                    }
                                                    setEtiquetaSelecionada(0);
                                                }
                                                else {
                                                    const novasEtiquetas = [];
                                                    for (let i = 0; i <= index; i++) {
                                                        novasEtiquetas.push(formik.values.Etiquetas[i]);
                                                    }
                                                    formik.setFieldValue(`Etiquetas`, novasEtiquetas);
                                                    setEtiquetaSelecionada(0);
                                                }
                                            }}
                                            style={{ display: 'flex', flexDirection: 'row' }}
                                        >
                                            <FormControlLabel value={1} control={<Radio />} label="1" />
                                            <FormControlLabel value={2} control={<Radio />} label="2" />
                                            <FormControlLabel value={3} control={<Radio />} label="3" />
                                        </RadioGroup>
                                        <span>{formik.errors.QuantidadeEtiquetas}</span>
                                        <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas.find(a => a !== undefined) !== undefined && "Favor verificar as validações das etiquetas"}</Styled.AlertError>
                                    </FormControl>
                                    <Box style={{ width: '100%' }}>
                                        {
                                            formik.values.ImagemSrc !== null && (
                                                <>
                                                    <Tabs
                                                        value={etiquetaSelecionada}
                                                        onChange={handleChangeTabEtiqueta}
                                                        aria-label="Selecione a etiqueta"
                                                    >
                                                        <Tab label="Etiqueta 1" {...a11yProps(0)} />
                                                        {formik.values.QuantidadeEtiquetas > 1 &&
                                                            <Tab label="Etiqueta 2" {...a11yProps(1)} />
                                                        }
                                                        {formik.values.QuantidadeEtiquetas > 2 &&
                                                            <Tab label="Etiqueta 3" {...a11yProps(2)} />
                                                        }
                                                    </Tabs>
                                                    {
                                                        etiquetaSelecionada > 0 &&
                                                        <Box style={{ width: '100%', display: 'flex', flexWrap: querySelector ? 'wrap' : 'nowrap' }}>
                                                            <Box style={{ marginTop: 15, width: 450 }}>
                                                                <InputText
                                                                    id={`Etiquetas[${etiquetaSelecionada}].sDescricao`}
                                                                    type='text'
                                                                    disabled={etiquetaSelecionada == 0}
                                                                    label="Nome da etiqueta"
                                                                    value={formik.values.Etiquetas[etiquetaSelecionada]?.sDescricao}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].sDescricao`, e.target.value);
                                                                    }}
                                                                />
                                                                <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.sDescricao}</Styled.AlertError>
                                                            </Box>
                                                        </Box>
                                                    }
                                                    <Box style={{ width: '100%', display: 'flex', marginTop: 10 }}>
                                                        <ColorPicker index={formik.values.Etiquetas[etiquetaSelecionada]?.IdCor} handleChangeCorEtiqueta={handleChangeCorEtiqueta} />
                                                        <Box>
                                                            <Button style={{ marginLeft: 10 }} variant="outlined" onClick={() => setOpen(true)}>
                                                                Marcar Etiqueta
                                                            </Button>
                                                            <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.X}</Styled.AlertError>
                                                            <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.Y}</Styled.AlertError>

                                                            <Dialog
                                                                open={open}
                                                                TransitionComponent={Transition}
                                                                keepMounted
                                                                onClose={() => setOpen(false)}
                                                            >
                                                                <Backdrop
                                                                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                                                    open={loading}
                                                                >
                                                                    <Stack
                                                                        direction="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <CircularProgress />
                                                                        <Typography>Marcando...</Typography>
                                                                    </Stack>
                                                                </Backdrop>
                                                                <DialogTitle>{`Marcar Etiqueta ${etiquetaSelecionada + 1}`}</DialogTitle>
                                                                <Styled.ImageContainer>
                                                                    <img
                                                                        alt='Imagem etiqueta'
                                                                        style={{ width: "100%" }}
                                                                        onClick={handleMarcaEtiqueta}
                                                                        src={getImagem(isEdicao, formik.values?.ImagemSrc)}
                                                                    />
                                                                    {formik.values.Etiquetas.map((etq) => {
                                                                        if (etq === undefined || etq === null) return null;
                                                                        if (etq.X?.length !== 0 && etq.Y?.length !== 0) {
                                                                            return (
                                                                                <Tooltip
                                                                                    title={
                                                                                        etq.sDescricao ? etq.sDescricao : "Etiqueta sem nome"
                                                                                    }
                                                                                >
                                                                                    <Styled.Marcador
                                                                                        color={etq.Cor}
                                                                                        posX={etq.X}
                                                                                        posY={etq.Y}
                                                                                        temporary={false}
                                                                                    />
                                                                                </Tooltip>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })}
                                                                    {formik.values.Etiquetas[etiquetaSelecionada]?.X !== null && (
                                                                        <Tooltip title={formik.values.Etiquetas[etiquetaSelecionada]?.sDescricao?.length > 0 ? formik.values.Etiquetas[etiquetaSelecionada]?.sDescricao : "Etiqueta (Não confirmada)"}>
                                                                            <Styled.Marcador
                                                                                temporary={true}
                                                                                color={
                                                                                    formik.values.Etiquetas[etiquetaSelecionada]?.Cor
                                                                                        ? formik.values.Etiquetas[etiquetaSelecionada].Cor
                                                                                        : "#0084BE"
                                                                                }
                                                                                posX={formik.values.Etiquetas[etiquetaSelecionada]?.X}
                                                                                posY={formik.values.Etiquetas[etiquetaSelecionada]?.Y}
                                                                            />
                                                                        </Tooltip>
                                                                    )}
                                                                </Styled.ImageContainer>

                                                                <DialogActions>
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="space-between"
                                                                        gap={2}
                                                                        sx={{ width: "100%" }}
                                                                    >
                                                                        <Button
                                                                            variant="contained"
                                                                            onClick={() => {
                                                                                setOpen(false);
                                                                                dispatch({
                                                                                    type: CONTROLEMENSAGEM_SUCESSO,
                                                                                    tipoComponente: 'alert',
                                                                                    titulo: 'Sucesso',
                                                                                    message: 'Posição da etiqueta atualizada',
                                                                                });
                                                                            }}
                                                                            disabled={!formik.values.Etiquetas[etiquetaSelecionada]?.X}
                                                                        >
                                                                            Confirmar Marcação
                                                                        </Button>
                                                                    </Stack>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </Box>
                                                    </Box>
                                                </>
                                            )
                                        }
                                        {
                                            formik.values.ImagemSrc === null && <Alert severity="info" sx={{ margin: "1rem 0" }}>
                                                Selecione uma foto do produto para marcar etiquetas.
                                            </Alert>
                                        }
                                    </Box>
                                </Styled.Section>

                                <Styled.Section>
                                    <Typography style={{ fontWeight: 'bold' }}>Imagens e vídeos</Typography>
                                    <Typography style={{ fontSize: 12 }}>*Recomendado imagens com no máximo 1MB com resolução de 1080px x 1350px</Typography>
                                    <hr />
                                    <Box style={{ display: 'flex', overflowX: 'auto', paddingBottom: 10 }}>
                                        <UploadImagem
                                            isEditing={isEdicao}
                                            imgData={formik.values?.ImagemSrc}
                                            setImgData={handleChangeImagem}
                                            heightImg='auto'
                                            widthImg={!customization.opened ? 280 : 250}
                                            paddingTopImg={0}
                                            borderRadiusImg={0}
                                            style={{ minWidth: !customization.opened ? 300 : 270, minHeight: 715 }}
                                        />
                                        <Grid container spacing={2} style={{ minWidth: 950, width: '100%' }}>
                                            {
                                                formik.values?._ImagensComplementares?.map((img, index) => {
                                                    return <Grid item xs={4}>
                                                        <UploadImagem
                                                            index={index}
                                                            isEditing={isEdicao}
                                                            imgData={img?.LocalIIS}
                                                            setImgData={handleChangeImagemComplemente}
                                                            heightImg='auto'
                                                            widthImg={!customization.opened ? 200 : 180}
                                                            paddingTopImg={0}
                                                            borderRadiusImg={0}
                                                            style={{ minWidth: !customization.opened ? 300 : 270, minHeight: 350 }}
                                                        />
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                    <Styled.AlertError>{formik.errors.ImagemSrc}</Styled.AlertError>
                                </Styled.Section>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Stack direction="row" gap={1} alignItems='center'>
                                    <Switch
                                        color="primary"
                                        checked={formik.values?.FlgAtivo}
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            if (formik.values?.FlgAtivo)
                                                formik.setFieldValue(`FlgAtivo`, !formik.values?.FlgAtivo);
                                            else
                                                getConfirmationProdutoAtivo();
                                        }}
                                    />
                                    <Typography sx={{ color: "gray" }}>Produto Ativo</Typography>
                                </Stack>

                                {
                                    /** 
                                     <Styled.Section>
                                    <Typography style={{ fontWeight: 'bold' }}>*Categorias</Typography>
                                        <hr />
                                        <FormGroup style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                            <FormControlLabel control={<Checkbox defaultChecked />} label="Todos produtos" />
                                            <FormControlLabel control={<Checkbox />} label="Calça" />
                                            <FormControlLabel control={<Checkbox />} label="Parte de baixo" />
                                            <FormControlLabel control={<Checkbox />} label="Parte de cima" />
                                            <FormControlLabel control={<Checkbox />} label="Peça única" />
                                        </FormGroup>
                                    </Styled.Section>
                                    */
                                }

                                <Styled.Section>
                                    <Typography style={{ fontWeight: 'bold' }}>Destaque da loja</Typography>
                                    <hr />
                                    <Stack direction="row" gap={1} alignItems='center'>
                                        <Switch
                                            color="primary"
                                            checked={formik.values?.FlgDestaque}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                formik.setFieldValue(`FlgDestaque`, !formik.values?.FlgDestaque);
                                            }}
                                        />
                                        <Typography sx={{ color: "gray" }}>Exibir como destaque</Typography>
                                    </Stack>
                                </Styled.Section>

                                <Styled.Section>
                                    <Typography style={{ fontWeight: 'bold' }}>Novidade da loja</Typography>
                                    <hr />
                                    <Stack direction="row" gap={1} alignItems='center'>
                                        <Switch
                                            color="primary"
                                            checked={formik.values.Etiquetas[etiquetaSelecionada]?.FlagNovidade}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].FlagNovidade`, !formik.values.Etiquetas[etiquetaSelecionada]?.FlagNovidade);
                                            }}
                                        />
                                        <Typography sx={{ color: "gray" }}>Exibir como novidade</Typography>
                                    </Stack>
                                </Styled.Section>

                                <Styled.Section>
                                    <Typography style={{ fontWeight: 'bold' }}>Mais vendido da loja</Typography>
                                    <hr />
                                    <Stack direction="row" gap={1} alignItems='center'>
                                        <Switch
                                            color="primary"
                                            checked={formik.values.Etiquetas[etiquetaSelecionada]?.FlagMaisVendido}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].FlagMaisVendido`, !formik.values.Etiquetas[etiquetaSelecionada]?.FlagMaisVendido);
                                            }}
                                        />
                                        <Typography sx={{ color: "gray" }}>Exibir como mais vendido</Typography>
                                    </Stack>
                                </Styled.Section>

                                <Styled.Section>
                                    <Typography style={{ fontWeight: 'bold' }}>Marketplace</Typography>
                                    <hr />
                                    <Stack direction="row" gap={1} alignItems='center'>
                                        <Switch
                                            color="primary"
                                            checked={formik.values?.FlgLiberadoShopLeap}
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                if (formik.values?.FlgLiberadoShopLeap)
                                                    formik.setFieldValue(`FlgLiberadoShopLeap`, !formik.values?.FlgLiberadoShopLeap);
                                                else
                                                    getConfirmationExibirShopLeap();
                                            }}
                                        />
                                        <Typography sx={{ color: "gray" }}>Exibir no ShopLeap</Typography>
                                    </Stack>
                                    <Stack direction="row" gap={1} alignItems='center'>
                                        <Switch
                                            color="primary"
                                            checked={formik.values?.FlgLiberadoLeap}
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                if (formik.values?.FlgLiberadoLeap)
                                                    formik.setFieldValue(`FlgLiberadoLeap`, !formik.values?.FlgLiberadoLeap);
                                                else
                                                    getConfirmationExibirNoApp();
                                            }}
                                        />
                                        <Typography sx={{ color: "gray" }}>Exibir no app Leap</Typography>
                                    </Stack>
                                </Styled.Section>

                                <Styled.Section>
                                    <Typography style={{ fontWeight: 'bold' }}>Local de disponibilidade</Typography>
                                    <hr />
                                    <FormControl fullWidth>
                                        <InputLabel id="disponibilidade-selector">Disponibilidade</InputLabel>
                                        <Select
                                            labelId="disponibilidade-selector"
                                            id="disponibilidade-id"
                                            value={formik.values.Etiquetas[0]?.iDisponibilidade || 0}
                                            label="Disponibilidade"
                                            style={{ width: '100%' }}
                                            onChange={(e) => {
                                                formik.setFieldValue(`Etiquetas[0].iDisponibilidade`, Number(e.target.value));
                                            }}
                                        >
                                            <MenuItem disabled value={0}>Selecionar</MenuItem>
                                            <MenuItem value={1}>Ambos</MenuItem>
                                            <MenuItem value={2}>PDV</MenuItem>
                                            <MenuItem value={3}>eCommerce</MenuItem>
                                        </Select>
                                    </FormControl>

                                </Styled.Section>

                                <Styled.Section>
                                    <Typography style={{ fontWeight: 'bold' }}>Corredores</Typography>
                                    <hr />

                                    <Box style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                        {
                                            temaMenus.length > 0 && temaMenus.filter(a => a._TemaMenu.IdTemaMenuPai !== -1 && a._TemaMenu.IdTemaMenuPai === 0).map(menu => {
                                                return <Box key={`corredor-${menu.IdTemaMenu}`} style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <FormControlLabel key={menu.IdTemaMenu}
                                                        control={<Checkbox
                                                            checked={temaMenusImagem.filter(a => a.IdUsuarioEmpresaXTemaMenu === menu.IdUsuarioEmpresaXTemaMenu)[0]?.FlgAtivo}
                                                            onChange={() => {
                                                                const arrTemaMenuImagem = [...temaMenusImagem];
                                                                const idx = arrTemaMenuImagem.findIndex(a => a.IdUsuarioEmpresaXTemaMenu === menu.IdUsuarioEmpresaXTemaMenu);

                                                                if (idx > -1) {
                                                                    const status = !temaMenusImagem.filter(a => a.IdUsuarioEmpresaXTemaMenu === menu.IdUsuarioEmpresaXTemaMenu)[0]?.FlgAtivo;
                                                                    arrTemaMenuImagem[idx].FlgAtivo = status;

                                                                    if (!status) //desmarcar os filhos
                                                                    {
                                                                        temaMenus.filter(a => a._TemaMenu.IdTemaMenuPai !== -1 && a._TemaMenu.IdTemaMenuPai === menu.IdTemaMenu).map(menuFilho => {
                                                                            const idxFilho = arrTemaMenuImagem.findIndex(a => a.IdUsuarioEmpresaXTemaMenu === menuFilho.IdUsuarioEmpresaXTemaMenu);

                                                                            if (idxFilho > -1) {
                                                                                arrTemaMenuImagem[idxFilho].FlgAtivo = false;
                                                                            }
                                                                        })
                                                                    }
                                                                }
                                                                else {
                                                                    arrTemaMenuImagem.push({
                                                                        IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                                                                        IdUsuarioEmpresaXTemaMenu: menu.IdUsuarioEmpresaXTemaMenu,
                                                                        DataCadastro: moment().format('YYYY-MM-DD HH:mm:ss'),
                                                                        FlgAtivo: true
                                                                    });
                                                                }

                                                                setTemaMenusImagem(arrTemaMenuImagem);
                                                            }}
                                                        />}
                                                        label={menu._TemaMenu.Nome} />

                                                    {
                                                        temaMenus.filter(a => a._TemaMenu.IdTemaMenuPai !== -1 && a._TemaMenu.IdTemaMenuPai === menu.IdTemaMenu).map(menuFilho => {
                                                            return <FormControlLabel key={menuFilho.IdTemaMenu} style={{ marginLeft: 10 }}
                                                                control={<Checkbox
                                                                    disabled={!temaMenusImagem.filter(a => a.IdUsuarioEmpresaXTemaMenu === menu.IdUsuarioEmpresaXTemaMenu)[0]?.FlgAtivo}
                                                                    checked={temaMenusImagem.filter(a => a.IdUsuarioEmpresaXTemaMenu === menu.IdUsuarioEmpresaXTemaMenu)[0]?.FlgAtivo && temaMenusImagem.filter(a => a.IdUsuarioEmpresaXTemaMenu === menuFilho.IdUsuarioEmpresaXTemaMenu)[0]?.FlgAtivo}
                                                                    onChange={() => {
                                                                        const arrTemaMenuImagem = [...temaMenusImagem];
                                                                        const idxFilho = arrTemaMenuImagem.findIndex(a => a.IdUsuarioEmpresaXTemaMenu === menuFilho.IdUsuarioEmpresaXTemaMenu);

                                                                        if (idxFilho > -1) {
                                                                            arrTemaMenuImagem[idxFilho].FlgAtivo = !temaMenusImagem.filter(a => a.IdUsuarioEmpresaXTemaMenu === menuFilho.IdUsuarioEmpresaXTemaMenu)[0]?.FlgAtivo;
                                                                        }
                                                                        else {
                                                                            arrTemaMenuImagem.push({
                                                                                IdUsuarioEmpresa: userLoggedIn.IdUsuarioEmpresa,
                                                                                IdUsuarioEmpresaXTemaMenu: menuFilho.IdUsuarioEmpresaXTemaMenu,
                                                                                DataCadastro: moment().format('YYYY-MM-DD HH:mm:ss'),
                                                                                FlgAtivo: true
                                                                            });
                                                                        }

                                                                        setTemaMenusImagem(arrTemaMenuImagem);
                                                                    }}
                                                                />}
                                                                label={menuFilho._TemaMenu.Nome} />
                                                        })
                                                    }
                                                </Box>
                                            })
                                        }

                                        {
                                            temaMenus.filter(a => a._TemaMenu.IdTemaMenuPai !== -1).length === 0 && <Typography>Nenhum item encontrado</Typography>
                                        }
                                    </Box>
                                </Styled.Section>

                                <Styled.Section>
                                    <Typography style={{ fontWeight: 'bold' }}>*Marketing e SEO</Typography>
                                    <hr />
                                    <Box style={{ display: 'flex', marginTop: 5 }}>
                                        <LocalOfferIcon style={{ color: 'red' }} />
                                        <Typography style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => window.open(`${window.location.origin}/cupons`)}>Criar cupom</Typography>
                                    </Box>
                                    {
                                        /*
                                        <Box style={{ display: 'flex', marginTop: 10 }}>
                                            <CampaignIcon />
                                            <Typography style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => console.log('Divulgar produto')}>Divulgar produto</Typography>
                                        </Box>
                                        <Box style={{ display: 'flex', marginTop: 10 }}>
                                            <SettingsSuggestIcon />
                                            <Typography style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => console.log('Editar configurações de SEO')}>Editar configurações de SEO</Typography>
                                        </Box>
                                         */
                                    }
                                </Styled.Section>
                            </Grid>
                            {
                                formik.values.ImagemSrc !== null && (<>
                                    <Grid item xs={12}>
                                        <Styled.Section>
                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Typography style={{ fontWeight: 'bold' }}>Detalhes</Typography>
                                                    <hr />
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="tamanho-selector">Gênero</InputLabel>
                                                        <Select
                                                            labelId="tamanho-selector"
                                                            id="tamanho-selector-id"
                                                            value={formik.values.Etiquetas[etiquetaSelecionada]?.idPublicoAlvo}
                                                            label="Tamanhos disponíveis"
                                                            style={{ width: '100%' }}
                                                            onChange={async (e) => {
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].idPublicoAlvo`, Number(e.target.value));
                                                                await fetchCategorias(e.target.value);
                                                            }}
                                                        >
                                                            <MenuItem value={-1} disabled>Favor selecionar um gênero</MenuItem>
                                                            {
                                                                sexosLoja.map(sexo => {
                                                                    return <MenuItem key={`sexo-${sexo.IdSexo}`} value={sexo.IdSexo}>{sexo._Sexo.SEX_Nome}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                    <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.idPublicoAlvo}</Styled.AlertError>
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            // disabled={isEdicao && (formik.values.Etiquetas[etiquetaSelecionada]?.IdEtiqueta && formik.values.Etiquetas[etiquetaSelecionada]?.IdEtiqueta > 0)}
                                                            // readOnly={isEdicao && (formik.values.Etiquetas[etiquetaSelecionada]?.IdEtiqueta && formik.values.Etiquetas[etiquetaSelecionada]?.IdEtiqueta > 0)}
                                                            id={`Etiquetas[${etiquetaSelecionada}].IdCategoria`}
                                                            options={categorias}
                                                            value={formik.values.Etiquetas[etiquetaSelecionada]?.InputCategoria || categorias[0]}
                                                            defaultValue={0}
                                                            getOptionLabel={(option) => option?.Descricao || ''}
                                                            onChange={(event, newValue) => {
                                                                if (formik.values.Etiquetas[etiquetaSelecionada].IdEtiqueta > 0) {
                                                                    setNovaCategoriaSelecionada(newValue);
                                                                    getConfirmationUpdateCategoria();
                                                                }
                                                                else if (formik.values.Etiquetas[etiquetaSelecionada].IdEtiqueta === 0) {
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdCategoria`, newValue?.IdCategoria);
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputCategoria`, newValue);
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].Categoria`, newValue);
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputPeca`, { IdPeca: 0, Nome: 'Nenhum(a)' });
                                                                    setChangeCategoria(newValue?.IdCategoria);
                                                                }

                                                            }}
                                                            inputValue={formik.values.Etiquetas[etiquetaSelecionada]?.InputValueCategoria}
                                                            onInputChange={(event, newInputValue) => {
                                                                if (newInputValue === '') formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdCategoria`, 0);
                                                                if (formik.values.Etiquetas[etiquetaSelecionada].IdEtiqueta > 0) {
                                                                    setNovoInputValueCategoriaSelecionada(newInputValue);
                                                                }
                                                                else if (formik.values.Etiquetas[etiquetaSelecionada].IdEtiqueta === 0) {
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValueCategoria`, newInputValue);
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValuePeca`, 'Nenhum(a)');
                                                                }
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} variant="outlined" label="Categoria" placeholder="Categoria" />
                                                            )}
                                                        />
                                                        <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.IdCategoria}</Styled.AlertError>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            // disabled={isEdicao && (formik.values.Etiquetas[etiquetaSelecionada]?.IdEtiqueta && formik.values.Etiquetas[etiquetaSelecionada]?.IdEtiqueta > 0)}
                                                            // readOnly={isEdicao && (formik.values.Etiquetas[etiquetaSelecionada]?.IdEtiqueta && formik.values.Etiquetas[etiquetaSelecionada]?.IdEtiqueta > 0)}
                                                            id={`Etiquetas[${etiquetaSelecionada}].IdPeca`}
                                                            options={pecas}
                                                            value={formik.values.Etiquetas[etiquetaSelecionada]?.InputPeca || pecas[0]}
                                                            defaultValue={0}
                                                            getOptionLabel={(option) => option?.Nome || ''}
                                                            onChange={(event, newValue) => {
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdPeca`, newValue?.IdPeca);
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputPeca`, newValue);

                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputSubPeca`, { IdPeca: 0, Nome: 'Nenhum(a)' });
                                                                setChangePeca(newValue?.IdPeca);
                                                            }}
                                                            inputValue={formik.values.Etiquetas[etiquetaSelecionada]?.InputValuePeca}
                                                            onInputChange={(event, newInputValue) => {
                                                                if (newInputValue === '') formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdPeca`, 0);
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValuePeca`, newInputValue);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} variant="outlined" label="Peça" placeholder="Peça" />
                                                            )}
                                                        />
                                                        <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.IdPeca}</Styled.AlertError>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            id={`Etiquetas[${etiquetaSelecionada}].IdSubPeca`}
                                                            options={subPecas}
                                                            value={formik.values.Etiquetas[etiquetaSelecionada]?.InputSubPeca || subPecas[0]}
                                                            defaultValue={0}
                                                            getOptionLabel={(option) => option?.Nome || ''}
                                                            onChange={(event, newValue) => {
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdSubPeca`, newValue?.IdSubPeca);
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputSubPeca`, newValue);
                                                            }}
                                                            inputValue={formik.values.Etiquetas[etiquetaSelecionada]?.InputValueSubPeca}
                                                            onInputChange={(event, newInputValue) => {
                                                                if (newInputValue === '') formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdSubPeca`, 0);
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValueSubPeca`, newInputValue);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} variant="outlined" label="Sub Peça" placeholder="Sub Peça" />
                                                            )}
                                                        />
                                                        <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.IdSubPeca}</Styled.AlertError>
                                                    </FormControl>
                                                </Grid>

                                                {
                                                    /* 
                                                    <Grid item xs={12} md={3}>                
                                                        <FormControl fullWidth>
                                                            <Autocomplete
                                                                id={`Etiquetas[${etiquetaSelecionada}].IdCor`}
                                                                disabled={!formik.values.Etiquetas[etiquetaSelecionada]?.IdPeca}
                                                                readOnly={!formik.values.Etiquetas[etiquetaSelecionada]?.IdPeca}
                                                                options={coresPecas}
                                                                value={formik.values.Etiquetas[etiquetaSelecionada]?.InputCor || coresPecas[0]}
                                                                defaultValue={0}
                                                                getOptionLabel={(option) => option?.Descricao || ''}
                                                                onChange={(event, newValue) => {
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdCor`, newValue?.IdCor);
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputCor`, newValue);
                                                                }}
                                                                inputValue={formik.values.Etiquetas[etiquetaSelecionada]?.InputValueCor}
                                                                onInputChange={(event, newInputValue) => {
                                                                    if (newInputValue === '') formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdCor`, 0);
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValueCor`, newInputValue);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} variant="outlined" label="Cor da Peça" placeholder="Cor da Peça" />
                                                                )}
                                                            />
                                                            <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.IdCor}</Styled.AlertError>
                                                        </FormControl> 
                                                    </Grid>
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl fullWidth>
                                                            <Autocomplete
                                                                id={`Etiquetas[${etiquetaSelecionada}].IdCor`}
                                                                disabled={!formik.values.Etiquetas[etiquetaSelecionada]?.IdPeca}
                                                                readOnly={!formik.values.Etiquetas[etiquetaSelecionada]?.IdPeca}
                                                                options={estampas}
                                                                value={formik.values.Etiquetas[etiquetaSelecionada]?.InputEstampa || estampas[0]}
                                                                defaultValue={0}
                                                                getOptionLabel={(option) => option?.Descricao || ''}
                                                                onChange={(event, newValue) => {
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdEstampa`, newValue?.IdEstampa);
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputEstampa`, newValue);
                                                                }}
                                                                inputValue={formik.values.Etiquetas[etiquetaSelecionada]?.InputValueEstampa}
                                                                onInputChange={(event, newInputValue) => {
                                                                    if (newInputValue === '') formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdEstampa`, 0);
                                                                    formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValueEstampa`, newInputValue);
                                                                }}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} variant="outlined" label="Estampa" placeholder="Estampa" />
                                                                )}
                                                            />
                                                            <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.IdEstampa}</Styled.AlertError>
                                                        </FormControl> 
                                                    </Grid>
                                                    */
                                                }

                                                {
                                                    (themeStorage?.tipoThema !== 10) &&
                                                    <Grid item xs={12} md={3}>
                                                        <FormControl fullWidth>
                                                            {
                                                                materiais &&
                                                                <>
                                                                    <Autocomplete
                                                                        id={`Etiquetas[${etiquetaSelecionada}].IdMaterial`}
                                                                        options={materiais}
                                                                        value={formik.values.Etiquetas[etiquetaSelecionada]?.InputMaterial || materiais[0]}
                                                                        defaultValue={0}
                                                                        getOptionLabel={(option) => option?.Descricao || 'Nenhum(a)'}
                                                                        onChange={(event, newValue) => {
                                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdMaterial`, newValue?.IdMaterial)
                                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputMaterial`, newValue);
                                                                        }}
                                                                        inputValue={formik.values.Etiquetas[etiquetaSelecionada]?.InputValueMaterial}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            if (newInputValue === '') formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdMaterial`, 0);
                                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValueMaterial`, newInputValue);
                                                                        }}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} variant="outlined" label="Material" placeholder="Material" />
                                                                        )}
                                                                    />
                                                                    <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.IdMaterial}</Styled.AlertError>
                                                                </>
                                                            }
                                                        </FormControl>
                                                    </Grid>
                                                }


                                                <Grid item xs={12} md={3}>
                                                    <FormControl fullWidth>
                                                        <Autocomplete
                                                            id={`Etiquetas[${etiquetaSelecionada}].IdMarca`}
                                                            options={marcas}
                                                            value={formik.values.Etiquetas[etiquetaSelecionada]?.InputMarca || marcas[0]}
                                                            defaultValue={0}
                                                            getOptionLabel={(option) => option?.Nome || ''}
                                                            onChange={(event, newValue) => {
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdMarca`, newValue?.IdMarca)
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputMarca`, newValue);
                                                            }}
                                                            inputValue={formik.values.Etiquetas[etiquetaSelecionada]?.InputValueMarca}
                                                            onInputChange={(event, newInputValue) => {
                                                                if (newInputValue === '') formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdMarca`, 0);
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValueMarca`, newInputValue);
                                                            }}
                                                            renderInput={(params) => (
                                                                <TextField {...params} variant="outlined" label="Marca" placeholder="Marca" />
                                                            )}
                                                        />
                                                        <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.IdMarca}</Styled.AlertError>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Styled.Section>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Styled.Section>
                                            <Typography style={{ fontWeight: 'bold' }}>Opções de produto</Typography>
                                            <Typography style={{ fontSize: 12 }}>Gerencie as opçõs desse produto</Typography>
                                            {/* <hr />
                                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                <Typography style={{ fontWeight: 'bold' }}>Público alvo</Typography>
                                                <FormControl style={{ marginLeft: 20 }}>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="1"
                                                        name="QuantidadeEtiquetas"
                                                        value={formik.values.Etiquetas[etiquetaSelecionada]?.idPublicoAlvo}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].idPublicoAlvo`, Number(e.target.value))
                                                        }}
                                                        style={{ display: 'flex', flexDirection: 'row' }}
                                                    >
                                                        <FormControlLabel value={2} defaultChecked control={<Radio />} label="Feminino" />
                                                        <FormControlLabel value={1} control={<Radio />} label="Masculino" />
                                                        <FormControlLabel value={3} control={<Radio />} label="Geral" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box> */}
                                            <hr />
                                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                <Typography style={{ fontWeight: 'bold' }}>Peça infantil</Typography>
                                                <FormControl style={{ marginLeft: 20 }}>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue='nao'
                                                        name="FlagKids"
                                                        value={formik.values.Etiquetas[etiquetaSelecionada]?.FlagKids ? 'sim' : 'nao'}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].FlagKids`, e.target.value === "sim" ? true : false)
                                                        }}
                                                        style={{ display: 'flex', flexDirection: 'row' }}
                                                    >
                                                        <FormControlLabel value='sim' control={<Radio />} label="Sim" />
                                                        <FormControlLabel value='nao' defaultChecked control={<Radio />} label="Não" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                            <hr />
                                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                                <Typography style={{ fontWeight: 'bold' }}>Condição</Typography>
                                                <FormControl style={{ marginLeft: 20 }}>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue='nao'
                                                        name="FlagKids"
                                                        value={formik.values.Etiquetas[etiquetaSelecionada]?.FlagPecaNova ? 'nova' : 'seminova'}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].FlagPecaNova`, e.target.value === "nova" ? true : false)
                                                        }}
                                                        style={{ display: 'flex', flexDirection: 'row' }}
                                                    >
                                                        <FormControlLabel value='nova' control={<Radio />} label="Nova" />
                                                        <FormControlLabel value='seminova' control={<Radio />} label="Seminova" />
                                                    </RadioGroup>
                                                </FormControl>
                                                <hr />
                                            </Box>
                                        </Styled.Section>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Styled.Section>
                                            <Typography style={{ fontWeight: 'bold' }}>Detalhes da peça</Typography>
                                            <hr />
                                            <Box>
                                                {formik.values.Etiquetas[etiquetaSelecionada]?.IdCategoria && (
                                                    <Stack direction="row" alignItems="center" gap={1} style={{ width: '100%' }}>
                                                        <Typography>Categoria:</Typography>
                                                        <Chip
                                                            color="secondary"
                                                            label={
                                                                formik.values.Etiquetas[etiquetaSelecionada]?.Categoria?.Descricao
                                                            }
                                                        />
                                                    </Stack>
                                                )}
                                            </Box>
                                            <Box style={{ width: '100%', marginTop: 20 }}>
                                                <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?._EtiquetaComplemento}</Styled.AlertError>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} md={2.5}>
                                                        <FormControl fullWidth>
                                                            {
                                                                listaTamanhos[etiquetaSelecionada] &&
                                                                <Autocomplete
                                                                    id="tamanho-selector-id"
                                                                    options={listaTamanhos[etiquetaSelecionada]}
                                                                    value={formik.values.Etiquetas[etiquetaSelecionada]?.InputTamanho || 0}
                                                                    defaultValue={0}
                                                                    getOptionLabel={(option) => option?.Descricao || ''}
                                                                    onChange={(event, newValue) => {
                                                                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdTamanho`, newValue?.IdTamanho)
                                                                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputTamanho`, newValue);

                                                                        let arrTamanho = [...tamanhoSelecionadoEC];
                                                                        arrTamanho[etiquetaSelecionada] = newValue?.IdTamanho;

                                                                        if (formik.values.Etiquetas[etiquetaSelecionada].IdCategoria === 1) {
                                                                            if (formik.values.Etiquetas[etiquetaSelecionada].FlagKids) {
                                                                                arrTamanho[etiquetaSelecionada] = 202;
                                                                            }
                                                                            else {
                                                                                arrTamanho[etiquetaSelecionada] = 201;
                                                                            }
                                                                        }
                                                                        else if (formik.values.Etiquetas[etiquetaSelecionada].IdCategoria === 3) {
                                                                            if (formik.values.Etiquetas[etiquetaSelecionada].FlagKids) {
                                                                                arrTamanho[etiquetaSelecionada] = 204;
                                                                            }
                                                                            else {
                                                                                arrTamanho[etiquetaSelecionada] = 203;
                                                                            }
                                                                        }
                                                                        setTamanhoSelecionadoEC(arrTamanho);
                                                                    }}
                                                                    inputValue={formik.values.Etiquetas[etiquetaSelecionada]?.InputValueTamanho}
                                                                    onInputChange={(event, newInputValue) => {
                                                                        if (newInputValue === '') formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdTamanho`, 0);
                                                                        formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValueTamanho`, newInputValue);
                                                                    }}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} variant="outlined" label="Tamanhos" placeholder="Tamanhos" />
                                                                    )}
                                                                />
                                                            }
                                                            {
                                                                !listaTamanhos[etiquetaSelecionada] &&
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="tamanho-selector">Tamanhos</InputLabel>
                                                                    <Select
                                                                        labelId="tamanho-selector"
                                                                        id="tamanho-selector-id"
                                                                        value={0}
                                                                        label="Tamanhos disponíveis"
                                                                        style={{ width: '100%' }}
                                                                        onChange={(e) => {

                                                                        }}
                                                                    >
                                                                        <MenuItem value={0}>Favor selecionar uma categoria</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            }
                                                        </FormControl>
                                                    </Grid>

                                                    {
                                                        (themeStorage?.tipoThema !== 10) &&
                                                        <>
                                                            <Grid item xs={12} md={2.5}>
                                                                <FormControl fullWidth>
                                                                    {
                                                                        coresPecas &&
                                                                        <Autocomplete
                                                                            id="cor-peca-selector-id"
                                                                            options={coresPecas}
                                                                            value={formik.values.Etiquetas[etiquetaSelecionada]?.InputCorPeca || coresPecas[0]}
                                                                            defaultValue={0}
                                                                            getOptionLabel={(option) => option?.Descricao || ''}
                                                                            onChange={(event, newValue) => {
                                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdCorPeca`, newValue?.IdCor)
                                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputCorPeca`, newValue);

                                                                                const arrPeca = [...corPecaSelecionadaEC]
                                                                                arrPeca[etiquetaSelecionada] = newValue?.IdCor;

                                                                                setCorPecaSelecionadaEC(arrPeca);
                                                                            }}
                                                                            inputValue={formik.values.Etiquetas[etiquetaSelecionada]?.InputValueCorPeca}
                                                                            onInputChange={(event, newInputValue) => {
                                                                                if (newInputValue === '') formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdCorPeca`, 0);
                                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValueCorPeca`, newInputValue);
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField {...params} variant="outlined" label="Cor da peça" placeholder="Cor da peça" />
                                                                            )}
                                                                        />
                                                                    }
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid item xs={12} md={3}>
                                                                <FormControl fullWidth>
                                                                    {
                                                                        estampas &&
                                                                        <Autocomplete
                                                                            id="estampa-selector-id"
                                                                            options={estampas}
                                                                            value={formik.values.Etiquetas[etiquetaSelecionada]?.InputEstampa || estampas[0]}
                                                                            defaultValue={0}
                                                                            getOptionLabel={(option) => option?.Descricao || ''}
                                                                            onChange={(event, newValue) => {
                                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdEstampa`, newValue?.IdEstampa);
                                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputEstampa`, newValue);

                                                                                const arrEstampa = [...estampaSelecionadaEC];
                                                                                arrEstampa[etiquetaSelecionada] = newValue?.IdEstampa;

                                                                                setEstampaSelecionadaEC(arrEstampa);
                                                                            }}
                                                                            inputValue={formik.values.Etiquetas[etiquetaSelecionada]?.InputValueEstampa}
                                                                            onInputChange={(event, newInputValue) => {
                                                                                if (newInputValue === '') formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].IdEstampa`, 0);
                                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].InputValueEstampa`, newInputValue);
                                                                            }}
                                                                            renderInput={(params) => (
                                                                                <TextField {...params} variant="outlined" label="Estampa" placeholder="Estampa" />
                                                                            )}
                                                                        />
                                                                    }
                                                                </FormControl>
                                                            </Grid>
                                                        </>
                                                    }

                                                    <Grid item xs={12} md={2}>
                                                        <TextField
                                                            id={`sku`}
                                                            label='SKU'
                                                            style={{ width: '100%' }}
                                                            value={skuEC[etiquetaSelecionada] || ''}
                                                            onChange={(e) => {
                                                                const arrSku = [...skuEC];
                                                                arrSku[etiquetaSelecionada] = e.target.value;

                                                                setSkuEC(arrSku);
                                                            }}
                                                        />
                                                    </Grid>

                                                    <Grid item xs={1.5} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Button
                                                            variant="contained"
                                                            sx={{ ml: 1 }}
                                                            disabled={!tamanhoSelecionadoEC}
                                                            onClick={handleAddTamanho}
                                                            style={{ maxHeight: 100 }}
                                                        >
                                                            Adicionar
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                            <Stack direction="column">
                                                <TableTamanhosSelecionados
                                                    listaTamanhos={listaTamanhos[etiquetaSelecionada]}
                                                    etiqueta={formik.values.Etiquetas[etiquetaSelecionada]}
                                                    listaTamanhosSelecionados={formik.values.Etiquetas[etiquetaSelecionada]?._EtiquetaComplemento}
                                                    handleSetListaTamanhosSelecionados={handleSetListaTamanhosSelecionados}
                                                    handleRemoverTamanho={handleRemoverTamanho}
                                                />
                                            </Stack>
                                        </Styled.Section>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Styled.Section>
                                            <Typography style={{ fontWeight: 'bold' }}>Preços</Typography>
                                            <hr />
                                            <Box style={{ width: '100%', display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginTop: 20 }}>
                                                <Box>
                                                    <NumericFormat
                                                        prefix={"R$ "}
                                                        defaultValue={0}
                                                        value={formik.values.Etiquetas[etiquetaSelecionada]?.mValorCusto || 0}
                                                        style={{ marginRight: 10 }}
                                                        label="Valor de custo"
                                                        variant="outlined"
                                                        customInput={TextField}
                                                        decimalSeparator=","
                                                        onBlur={(e) => {
                                                            const valor = e.target.value.replace(/\D[,]/g, "").replace('R$ ', '').trim();
                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].mValorCusto`, valor);
                                                        }}
                                                    />
                                                    <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.mValorCusto}</Styled.AlertError>
                                                </Box>
                                                <Box>
                                                    <NumericFormat
                                                        prefix={"R$ "}
                                                        defaultValue={0}
                                                        value={formik.values.Etiquetas[etiquetaSelecionada]?.mValor || 0}
                                                        label="Valor de venda"
                                                        variant="outlined"
                                                        customInput={TextField}
                                                        decimalSeparator=","
                                                        onBlur={(e) => {
                                                            const valor = e.target.value.replace(/\D[,]/g, "").replace('R$ ', '').trim();
                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].mValor`, valor);
                                                        }}
                                                    />
                                                    <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.mValor}</Styled.AlertError>
                                                </Box>
                                                <Box direction="row" style={{ marginTop: querySelector ? 20 : 0 }}>
                                                    <NumericFormat
                                                        style={{ marginLeft: 10 }}
                                                        prefix={"% "}
                                                        defaultValue={0}
                                                        value={formik.values.Etiquetas[etiquetaSelecionada]?.mPorcentoDesconto || 0}
                                                        label="Desconto"
                                                        variant="outlined"
                                                        customInput={TextField}
                                                        decimalSeparator=","
                                                        onBlur={(e) => {
                                                            const valor = e.target.value.replace(/\D[,]/g, "").replace('% ', '').trim();
                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].mPorcentoDesconto`, valor)
                                                        }}
                                                    />
                                                    <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.mPorcentoDesconto}</Styled.AlertError>
                                                </Box>
                                            </Box>
                                        </Styled.Section>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Styled.Section>
                                            <Typography style={{ fontWeight: 'bold' }}>Dimensoes do Pacote/Caixa</Typography>
                                            <hr />
                                            <Grid container spacing={2} style={{ width: '100%', display: 'flex', flexWrap: querySelector ? 'wrap' : 'nowrap', alignItems: 'center' }}>
                                                <Grid item xs={12} md={3}>
                                                    <InputText
                                                        error={!isMaxDimensionsValid}
                                                        id={`Etiquetas[${etiquetaSelecionada}].Altura`}
                                                        type='number'
                                                        label="Altura (cm)"
                                                        value={formik.values.Etiquetas[etiquetaSelecionada]?.Altura || 0}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].Altura`, Number(e.target.value.replace(/\D/g, "").replace('.', '').replace(',', '').replace('R$ ', '').trim()))
                                                        }}
                                                    />
                                                    <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.Altura}</Styled.AlertError>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <InputText
                                                        error={!isMaxDimensionsValid}
                                                        id={`Etiquetas[${etiquetaSelecionada}].Largura`}
                                                        type='number'
                                                        label="Largura (cm)"
                                                        value={formik.values.Etiquetas[etiquetaSelecionada]?.Largura || ''}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].Largura`, Number(e.target.value.replace(/\D/g, "").replace('.', '').replace(',', '').replace('R$ ', '').trim()))
                                                        }}
                                                    />
                                                    <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.Largura}</Styled.AlertError>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <InputText
                                                        error={!isMaxDimensionsValid}
                                                        id={`Etiquetas[${etiquetaSelecionada}].Profundidade`}
                                                        type='number'
                                                        label="Profundidade (cm)"
                                                        value={formik.values.Etiquetas[etiquetaSelecionada]?.Profundidade || ''}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].Profundidade`, Number(e.target.value.replace(/\D/g, "").replace('.', '').replace(',', '').replace('R$ ', '').trim()))
                                                        }}
                                                    />
                                                    <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.Profundidade}</Styled.AlertError>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <FormControl style={{ marginTop: 10, width: '80%' }}>
                                                        <InputLabel id="select-peso">Peso</InputLabel>
                                                        <Select
                                                            labelId="select-peso"
                                                            id="select-peso-field"
                                                            style={{ height: '50px' }}
                                                            error={!isMaxWeightValid}
                                                            defaultValue={0}
                                                            value={parsePesoPacoteAppToCrm(formik.values.Etiquetas[etiquetaSelecionada]?.Peso || '')}
                                                            label="Peso"
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`Etiquetas[${etiquetaSelecionada}].Peso`, parsePesoPacoteCrmToApp(Number(e.target.value)));
                                                            }}
                                                        >
                                                            <MenuItem key={0} value={0}>Nenhum(a)</MenuItem>
                                                            {pesoOptions?.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                            ))}
                                                        </Select>
                                                        <Styled.AlertError className="msg-error">{formik.errors.Etiquetas && formik.errors.Etiquetas[etiquetaSelecionada]?.Peso}</Styled.AlertError>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Styled.Section>
                                    </Grid>

                                </>)
                            }

                            <Grid item xs={12}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    sx={{ width: "100%" }}
                                >
                                    <Button variant="outlined"
                                        color="secondary"
                                        size="large"
                                        style={{ marginRight: 20, fontSize: 10 }}
                                        onClick={() => navigate("/produtos", { replace: true })}
                                    >
                                        Voltar
                                    </Button>
                                    <Button
                                        disabled={salvando}
                                        readOnly={salvando}
                                        variant="contained"
                                        color="primary"
                                        type='submite'
                                    >
                                        {salvando && <Loader />}
                                        Salvar Produto
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    }

                    {
                        tabSelecionada === 1 &&
                        <Styled.Container>
                            {
                                loadingRelacionados &&
                                <Loading marginTop={100} texto="Buscando produtos relacionados" />
                            }

                            {
                                !loadingRelacionados &&
                                <>
                                    <ProductList ehAtacado={false} produtosRelacionados={produtosRelacionados} productList={listaProdutosRelacionados} onChangeSelected={(idImagem, idEtiqueta, idEtiquetaComplento, selecionado) => onChangeSelected(idImagem, idEtiqueta, idEtiquetaComplento, selecionado)} />

                                    <Box style={{ width: '100%', marginTop: 10, marginBottom: 10, display: 'flex', justifyContent: 'center' }}>
                                        <Pagination
                                            size="large"
                                            color="primary"
                                            showFirstButton
                                            showLastButton
                                            count={totalPages}
                                            page={page}
                                            onChange={handleChangePage}
                                        />
                                    </Box>
                                </>
                            }

                        </Styled.Container>
                    }
                </Styled.Container>
            )}

        </form >

        return componente;
    }
    catch (err) {
        SalvarLogSentry(err);
        const obj = {
            Descricao: 'Erro ao abrir detalhes do produto',
            Erro: err.message,
            Controller: 'CRM CMS',
            DataErro: moment().format('yyyy-MM-DD HH:mm:ss')
        };

        PUT_DATA(`LogErro/CadastroLogErro`, obj);
        navigate('/not-found', { replace: true });
    }
};
