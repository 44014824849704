import { Box, Button, ButtonGroup, CircularProgress, Divider, FormControlLabel, Grid, MenuItem, Select, Stack, Switch, TextField, Typography } from "@mui/material";
import Card from "../../../components/Card";
import * as Yup from 'yup';
import { AlertError, Container } from "./styles";
import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { POST_DATA, POST_DATA_FILE, PUT_DATA, PUT_DATA_FILE, SalvarLogSentry, UploadFileAzure } from "../../../services/API";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../ui-component/Loader";
import { useFormik } from "formik";
import UploadImagem from "../../../components/UploadImagem";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import { NumericFormat } from "react-number-format";
import axios from "axios";
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";
import * as moment from "moment";
import { atualizarOnboardStore } from "../../../services/utils/functions";

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { pt } from 'date-fns/locale'

const InitialValue = {
    "IdUsuarioEmpresa": 0,
    "IdUsuario": 0,
    "IdUsuarioEmpresaPlano": 0,
    "NomeFantasia": "",
    "TipoEmpresa": "MEI",
    "Cnpj": "",
    "Logradouro": "",
    "CEP": "",
    "Telefone": "",
    "Complemento": "",
    "Numero": "",
    "Bairro": "",
    "Cidade": "",
    "Estado": "",
    "FlgAtivo": true,
    "DataCadastro": "",
    "RazaoSocial": "",
    "FlgValidado": false,
    "iStatus": 1,
    "sPathInscricaoEstadual": "",
    "sPathContratoSocial": "",
    "sPathBanner": "",
    "sPathlogo": "",
    "FlgBrecho": false,
    "valorMinimoProduto": 0.0,
    "valorMaximoProduto": 0.0,
    "quantidadeEtapaPreco": 0,
    "qtdEmailsCarrinhosAbandonados": 0,
    "FotoCapaShopLeap": "",
    "Peso": 0,
    "QtdProdutosAVenda": 0,
    "QtdProdutosVendido": 0,
    "FlgSMS": false,
    "FlgWhatsApp": false,
    "contatoWhataapp": "",
    "contatoSms": "",
    "metaFaturamento": 1200,
    "metaVenda": 300,
    "FlgParcelamento": false,
    "qtdParcelamento": 0,
    "ConstrutorSite": null,
    "TipoConta": 1,
    "DataNascimento": ''
}

const ConfigLoja = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onBoard = useSelector((store) => store.onBoard);
    const cepInputRef = useRef(null);

    const [usuario, setUsuario] = useState(null);
    const [logoLoja, setLogoLoja] = useState(null);
    const [fotoCapaLoja, setFotoCapaLoja] = useState(null);

    const [isLoadingCep, setIsLoadingCep] = useState(false);
    const [errorCep, setErrorCep] = useState(false);

    const [usuarioEmpresa, setUsuarioEmpresa] = useState(InitialValue);
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [loadingSalvando, setLoadingSalvando] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        (async () => {
            if (!userLoggedIn) return;

            setLoading(true);

            const user = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${userLoggedIn.IdUsuario}&join=true&Log=`);
            const userEmpresa = await POST_DATA(`UsuarioEmpresa/GetUsuarioEmpresaByIdUsuario?IdUsuario=${userLoggedIn.IdUsuario}`);

            if(userEmpresa.DataNascimento !== null){
                userEmpresa.DataNascimento = new Date(userEmpresa.DataNascimento);
            }
            else {
                userEmpresa.DataNascimento = '';
            }

            if(userEmpresa.Telefone === null){
                userEmpresa.Telefone = '';
            }

            if(user._Endereco.length > 0 && userEmpresa.CEP === null){
                userEmpresa.CEP = user._Endereco[0].END_CEP;
                userEmpresa.Logradouro = user._Endereco[0].END_Logradouro;
                userEmpresa.Complemento = user._Endereco[0].END_Complemento;
                userEmpresa.Numero = user._Endereco[0].END_Numero;
                userEmpresa.Bairro = user._Endereco[0].END_Bairro;
                userEmpresa.Cidade = user._Endereco[0].END_Cidade;
                userEmpresa.Estado = user._Endereco[0].END_Estado;
            }

            userEmpresa.contatoSms = userEmpresa?.contatoSms === null ? '' : userEmpresa.contatoSms;
            userEmpresa.contatoWhataapp = userEmpresa?.contatoWhataapp === null ? '' : userEmpresa.contatoWhataapp;

            setLogoLoja(user.FotoProfile);
            setFotoCapaLoja(userEmpresa?.FotoCapaShopLeap);
            setUsuario(user);
            setUsuarioEmpresa(userEmpresa);
            setLoading(false);
            formik.resetForm();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoggedIn]);

    const validationSchema = Yup.object().shape({
        NomeFantasia: Yup.string().required('*Favor informar o Nome Fantasia'),
        RazaoSocial: Yup.string().required('*Favor informar a Razão Social'),
        Cnpj: Yup.string().required('*Favor informar o CNPJ'),

        CEP: Yup.string().required('*Favor informar o CEP'),
        Logradouro: Yup.string().required('*Favor informar o Logradouro'),
        Numero: Yup.string().required('*Favor informar o Número'),
        Bairro: Yup.string().required('*Favor informar o Bairro'),
        Cidade: Yup.string().required('*Favor informar a Cidade'),
        Telefone: Yup.string().test(
            'validateContato',
            '*Favor informar o número de celular com no mínimo 11 dígitos',
            (telefone, context) => {                
                return telefone?.toString().length >= 11;
            }
        ),

        contatoSms: Yup.string().test(
            'validateEnvioSms',
            '*Favor informar o contato SMS',
            (contatoSms, context) => {
                if (!context.parent.FlgSMS) return true;
                return contatoSms && contatoSms?.toString().length >= 11;
            }
        ),
        contatoWhataapp: Yup.string().test(
            'validateEnvioWhatsapp',
            '*Favor informar o contato WhatsApp',
            (contatoWhataapp, context) => {
                if (!context.parent.FlgWhatsApp) return true;
                return contatoWhataapp && contatoWhataapp?.toString().length >= 11;
            }
        ),
        qtdParcelamento: Yup.string().test(
            'validateQtdParcelas',
            '*Favor informar a quantidade de parcelas',
            (qtdParcelamento, context) => {

                if (!context.parent.FlgParcelamento) return true;
                return qtdParcelamento > 0;
            }
        ),
        DataNascimento: Yup.string().test(
            'validateDataNascimento',
            '*Favor informar a sua data de nascimento',
            (dataNascimento, context) => {
                if (context.parent?.TipoConta === 2 && (dataNascimento === null || dataNascimento === undefined || dataNascimento?.trim()?.length === 0)) return false;
                return true;
            }
        ),
    });

    const onSubmit = async (values) => {
        try {

            if (logoLoja === null) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'É necessário informar a logo da loja',
                });
                return;
            }

            values.FlgBrecho = usuario.FlgBrecho;

            setLoadingSalvando(true);

            if (logoLoja !== null && !logoLoja?.toString().includes('http')) {
                const formData = new FormData();
                formData.append("file", logoLoja);
                await PUT_DATA_FILE(`Usuario/UploadImagemPerfil?UserId=${userLoggedIn.IdUsuario}`, formData);
            }

            values.FotoCapaShopLeap = fotoCapaLoja;

            if (fotoCapaLoja !== null && !fotoCapaLoja?.toString().includes('http')) {
                const extensao = fotoCapaLoja.type.split("/");
                const nomeArquivo = `fotoCapaLoja${moment().format('DDMMYYYYHHmmss')}.${extensao[1]}`;
                const pasta = `${usuarioEmpresa.Cnpj}/images`;
                const result = await UploadFileAzure(fotoCapaLoja, pasta, nomeArquivo, fotoCapaLoja.type);

                values.FotoCapaShopLeap = result;
            }

            const possuiEndereco = usuario._Endereco.length > 0;
            const oldEndereco = possuiEndereco ? usuario._Endereco[0] : null;
            const endereco = {
                END_Id: possuiEndereco ? oldEndereco.END_Id : 0,
                End_IdUsuario: userLoggedIn.IdUsuario,
                END_Logradouro: values.Logradouro,
                END_Bairro: values.Bairro,
                END_Cidade: values.Cidade,
                END_Estado: values.Estado,
                END_Complemento: values.Complemento,
                END_CEP: values.CEP,
                END_Ativo: 1,
            }

            await POST_DATA('Usuario/updateDadosUsuario', usuario);
            await PUT_DATA('Endereco/CadastroEndereco', endereco);
            await POST_DATA('UsuarioEmpresa/UpdateUsuarioEmpresa', values);
            await PUT_DATA('UsuarioEmpresa/AtualizarHoraAtendimentoUsuarioEmpresa', values);

            setLoadingSalvando(false);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Sucesso',
                message: 'Sucesso ao salvar os dados da Loja',
            });

            if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 1)[0].FlagStatus) { //Configure sua loja
                const objOnboard = { ...onBoard };
                objOnboard.IdUltimoOnboardingStoreItem = 1;

                const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 1)[0];
                itemOnboard.FlagStatus = true;

                atualizarOnboardStore(objOnboard, itemOnboard);
                dispatch({
                    type: SET_DADOS_ON_BOARD,
                    data: objOnboard
                });

                navigate('/on-board');
            }
        }
        catch (err) {
            setLoadingSalvando(false);
            SalvarLogSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err?.message,
            });
        }
    };

    const handleCep = (e) => {
        if (e.target.value.length === 8) {
            cepInputRef.current.blur();
            setIsLoadingCep(true);
            axios
                .get(`https://viacep.com.br/ws/${e.target.value}/json/`)
                .then((res) => {
                    if (res.data.erro) {
                        setErrorCep(true);
                    } else {
                        formik.setFieldValue(`Logradouro`, res.data.logradouro);
                        formik.setFieldValue(`Complemento`, res.data.complemento);
                        formik.setFieldValue(`Numero`, res.data.siafi);
                        formik.setFieldValue(`Bairro`, res.data.bairro);
                        formik.setFieldValue(`Cidade`, res.data.localidade);
                        formik.setFieldValue(`Estado`, res.data.uf);

                        setErrorCep(false);
                    }

                    setIsLoadingCep(false);
                });
        }
    };

    const formik = useFormik({
        onSubmit,
        initialValues: usuarioEmpresa,
        validationSchema: validationSchema,
        validateOnChange: false,
        enableReinitialize: true,
    });

    const styleItemGrid = { width: '100%', marginTop: 25 };
    const marcouDiaSemana = formik.values?.FlgAbreSeg || formik.values?.FlgAbreTer || formik.values?.FlgAbreQua || formik.values?.FlgAbreQui || formik.values?.FlgAbreSex;

    return (
        <Container>
            <form onSubmit={formik.handleSubmit}>
                {
                    loading ? (
                        <Stack
                            sx={{ height: "150px", marginTop: '10%' }}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <CircularProgress />
                            <Typography sx={{ mt: 1 }}>Carregando dados da empresa</Typography>
                        </Stack>
                    ) : (
                        <>
                            <Box sx={{ width: "100%" }}>
                                <Box style={{ width: "100%" }}>
                                    <Typography style={{ fontWeight: 'bold' }}>Gestão Loja / Configura Loja</Typography>
                                </Box>
                                <Box style={{ width: "100%", cursor: 'pointer', marginTop: 10, display: 'flex', justifyContent: !onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 1)[0].FlagStatus ? 'flex-end' : 'space-between' }}>
                                    {
                                        onBoard?.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 1)[0].FlagStatus &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => navigate('/assinatura')}
                                        >
                                            Assinatura
                                        </Button>
                                    }
                                    <Button
                                        disabled={loadingSalvando}
                                        variant="contained"
                                        color="primary"
                                        onClick={formik.submitForm}
                                    >
                                        {loadingSalvando && <Loader />}
                                        Salvar
                                    </Button>
                                </Box>
                            </Box>
                            <Card style={{ marginTop: 10, paddingBottom: 50 }}>
                                <Grid container>
                                    <Grid item xs={12} md={3}>
                                        <Box>
                                            <FormControlLabel
                                                labelPlacement="top"
                                                control={<UploadImagem
                                                    isEditing={true}
                                                    imgData={logoLoja}
                                                    widthImg={180}
                                                    heightImg={150}
                                                    setImgData={(imagem) => setLogoLoja(imagem)}
                                                    style={{ width: '100%', maxWidth: 150, height: 200 }}
                                                />}
                                                label="Logo Loja"
                                            />
                                            <AlertError className="msg-error">{formik.errors.FotoProfile}</AlertError>
                                        </Box>
                                        <Box>
                                            <FormControlLabel
                                                labelPlacement="top"
                                                control={<UploadImagem
                                                    isEditing={true}
                                                    imgData={fotoCapaLoja}
                                                    widthImg={180}
                                                    heightImg={150}
                                                    setImgData={(imagem) => {
                                                        setFotoCapaLoja(imagem);
                                                    }}
                                                    style={{ width: '100%', maxWidth: 150, height: 200 }}
                                                />}
                                                label="Foto capa loja"
                                            />
                                            <AlertError className="msg-error">{formik.errors.FotoCapaShopLeap}</AlertError>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    label={'Razão Social da Loja'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.RazaoSocial}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`RazaoSocial`, e.target.value);
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.RazaoSocial}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    label={'Nome Fantasia da Loja'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.NomeFantasia}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`NomeFantasia`, e.target.value);
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.NomeFantasia}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <TextField
                                                    disabled
                                                    readOnly
                                                    label={'CPF ou CNPJ'}
                                                    style={{ ...styleItemGrid, width: '90%' }}
                                                    value={formik.values?.Cnpj}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Cnpj`, e.target.value);
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Cnpj}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={1} style={{ ...styleItemGrid, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
                                                <FormControlLabel labelPlacement="top" control={<Switch checked={formik.values?.FlgAtivo} onChange={() => formik.setFieldValue(`FlgAtivo`, !formik.values?.FlgAtivo)} />} label="Ativo" />
                                            </Grid>
                                        </Grid>

                                        <Divider style={{ width: '100%' }} />
                                        <Grid container spacing={2} style={{ paddingTop: 10, paddingRight: 20 }}>
                                            <Grid item xs={12} style={{ textAlign: 'left', marginBottom: 0 }}>
                                                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Configurações</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControlLabel labelPlacement="top" control={
                                                    <Switch
                                                        checked={formik.values?.FlgSMS}
                                                        onChange={() => {
                                                            formik.setFieldValue(`FlgSMS`, !formik.values?.FlgSMS);
                                                            if (formik.values?.FlgSMS)
                                                                formik.setFieldValue(`contatoSms`, '');
                                                        }} />}
                                                    label="Enviar SMS"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                    disabled={!formik.values?.FlgSMS}
                                                    readOnly={!formik.values?.FlgSMS}
                                                    label={'Contato SMS'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.contatoSms}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`contatoSms`, e.target.value.replace(/\D/g, ""));
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.contatoSms}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={
                                                        <Switch
                                                            checked={formik.values?.FlgWhatsApp}
                                                            onChange={() => {
                                                                formik.setFieldValue(`FlgWhatsApp`, !formik.values?.FlgWhatsApp);
                                                                if (formik.values?.FlgWhatsApp)
                                                                    formik.setFieldValue(`contatoWhataapp`, '');
                                                            }} />}
                                                    label="Enviar Whatsapp"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                    disabled={!formik.values?.FlgWhatsApp}
                                                    readOnly={!formik.values?.FlgWhatsApp}
                                                    label={'Contato Whatsapp'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.contatoWhataapp}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`contatoWhataapp`, e.target.value.replace(/\D/g, ""));
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.contatoWhataapp}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={
                                                        <Switch
                                                            checked={formik.values?.FlgParcelamento}
                                                            onChange={() => {
                                                                formik.setFieldValue(`FlgParcelamento`, !formik.values?.FlgParcelamento);
                                                                if (formik.values?.FlgParcelamento)
                                                                    formik.setFieldValue(`qtdParcelamento`, 0);
                                                            }}
                                                        />}
                                                    label="Parcelar vendas"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={
                                                        <Select
                                                            disabled={!formik.values?.FlgParcelamento}
                                                            readOnly={!formik.values?.FlgParcelamento}
                                                            labelId="tamanho-selector"
                                                            id="tamanho-selector-id"
                                                            value={!formik.values?.FlgParcelamento ? 0 : formik.values?.qtdParcelamento}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`qtdParcelamento`, Number(e.target.value))
                                                            }}
                                                            MenuProps={{
                                                                style: {
                                                                    maxHeight: 300,
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value={0}>Nenhum(a)</MenuItem>
                                                            <MenuItem value={1}>1x</MenuItem>
                                                            <MenuItem value={2}>2x</MenuItem>
                                                            <MenuItem value={3}>3x</MenuItem>
                                                            <MenuItem value={4}>4x</MenuItem>
                                                            <MenuItem value={5}>5x</MenuItem>
                                                            <MenuItem value={6}>6x</MenuItem>
                                                            <MenuItem value={7}>7x</MenuItem>
                                                            <MenuItem value={8}>8x</MenuItem>
                                                            <MenuItem value={9}>9x</MenuItem>
                                                            <MenuItem value={10}>10x</MenuItem>
                                                            <MenuItem value={11}>11x</MenuItem>
                                                            <MenuItem value={12}>12x</MenuItem>
                                                            <MenuItem value={13}>13x</MenuItem>
                                                            <MenuItem value={14}>14x</MenuItem>
                                                            <MenuItem value={15}>15x</MenuItem>
                                                            <MenuItem value={16}>16x</MenuItem>
                                                            <MenuItem value={17}>17x</MenuItem>
                                                            <MenuItem value={18}>18x</MenuItem>
                                                            <MenuItem value={19}>19x</MenuItem>
                                                            <MenuItem value={20}>20x</MenuItem>
                                                        </Select>
                                                    }
                                                    label="Parcelamentos"
                                                />
                                                <AlertError className="msg-error">{formik.errors.qtdParcelamento}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <NumericFormat
                                                    prefix={"R$ "}
                                                    defaultValue={0}
                                                    value={formik.values?.ValorMinimoCompra || 0}
                                                    label="Valor mín. compra"
                                                    variant="outlined"
                                                    customInput={TextField}
                                                    decimalSeparator=","
                                                    onBlur={(e) => {
                                                        const valor = e.target.value.replace(/\D[,]/g, "").replace('R$ ', '').trim();
                                                        formik.setFieldValue(`ValorMinimoCompra`, valor);
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.ValorMinimoCompra}</AlertError>
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={
                                                        <Switch
                                                            checked={usuario?.FlgBrecho || false}
                                                            onChange={() => setUsuario({ ...usuario, FlgBrecho: !usuario.FlgBrecho, FlgLoja: usuario.FlgBrecho })}
                                                        />}
                                                    label="Brecho?"
                                                />
                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    inputProps={{
                                                        maxLength: 11,
                                                    }}
                                                    label={'Número de celular'}
                                                    style={{ ...styleItemGrid, marginTop: 0 }}
                                                    value={formik.values?.Telefone}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Telefone`, e.target.value.replace(/\D/g, ""));
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Telefone}</AlertError>

                                            </Grid>

                                            <Grid item xs={12} md={2.5}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    style={{ marginTop: -20 }}
                                                    control={
                                                        <Select
                                                            labelId="TipoEmpresa"
                                                            id="tipo-empresa-id"
                                                            value={formik.values?.TipoEmpresa}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`TipoEmpresa`, e.target.value)
                                                            }}
                                                            MenuProps={{
                                                                style: {
                                                                    maxHeight: 300,
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value='MEI'>MEI</MenuItem>
                                                            <MenuItem value='LIMITED'>Limitado</MenuItem>
                                                            <MenuItem value='INDIVIDUAL'>Individual</MenuItem>
                                                            <MenuItem value='ASSOCIATION'>Associação</MenuItem>
                                                        </Select>
                                                    }
                                                    label="Tipo de empresa"
                                                />
                                                <AlertError className="msg-error">{formik.errors.TipoEmpresa}</AlertError>

                                            </Grid>

                                            <Grid item xs={12} md={2}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    style={{ marginTop: -20 }}
                                                    control={
                                                        <Select
                                                            labelId="TipoConta"
                                                            id="tamanho-selector-id"
                                                            value={formik.values?.TipoConta}
                                                            style={{ width: '100%' }}
                                                            onChange={(e) => {
                                                                formik.setFieldValue(`TipoConta`, e.target.value)
                                                            }}
                                                            MenuProps={{
                                                                style: {
                                                                    maxHeight: 300,
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value={1}>PJ</MenuItem>
                                                            <MenuItem value={2}>PF</MenuItem>
                                                        </Select>
                                                    }
                                                    label="Tipo Conta"
                                                />
                                                <AlertError className="msg-error">{formik.errors.TipoConta}</AlertError>
                                            </Grid>
                                            {
                                                formik.values?.TipoConta?.toString() === "2" &&
                                                <Grid item xs={12} md={3}>
                                                    <Typography>Data de nascimento</Typography>
                                                    <LocalizationProvider localeText={pt} dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            id={`DataNascimento`}
                                                            format="dd/MM/yyyy"
                                                            value={formik.values?.DataNascimento || ''}
                                                            onChange={(newValue) => {                  
                                                                formik.setFieldValue(`DataNascimento`, newValue);
                                                            }}
                                                        />
                                                    </LocalizationProvider>
                                                    <AlertError className="msg-error">{formik.errors.DataNascimento}</AlertError>
                                                </Grid>
                                            }
                                        </Grid>

                                        <Divider style={{ width: '100%' }} />
                                        <Grid container spacing={2} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 0 }}>
                                            <Grid item xs={12} style={{ textAlign: 'left', marginBottom: -20 }}>
                                                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Endereço</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    label={'CEP'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.CEP}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`CEP`, e.target.value.replace(/[^0-9]/g, "").trim());

                                                        if (Number(e.target.value) < 8) return;
                                                        handleCep(e);
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.CEP}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    label={'Logradouro'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.Logradouro || ''}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Logradouro`, e.target.value);
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Logradouro}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={1}>
                                                <TextField
                                                    type="number"
                                                    label={'Nº'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.Numero || ''}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Numero`, e.target.value);
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Numero}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    label={'Bairro'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.Bairro || ''}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Bairro`, e.target.value);
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Bairro}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    label={'Complemento'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.Complemento || ''}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Complemento`, e.target.value);
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Complemento}</AlertError>
                                            </Grid>
                                            <Grid item xs={12} md={2}>
                                                <TextField
                                                    label={'Cidade'}
                                                    style={styleItemGrid}
                                                    value={formik.values?.Cidade || ''}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(`Cidade`, e.target.value);
                                                    }}
                                                />
                                                <AlertError className="msg-error">{formik.errors.Cidade}</AlertError>
                                            </Grid>
                                        </Grid>

                                        <Divider style={{ width: '100%' }} />
                                        <Grid container spacing={2} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10, paddingBottom: 0 }}>
                                            <Grid item xs={12} style={{ textAlign: 'left', marginBottom: -20 }}>
                                                <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>Horários de atendimento</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 10 }}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={<ButtonGroup variant="outlined" aria-label="outlined button group" style={{ paddingTop: 10 }}>
                                                        <Button variant={formik.values?.FlgAbreSeg ? 'contained' : 'outlined'} onClick={() => formik.setFieldValue(`FlgAbreSeg`, !formik.values?.FlgAbreSeg)}>Seg</Button>
                                                        <Button variant={formik.values?.FlgAbreTer ? 'contained' : 'outlined'} onClick={() => formik.setFieldValue(`FlgAbreTer`, !formik.values?.FlgAbreTer)}>Ter</Button>
                                                        <Button variant={formik.values?.FlgAbreQua ? 'contained' : 'outlined'} onClick={() => formik.setFieldValue(`FlgAbreQua`, !formik.values?.FlgAbreQua)}>Qua</Button>
                                                        <Button variant={formik.values?.FlgAbreQui ? 'contained' : 'outlined'} onClick={() => formik.setFieldValue(`FlgAbreQui`, !formik.values?.FlgAbreQui)}>Qui</Button>
                                                        <Button variant={formik.values?.FlgAbreSex ? 'contained' : 'outlined'} onClick={() => formik.setFieldValue(`FlgAbreSex`, !formik.values?.FlgAbreSex)}>Sex</Button>
                                                        <Button variant={formik.values?.FlgAbreSab ? 'contained' : 'outlined'} onClick={() => formik.setFieldValue(`FlgAbreSab`, !formik.values?.FlgAbreSab)}>Sáb</Button>
                                                        <Button variant={formik.values?.FlgAbreDom ? 'contained' : 'outlined'} onClick={() => formik.setFieldValue(`FlgAbreDom`, !formik.values?.FlgAbreDom)}>Dom</Button>
                                                    </ButtonGroup>}
                                                    label="Dias da semana"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                        <TextField
                                                            disabled={!marcouDiaSemana}
                                                            inputRef={cepInputRef}
                                                            label={'Início'}
                                                            placeholder="08:00"
                                                            style={{ width: '50%' }}
                                                            value={formik.values?.HoraInicioSemana}
                                                            inputProps={{ maxLength: 5 }}
                                                            onChange={(e) => {
                                                                const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                                formik.setFieldValue(`HoraInicioSemana`, valor);
                                                            }}
                                                        />
                                                        <AlertError className="msg-error">{formik.errors.HoraInicioSemana}</AlertError>
                                                        <TextField
                                                            disabled={!marcouDiaSemana}
                                                            label={'Fim'}
                                                            placeholder="18:00"
                                                            value={formik.values?.HoraFimSemana}
                                                            style={{ width: '50%', marginLeft: 5 }}
                                                            inputProps={{ maxLength: 5 }}
                                                            onChange={(e) => {
                                                                const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                                formik.setFieldValue(`HoraFimSemana`, valor);
                                                            }}
                                                        />
                                                        <AlertError className="msg-error">{formik.errors.HoraFimSemana}</AlertError>
                                                    </Box>}
                                                    label="Horários semana"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                        <TextField
                                                            disabled={!formik.values?.FlgAbreSab}
                                                            label={'Início'}
                                                            placeholder="08:00"
                                                            style={{ width: '50%' }}
                                                            value={formik.values?.HoraInicioSabado}
                                                            inputProps={{ maxLength: 5 }}
                                                            onChange={(e) => {
                                                                const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                                formik.setFieldValue(`HoraInicioSabado`, valor);
                                                            }}
                                                        />
                                                        <AlertError className="msg-error">{formik.errors.HoraInicioSabado}</AlertError>
                                                        <TextField
                                                            disabled={!formik.values?.FlgAbreSab}
                                                            label={'Fim'}
                                                            placeholder="18:00"
                                                            value={formik.values?.HoraFimSabado}
                                                            style={{ width: '50%', marginLeft: 5 }}
                                                            inputProps={{ maxLength: 5 }}
                                                            onChange={(e) => {
                                                                const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                                formik.setFieldValue(`HoraFimSabado`, valor);
                                                            }}
                                                        />
                                                        <AlertError className="msg-error">{formik.errors.HoraFimSabado}</AlertError>
                                                    </Box>}
                                                    label="Horários sábado"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                        <TextField
                                                            disabled={!formik.values?.FlgAbreDom}
                                                            label={'Início'}
                                                            placeholder="08:00"
                                                            style={{ width: '50%' }}
                                                            value={formik.values?.HoraInicioDomingo}
                                                            inputProps={{ maxLength: 5 }}
                                                            onChange={(e) => {
                                                                const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                                formik.setFieldValue(`HoraInicioDomingo`, valor);
                                                            }}
                                                        />
                                                        <AlertError className="msg-error">{formik.errors.HoraInicioDomingo}</AlertError>
                                                        <TextField
                                                            disabled={!formik.values?.FlgAbreDom}
                                                            label={'Fim'}
                                                            placeholder="18:00"
                                                            value={formik.values?.HoraFimDomingo}
                                                            style={{ width: '50%', marginLeft: 5 }}
                                                            inputProps={{ maxLength: 5 }}
                                                            onChange={(e) => {
                                                                const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                                formik.setFieldValue(`HoraFimDomingo`, valor);
                                                            }}
                                                        />
                                                        <AlertError className="msg-error">{formik.errors.HoraFimDomingo}</AlertError>
                                                    </Box>}
                                                    label="Horários Domingo"
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <FormControlLabel
                                                    labelPlacement="top"
                                                    control={<Box style={{ display: 'flex', flexWrap: 'nowrap', paddingTop: 10 }}>
                                                        <TextField
                                                            label={'Início'}
                                                            placeholder="08:00"
                                                            style={{ width: '50%' }}
                                                            value={formik.values?.HoraInicioFeriado}
                                                            inputProps={{ maxLength: 5 }}
                                                            onChange={(e) => {
                                                                const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                                formik.setFieldValue(`HoraInicioFeriado`, valor);
                                                            }}
                                                        />
                                                        <AlertError className="msg-error">{formik.errors.HoraInicioFeriado}</AlertError>
                                                        <TextField
                                                            label={'Fim'}
                                                            placeholder="18:00"
                                                            value={formik.values?.HoraFimFeriado}
                                                            style={{ width: '50%', marginLeft: 5 }}
                                                            inputProps={{ maxLength: 5 }}
                                                            onChange={(e) => {
                                                                const valor = e.target.value.replace(/[^0-9:]/g, "").trim();
                                                                formik.setFieldValue(`HoraFimFeriado`, valor);
                                                            }}
                                                        />
                                                        <AlertError className="msg-error">{formik.errors.HoraFimFeriado}</AlertError>
                                                    </Box>}
                                                    label="Horários Feriado"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Card>
                        </>
                    )
                }
            </form>
        </Container>
    )
}

export default ConfigLoja;