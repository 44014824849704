import { Box, Card, FormControl, Grid, MenuItem, TextField, Typography, Select, InputLabel, FormControlLabel, Switch, Button, Modal, DialogTitle, FormLabel, RadioGroup, Radio, Divider } from "@mui/material";
import { Container } from "./styles";
import { useEffect, useState } from "react";
import { GET_DATA, GET_DATA_DESIGN, POST_AUTH, POST_DATA, POST_DATA_DESING, POST_DATA_FORM_DESING, PUT_DATA, SalvarLogSentry } from "../../../services/API";
import { useDispatch, useSelector } from "react-redux";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO, CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagemReducer/types";
import * as moment from "moment";
import { SET_DADOS_ON_BOARD } from "../../../store/OnBoard/types";
import { useNavigate } from "react-router";
import { atualizarOnboardStore } from "../../../services/utils/functions";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import imagemGerandoSite from '../../../assets/imagemGerandoSite.png';

const DominioLeap = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLogeedIn = useSelector((store) => store.usuarioLogado);
    const onBoard = useSelector((store) => store.onBoard);
    const [provedores, setProvedores] = useState([]);
    const [openModalLojaLiberacao, setOpenModalLojaLiberacao] = useState(false);
    const [dominio, setDominio] = useState({
        IdDominioLEAP: 0,
        IdUsuarioEmpresa: 0,
        IdProvedor: 0,
        IpServidor: '20.51.255.252',
        NomeSiteLeap: '',
        NomeSiteDominioGeral: '',
        DataCriacao: '',
        DataAtualizacao: null,
        FlagAtivo: true
    });
    const [tokenDesign, setTokenDesign] = useState(null);
    const [codigoLoja, setCodigoLoja] = useState(null);

    const [possuiDominioProprio, setPossuiDominioProprio] = useState("naoPossuiDominio");

    const container = {
        position: 'absolute',
        top: '0%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        if (userLogeedIn === null) return;

        (async () => {
            await getProvedores();
            await getDominios();

            const obj = {
                userName: process.env.REACT_APP_LOGIN_DESIGN,
                password: process.env.REACT_APP_SENHA_DESIGN
            };
            const login = await POST_AUTH(obj);

            setTokenDesign(login.token);

            const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${userLogeedIn.IdUsuario}`, login.token);

            if (construtorSite.data.urlSite)
                setCodigoLoja(construtorSite.data.urlSite.split('=')[1]);
        })();
    }, [userLogeedIn]);

    const getProvedores = async () => {
        const itens = await POST_DATA(`Provedor/GetAllProvedor?fVerTodos=true&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdProvedor&Log=`);
        setProvedores(itens);
    }

    const getDominios = async () => {
        const result = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${userLogeedIn.IdUsuarioEmpresa}&ColunaParaValorExato=IdUsuarioEmpresa&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP&Log=`);
        if (result !== null && result.length > 0) {
            result[0].IpServidor = '20.51.255.252';
            setDominio(result[0]);
        }
    }

    const handleSalvar = async () => {
        try {
            let todosCamposPreenchidos = true;

            if (dominio.IdDominioLEAP === 0 && possuiDominioProprio === 'naoPossuiDominio') {
                dominio.NomeSiteLeap = dominio.NomeSiteLeap.replaceAll('.leapstyle.me', '');
                dominio.NomeSiteLeap = `${dominio.NomeSiteLeap}.leapstyle.me`;
                dominio.IdProvedor = 16; //Sem provedor
            }

            if (dominio.IdProvedor === 16) { //Sem provedor
                dominio.NomeSiteLeap = dominio.NomeSiteLeap.replaceAll('.leapstyle.me', '');
                dominio.NomeSiteLeap = `${dominio.NomeSiteLeap}.leapstyle.me`;
            }

            dominio.NomeSiteDominioGeral = dominio.NomeSiteLeap;

            if ((possuiDominioProprio === 'possuiDominio' && dominio.IdProvedor === 0) || dominio.NomeSiteLeap.trim().length === 0 || dominio.NomeSiteDominioGeral.trim().length === 0)
                todosCamposPreenchidos = false;

            if (!todosCamposPreenchidos) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor preencher todos os campos',
                });
                return;
            }

            let urlDominiosJson = "https://leapstyle.blob.core.windows.net/cms/config/dominios.json";
            if (process.env.REACT_APP_AMBIENTE === 'DEV')
                urlDominiosJson = "https://leapstyle.blob.core.windows.net/cms/config/homolog/dominios.json";

            const resultDominios = await GET_DATA(`Base/DownloadFile?url=${urlDominiosJson}`);
            const objDominios = JSON.parse(resultDominios);

            let encontrou = false;
            objDominios.forEach((item) => {
                if (item.idUsuarioEmpresa == dominio.IdUsuarioEmpresa) {
                    item.cnpj = userLogeedIn.Cnpj.replaceAll(".", "").replace("/", "").replace("-", "");
                    item.dominio = dominio.NomeSiteLeap;

                    //item.dominioGeral = dominio.NomeSiteDominioGeral;
                    item.dominioGeral = dominio.NomeSiteLeap;

                    item.dataAlteracao = moment().format("YYY-MM-DD HH:mm:ss");
                    item.codigoLoja = codigoLoja;
                    encontrou = true;
                }
            });

            if (!encontrou) {
                objDominios.push({
                    "cnpj": userLogeedIn.Cnpj.replaceAll(".", "").replace("/", "").replace("-", ""),
                    "idUsuarioEmpresa": userLogeedIn.IdUsuarioEmpresa,
                    "dominio": dominio.NomeSiteLeap,

                    //"dominioGeral": dominio.NomeSiteDominioGeral,
                    "dominioGeral": dominio.NomeSiteLeap,

                    "codigoLoja": codigoLoja,
                    "dataAlteracao": moment().format("YYY-MM-DD HH:mm:ss")
                })
            }

            const formData = new FormData();
            formData.append("arquivoDominioStr", JSON.stringify(objDominios));
            await POST_DATA_FORM_DESING(`ConstrutorSite/SalvarArquivoDominio`, tokenDesign, formData);

            const dominios = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${dominio.NomeSiteLeap}&ColunaParaValorExato=NomeSiteLeap&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP`);
            if (dominios.filter(a => a.IdUsuarioEmpresa !== userLogeedIn.IdUsuarioEmpresa).length > 0) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Domínio já utilizado por outra loja',
                });
                return;
            }

            const objSave = { ...dominio };
            objSave.IdUsuarioEmpresa = userLogeedIn.IdUsuarioEmpresa;
            objSave.NomeSiteLeap = objSave.NomeSiteLeap.trim();
            objSave.NomeSiteDominioGeral = objSave.NomeSiteDominioGeral.trim();

            if (objSave.IdDominioLEAP === 0) {
                objSave.DataCriacao = moment().format("YYYY-MM-DD HH:mm:ss");
            }
            objSave.DataAtualizacao = moment().format("YYYY-MM-DD HH:mm:ss");

            await PUT_DATA(`DominioLEAP/CadastroDominioLEAP`, objSave);

            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Sucesso ao salvar o domínio',
            });

            setOpenModalLojaLiberacao(true);

            if (!onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 5)[0].FlagStatus) { //Liberar loja
                const objOnboard = { ...onBoard };
                objOnboard.IdUltimoOnboardingStoreItem = 5;

                const itemOnboard = objOnboard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 5)[0];
                itemOnboard.FlagStatus = true;

                atualizarOnboardStore(objOnboard, itemOnboard);

                dispatch({
                    type: SET_DADOS_ON_BOARD,
                    data: objOnboard
                });

                navigate('/on-board');
            }
        } catch (err) {
            SalvarLogSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: err?.message,
            });
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') return false;
        if (reason === 'escapeKeyDown') return false;

        setOpenModalLojaLiberacao(false);
    };

    const handleValidarDominioLeap = async () => {
        if (dominio.NomeSiteLeap.length < 5) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Favor informar um domínio válido',
            });
            return;
        }

        let dominioVerificacao = dominio.NomeSiteLeap;
        if (dominioVerificacao === 0 || dominioVerificacao === 16) { //Sem provedor
            dominioVerificacao = dominioVerificacao.replaceAll('.leapstyle.me', '');
            dominioVerificacao = `${dominioVerificacao}.leapstyle.me`;
        }        

        const dominios = await POST_DATA(`DominioLEAP/GetAllDominioLEAPByValorExato?strValorExato=${dominioVerificacao}&ColunaParaValorExato=NomeSiteLeap&fSomenteAtivos=true&join=true&maxInstances=0&order_by=IdDominioLEAP`);
        if (dominios.length > 0) {
            dispatch({
                type: CONTROLEMENSAGEM_AVISO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Domínio já está em uso por outra loja',
            });
        }
        else {
            dispatch({
                type: CONTROLEMENSAGEM_SUCESSO,
                tipoComponente: 'alert',
                titulo: 'Aviso',
                message: 'Domínio disponível para uso',
            });
        }
    }

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 15,
                            top: 35,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };

    return (
        <Container>
            {
                openModalLojaLiberacao &&
                <Modal
                    key="modal"
                    open={openModalLojaLiberacao}
                    style={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '0%'
                    }}
                    keepMounted
                    disableEscapeKeyDown
                    disableAutoFocus
                >
                    <Box
                        sx={[
                            container,
                            {
                                width: '650px',
                                height: 'auto',
                                borderRadius: 2,
                                backgroundColor: 'white',
                            },
                        ]}
                    >
                        <BootstrapDialogTitle id="componenteMensagem" onClose={handleClose}>
                            <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>Loja em processo de liberação</Typography>
                        </BootstrapDialogTitle>
                        <img src={imagemGerandoSite} style={{ width: 580 }} />
                    </Box>
                </Modal>
            }

            <Box sx={{ width: "100%" }}>
                <Typography style={{ fontWeight: 'bold' }}>Marketing / Domínios</Typography>
            </Box>

            <Card style={{ width: '100%', minHeight: 'auto', marginTop: 10, padding: 20, borderRadius: 20 }}>
                {
                    dominio.IdDominioLEAP === 0 &&
                    <Grid item xs={12} style={{ marginBottom: 20, marginTop: 10 }}>
                        <Typography><span style={{ fontWeight: 'bold' }}>Você possui domínio próprio?</span> Exemplo, seunome.com.br</Typography>

                        <FormControl>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue={possuiDominioProprio}
                                name="radio-buttons-group"
                                style={{ display: 'flex', flexDirection: 'row-reverse' }}
                                onChange={(e) => {
                                    setPossuiDominioProprio(e.target.value)
                                }}
                            >
                                <FormControlLabel value="possuiDominio" control={<Radio />} label="Possuí" />
                                <FormControlLabel value="naoPossuiDominio" control={<Radio />} label="Não possuí" />
                            </RadioGroup>
                        </FormControl>

                    </Grid>
                }
                <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item xs={5.5} style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            fullWidth={possuiDominioProprio !== "naoPossuiDominio" && dominio.IdProvedor !== 16}
                            type="text"
                            style={{ minWidth: 250 }}
                            InputLabelProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            label={dominio.IdDominioLEAP > 0 ? "Domínio" : (possuiDominioProprio === "possuiDominio" ? "Seu domínio" : "Domínio Leap")}
                            placeholder={dominio.IdDominioLEAP > 0 ? "Domínio" : (possuiDominioProprio === "possuiDominio" ? "Seu domínio" : "Domínio Leap")}
                            inputProps={{ maxLength: 255 }}
                            value={dominio.NomeSiteLeap}
                            onChange={(e) => {
                                setDominio({ ...dominio, NomeSiteLeap: e.target.value })
                            }}
                        />
                        {
                            (possuiDominioProprio === "naoPossuiDominio" || dominio.IdProvedor === 16) &&
                            <>
                                <span style={{ fontSize: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10, marginLeft: 10 }}>+</span>
                                <TextField
                                    fullWidth
                                    disabled
                                    type="text"
                                    InputLabelProps={{
                                        style: {
                                            color: 'black',
                                            fontSize: '14px'
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: 'black',
                                            fontSize: '14px'
                                        }
                                    }}
                                    value={'.leapstyle.me'}
                                />
                            </>
                        }

                    </Grid>
                    <Grid item xs={1.5}>
                        <Button variant="contained" onClick={() => handleValidarDominioLeap()}>Validar</Button>
                    </Grid>

                    <Grid item xs={1.5}>
                        <FormControlLabel labelPlacement="top" control={
                            <Switch
                                checked={dominio.FlagAtivo}
                                onChange={() => {
                                    setDominio({ ...dominio, FlagAtivo: !dominio.FlagAtivo });
                                }} />}
                            label="Ativo"
                        />
                    </Grid>

                    {
                        (possuiDominioProprio === "possuiDominio" || (dominio.IdProvedor > 0 && dominio.IdProvedor !== 16)) && //16 = Sem provedor
                        <>
                            <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10, marginLeft: 10 }} />
                            <Grid item xs={5.5}>
                                <FormControl fullWidth>
                                    <InputLabel id="provedor">Provedor</InputLabel>
                                    <Select
                                        fullWidth
                                        labelId="provedor"
                                        id="provedor"
                                        value={dominio.IdProvedor}
                                        label="Provedor"
                                        style={{ height: 50 }}
                                        onChange={(e) => setDominio({ ...dominio, IdProvedor: Number(e.target.value) })}
                                    >
                                        <MenuItem key={0} value={0}>Nenhum(a)</MenuItem>
                                        {
                                            provedores.map(provedor => {
                                                return <MenuItem key={provedor.IdProvedor} value={provedor.IdProvedor}>{provedor.Nome}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                                <TextField
                                    fullWidth
                                    disabled
                                    type="text"
                                    InputLabelProps={{
                                        style: {
                                            color: 'black',
                                            fontSize: '14px'
                                        }
                                    }}
                                    InputProps={{
                                        style: {
                                            color: 'black',
                                            fontSize: '14px'
                                        }
                                    }}
                                    label="ip do servidor LeapStyle"
                                    placeholder="ip do servidor LeapStyle"
                                    inputProps={{ maxLength: 15 }}
                                    value={dominio.IpServidor}
                                />
                            </Grid>
                        </>
                    }

                    {/* <Grid item xs={9}>
                        <TextField
                            fullWidth
                            type="text"
                            InputLabelProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            InputProps={{
                                style: {
                                    color: 'black',
                                    fontSize: '14px'
                                }
                            }}
                            label="Nome Site Domínio Geral"
                            placeholder="Nome Site Domínio Geral"
                            inputProps={{ maxLength: 255 }}
                            value={dominio.NomeSiteDominioGeral}
                            onChange={(e) => {
                                setDominio({ ...dominio, NomeSiteDominioGeral: e.target.value })
                            }}
                        />
                    </Grid> */}

                    <Grid item xs={1.5}>
                        <Button variant="contained" onClick={() => handleSalvar()}>Salvar</Button>
                    </Grid>
                </Grid>
            </Card>
        </Container >
    )
}

export default DominioLeap;