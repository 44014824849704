import { Box, styled } from "@mui/material";

export const Container = styled(Box)`
    height: 50%;
    background-color: red;
    width: 100%;
    padding-right: 30px;
    background: #f9f9f9;
`;

export const containerModal = {
    position: 'absolute',
    borderRadius: 2,
    boxShadow: 24,
    p: 4
};