import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingAllPage({ text = 'Gerando texto mágico...' }) {
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={true}
            >
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <CircularProgress size={70} color="primary" />
                    <span style={{ marginTop: 15, fontSize: 16 }}>{text}</span>
                </div>
            </Backdrop>
        </div>
    );
}