import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

// project imports
import { MENU_OPEN, SET_MENU } from '../../../../../store/Customization/types';

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const customization = useSelector((state) => state.customization);
  const superUser = useSelector((state) => state.superUser);
  const onBoard = useSelector((state) => state.onBoard);
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'));
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const [isHover, setIsHover] = useState(false);
  const ehLeapLovers = process.env.REACT_APP_LEAP_LOVERS === 'true';

  const Icon = item.icon;
  const itemIcon = item?.icon ? (
    <Icon style={{ color: item.id === 'pendencias' && !onBoard?.FlagCompletado && !isHover ? 'red' : 'white' }} stroke={1.5} size="1.3rem" />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
        height: customization.isOpen.findIndex((id) => id === item?.id) > -1 ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  );

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={`${item.url}`} target={itemTarget} />
    )),
  };

  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    dispatch({ type: MENU_OPEN, id });
    if (matchesSM) dispatch({ type: SET_MENU, opened: false });
  };

  // active menu item on page load
  useEffect(() => {
    const currentIndex = document.location.pathname
      .toString()
      .split('/')
      .findIndex((id) => id === item.id);
    if (currentIndex > -1) {
      dispatch({ type: MENU_OPEN, id: item.id });
    }

    // eslint-disable-next-line
  }, []);

  const naoMostrarPendentes = item.id === 'pendencias' && onBoard?.FlagCompletado;
  const naoMostrarXGH = item.id === 'xgh' && superUser === null;
  const naoMostrarLayout2 = item.id === 'configurar-ecommerce-onboard' && superUser === null;

  if(onBoard && !onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 3)[0].FlagStatus && item.id === 'configurar-ecommerce'){
    item.url = '/configurar-ecommerce-onboard';
  }

  return naoMostrarPendentes ? null : naoMostrarXGH ? null : naoMostrarLayout2 ? null :
    (
      <ListItemButton
        {...listItemProps}
        disabled={item.disabled}
        sx={{
          borderRadius: `${customization.borderRadius}px`,
          mb: 0.5,
          alignItems: 'flex-start',
          backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
          py: level > 1 ? 1 : 1.25,
          pl: `${level * 24}px`,
          color: 'white'
        }}
        style={{ background: ehLeapLovers && customization.isOpen.findIndex((id) => id === item.id) > -1 ? '#f7931e' : null }}
        selected={item.id !== 'pendencias' && customization.isOpen.findIndex((id) => id === item.id) > -1}
        onClick={() => {
          itemHandler(item.id);
        }}
      >
        <ListItemIcon sx={{ my: 'auto', color: 'white', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
        {leftDrawerOpened && <>
          <ListItemText
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            primary={
              <Typography
                color="inherit"
                style={{ fontSize: 14, color: item.id === 'pendencias' && !onBoard?.FlagCompletado && !isHover ? 'red' : 'white', fontWeight: (customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'bold' : null) }}
              >
                {item.title}
              </Typography>
            }
            secondary={
              item.caption && (
                <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                  {item.caption}
                </Typography>
              )
            }
          />
          {item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </>}
      </ListItemButton>
    );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
};

export default NavItem;
