import { useSelector } from 'react-redux';
import { FooterContainer } from './styles';
import { Box, Stack, Typography } from '@mui/material';
import packageJson from '../../../package.json';
import smallLogoLeap from '../../assets/Badge.svg'
import { useLocation } from 'react-router';

const Footer = () => {
    const location = useLocation();
    const usuarioLogado = useSelector((store) => store.usuarioLogado);
    const customization = useSelector((store) => store.customization);
    const onBoard = useSelector((store) => store.onBoard);
    const year = new Date().getFullYear();

    const lojaConfigurada = !location.pathname.includes('lojas') && !(location.pathname.replace('/', '') === 'onboard-lojista') && !(location.pathname.replace('/', '') === 'on-board-design-site') && onBoard && onBoard?.ListOnboardingStorexOnboardingStoreItem && onBoard.ListOnboardingStorexOnboardingStoreItem.filter(a => a.IdOnboardingStoreItem === 1)[0].FlagStatus;

    return (
        <FooterContainer opened={customization.opened} lojaConfigurada={lojaConfigurada}>
            {usuarioLogado && 
                <Typography
                    variant="caption"
                    sx={{
                        color: "gray",
                        transition: "0.5s",
                    }}
                >
                    CNPJ: {usuarioLogado.Cnpj}
                </Typography>
            }
            <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Stack direction="row" alignItems="center" gap={2}></Stack>
                <Typography variant="caption" sx={{ color: "gray" }}>
                    LeapStyle @ {year} - CRM - Versão: {packageJson.version}
                </Typography>
                <img
                    alt="Logo Leap"
                    src={smallLogoLeap}
                    style={{ width: 25, height: 25, margin: "0 0.5rem" }}
                />
            </Box>
        </FooterContainer>
    );
}

export default Footer;
