import Cookie from 'js-cookie';

export const setStorage = (itemName, value) => {
    localStorage.setItem(itemName, value);
    /*Cookie.set(itemName, value, {
        expires: 1,
        secure: true,
        sameSite: 'strict',
        path: '/'
    });*/
}

export const getStorage = (itemName) => {
    return localStorage.getItem(itemName);
    //return Cookie.get(itemName);
}

export const removeStorage = (itemName) => {
    localStorage.removeItem(itemName);
    //Cookie.remove(itemName);
}
