import { useDispatch, useSelector } from 'react-redux';
import NavigationScroll from './layout/NavigationScroll';
import Routes from './routes';
import { useEffect } from 'react';
import { USUARIO_LOGADO_SET_DADOS } from './store/UsuarioLogadoReducer/types';
import ScrollToTop from './components/ScrollToTop';
import { getStorage } from './hooks/useStorage';
import ControleMensagem from './components/ControleMensagem';
import { Theme } from './themes';
import { SET_DADOS_ON_BOARD } from './store/OnBoard/types';
import { isObject } from 'formik';
import { SUPER_USER_SET_DADOS } from './store/SuperUser/types';
import { LEAPLOVERS_SET_AFILIADO } from './store/LeapLovers/types';
import { Navigate } from 'react-router';
import { GET_DATA_DESIGN, POST_AUTH, POST_DATA } from './services/API';
import { TEMA_LOJA_SET } from './store/TemaLojaReducer/types';

const App = () => {
    const customization = useSelector((state) => state.customization);
    const onboard = useSelector((state) => state.onBoard);
    const dispatch = useDispatch();

    useEffect(() => {
        let usuario = getStorage('usrin');
        let afiliado = getStorage('usrAfiliado');
        let superUsrin = getStorage('superUsrin');
        if (usuario === undefined) return;

        let usuarioLogado = JSON.parse(usuario);

        if (usuarioLogado !== null) {
            dispatch({
                type: USUARIO_LOGADO_SET_DADOS,
                data: usuarioLogado
            });
        }

        var usrAfiliado = JSON.parse(afiliado);
        if (usrAfiliado !== null) {
            dispatch({
                type: LEAPLOVERS_SET_AFILIADO,
                data: usrAfiliado
            });
        }

        if (superUsrin !== null) {
            dispatch({
                type: SUPER_USER_SET_DADOS,
                data: JSON.parse(superUsrin)
            });
        }

        const storeOnBoard = getStorage('onBoard');
        if (onboard === null && storeOnBoard !== null && storeOnBoard !== undefined && storeOnBoard !== '' && !isObject(storeOnBoard)) {
            const obj = JSON.parse(storeOnBoard);
            dispatch({
                type: SET_DADOS_ON_BOARD,
                data: obj
            });
        }

        document.title = process.env.REACT_APP_LEAP_LOVERS === 'true' ? 'Leap Lovers' : 'CRM CMS';

        (async () => {
            if (usuarioLogado === null) return;

            const obj = {
                userName: process.env.REACT_APP_LOGIN_DESIGN,
                password: process.env.REACT_APP_SENHA_DESIGN
            };

            const login = await POST_AUTH(obj);
            const construtorSite = await GET_DATA_DESIGN(`ConstrutorSite?clienteId=${usuarioLogado.IdUsuario}`, login.token);

            if (construtorSite?.data?.id > 0) {
                dispatch({
                    type: TEMA_LOJA_SET,
                    data: construtorSite.data.arquivo
                });
            }

            const onboardLojistaResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=1&idUsuario=${usuarioLogado.IdUsuario}`);
            const onboardDesignSiteResult = await POST_DATA(`Questionario/GetPerguntaRespostaQuestionarioId?idQuestionario=2&idUsuario=${usuarioLogado.IdUsuario}`);

            if (!window.location.pathname.includes('/onboard-lojista') && !window.location.pathname.includes('/on-board-design-site')) {
                if (onboardLojistaResult.totalPerguntaRespondida < onboardLojistaResult.totalPergunta) {
                    window.location.href = '/onboard-lojista';
                    return;
                }
                else if (onboardDesignSiteResult.totalPerguntaRespondida < onboardDesignSiteResult.totalPergunta) {
                    window.location.href = '/on-board-design-site';
                    return;
                }

                if (construtorSite.data.id === 0) {
                    window.location.href = '/on-board-design-site';
                    return;
                }
            }
        })();

//         if (process.env.REACT_APP_START_LOGIN_OPCAO == 2) {
//             var head = document.createElement('script');
//             head.setAttribute('content', `<!-- Google Tag Manager -->
//                 <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//                 new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//                 j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//                 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//                 })(window,document,'script','dataLayer','GTM-KHWW45LZ');</script>
//                 <!-- End Google Tag Manager -->`);

//             document.getElementsByTagName('head')[0].appendChild(head);

//             var script = document.createElement('noscript');
//             script.setAttribute('content', `<!-- Google Tag Manager (noscript) -->
// <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KHWW45LZ"
// height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
// <!-- End Google Tag Manager (noscript) -->`);
//             document.getElementsByTagName('body')[0].appendChild(script);
//         }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Theme customization={customization}>
            <NavigationScroll>
                <ScrollToTop />
                <Routes />
                <ControleMensagem />
            </NavigationScroll>
        </Theme>
    );
};

export default App;
