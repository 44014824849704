import { Avatar, Box, Button, TextField, Typography } from "@mui/material";
import { Container } from "./styles";

import LogoLeap from '../../../assets/Badge.svg';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONTROLEMENSAGEM_AVISO, CONTROLEMENSAGEM_ERRO } from "../../../store/ControleMensagemReducer/types";
import { POST_DATA, SalvarLogEventoSentry } from "../../../services/API";
import * as moment from "moment";
import { CONTROLEMENSAGEM_SUCESSO } from "../../../store/ControleMensagem/types";

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { USUARIO_LOGADO_SET_DADOS } from "../../../store/UsuarioLogadoReducer/types";
import { removeStorage, setStorage } from "../../../hooks/useStorage";
import { useNavigate } from "react-router";
import { isMobile } from "../../../services/DeviceServices";

const ValidarCodigoAtivacao = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userLoggedIn = useSelector((state) => state.usuarioLogado);
    const [usuario, setUsuario] = useState(null);

    const [mostrarComponenteValidacao, setMostrarComponenteValidacao] = useState(true);

    const [codigo, setCodigo] = useState({
        codigo1: '',
        codigo2: '',
        codigo3: '',
        codigo4: '',
        codigo5: '',
        codigo6: '',
    });

    useEffect(() => {
        (async () => {
            if (userLoggedIn === null) return;

            const user = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${userLoggedIn.IdUsuario}&join=true&Log=`);
            setUsuario(user);
        })();

    }, [userLoggedIn])

    const handleReenviarCodigo = async () => {
        try {
            const result = await POST_DATA(`/Usuario/GetGerarCodigoValidaAcesso?idUsuario=${userLoggedIn.IdUsuario}`, null);

            if (result?.obj) {
                const user = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${userLoggedIn.IdUsuario}&join=true&Log=`);
                setUsuario(user);

                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Um novo código foi enviado para o seu e-mail',
                });
            }
            else {
                dispatch({
                    type: CONTROLEMENSAGEM_ERRO,
                    tipoComponente: 'alert',
                    titulo: 'Erro',
                    message: result.mensagem,
                });
            }
        }
        catch (err) {
            SalvarLogEventoSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: `Ocorreu um erro: ${err.message}`,
            });
        }
    }

    const handleValidarCodigo = async () => {
        try {
            if (codigo.codigo1 === '' || codigo.codigo2 === '' || codigo.codigo3 === '' || codigo.codigo4 === '' || codigo.codigo5 === '' || codigo.codigo6 === '') {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Favor informar todos os 6 dígitos',
                });
                return;
            }

            const codigoFinal = `${codigo.codigo1}${codigo.codigo2}${codigo.codigo3}${codigo.codigo4}${codigo.codigo5}${codigo.codigo6}`;

            if (usuario.CodigoAcesso !== codigoFinal) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Código informado incorreto',
                });
                return;
            }

            if (moment().format('YYYY-MM-DD HH:mm:ss') > moment(usuario.DataCodigoAcesso).add(1, 'hours').format('YYYY-MM-DD HH:mm:ss')) {
                dispatch({
                    type: CONTROLEMENSAGEM_AVISO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Código expirado',
                });
                return;
            }

            const result = await POST_DATA(`/Usuario/updateCodigoAcesso?idUsuario=${userLoggedIn?.IdUsuario}`, null);
            if (result) {
                dispatch({
                    type: CONTROLEMENSAGEM_SUCESSO,
                    tipoComponente: 'alert',
                    titulo: 'Aviso',
                    message: 'Sucesso ao validar o código',
                });
                setMostrarComponenteValidacao(false);
            }
        }
        catch (err) {
            SalvarLogEventoSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: `Ocorreu um erro: ${err.message}`,
            });
        }
    }

    const handleVoltarAoSistema = async () => {
        try {
            const user = await POST_DATA(`Usuario/GetUsuarioById?IdUsuario=${userLoggedIn.IdUsuario}&join=true&Log=`);
            setUsuario(user);

            const login = { ...userLoggedIn, FlgCodigoAcesso: true };
            setStorage('usrin', JSON.stringify(login));

            dispatch({
                type: USUARIO_LOGADO_SET_DADOS,
                data: login
            });
        }
        catch(err){
            SalvarLogEventoSentry(err);

            dispatch({
                type: CONTROLEMENSAGEM_ERRO,
                tipoComponente: 'alert',
                titulo: 'Erro',
                message: `Ocorreu um erro: ${err.message}`,
            });
        }
    }

    const handleLogout = () => {
        removeStorage('usrin');
        removeStorage('superUsrin');
        navigate('/login');
    }

    const handleSetCodigo = (event, inputId) => {
        const code = event.which || event.keyCode;
        let charCode = String.fromCharCode(code).toLowerCase();

        if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
            navigator.clipboard.readText()
                .then(text => {
                    if (text.length === 6) {
                        setCodigo({
                            ...codigo,
                            codigo1: text.substring(0, 1).trim(),
                            codigo2: text.substring(1, 2).trim(),
                            codigo3: text.substring(2, 3).trim(),
                            codigo4: text.substring(3, 4).trim(),
                            codigo5: text.substring(4, 5).trim(),
                            codigo6: text.substring(5, 6).trim()
                        });
                    }
                })
                .catch(err => {
                    console.error('Failed to read clipboard contents: ', err);
                });
        }
        else if (event.keyCode >= 48 && event.keyCode <= 57) {
            setTimeout(() => {
                document.getElementById(inputId).focus();
            }, 400);
        }
    }

    return mostrarComponenteValidacao ? (
        <Container>
            <Box style={{ width: 450, height: 250, display: 'flex', justityContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <Avatar style={{ background: 'white' }} src={LogoLeap} sx={{ width: 70, height: 70 }} />
                <Typography style={{ marginTop: 30, fontSize: 18, fontWeight: 'bold' }}>Sua conta está pronta</Typography>
                <Typography style={{ marginTop: 10, fontSize: 14, color: 'gray', textAlign: 'center' }}>Para sua segurança e de todos cliente da Leapstyle, enviamos um código de verificação com 6 dígitos para o email <span style={{ color: '#f1416d' }}>{userLoggedIn?.Email}</span>. Tente localiza-lo na sua caixa de entrada ou na caixa de SPAM.</Typography>

                <Button variant="text" style={{ fontWeight: 'bold', marginTop: 15 }} onClick={() => handleReenviarCodigo()}>Reenviar código</Button>

                <Typography style={{ marginTop: 10, fontSize: 14, color: 'gray' }}>Digite o código recebido no espaço abaixo</Typography>
                <Box style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 30 }}>
                    <TextField
                        type="text"
                        id='codigo1'
                        onKeyDown={(event) => {
                            handleSetCodigo(event, 'codigo2');
                        }}
                        style={{ width: 50 }}
                        InputLabelProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px',
                                paddingLeft: 5
                            }
                        }}
                        inputProps={{ maxLength: 1 }}
                        value={codigo.codigo1}
                        onChange={(event) => {
                            setCodigo({ ...codigo, codigo1: event.target.value.replace(/[^0-9:]/g, "").trim() })
                        }}
                    />
                    <TextField
                        type="text"
                        id='codigo2'
                        onKeyDown={(event) => {
                            handleSetCodigo(event, 'codigo3');
                        }}
                        style={{ width: 50, marginLeft: 5 }}
                        InputLabelProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px',
                                paddingLeft: 5
                            }
                        }}
                        inputProps={{ maxLength: 1 }}
                        value={codigo.codigo2}
                        onChange={(e) => {
                            setCodigo({ ...codigo, codigo2: e.target.value.replace(/[^0-9:]/g, "").trim() })
                        }}
                    />
                    <TextField
                        type="text"
                        id='codigo3'
                        onKeyDown={(event) => {
                            handleSetCodigo(event, 'codigo4');
                        }}
                        style={{ width: 50, marginLeft: 5 }}
                        InputLabelProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px',
                                paddingLeft: 5
                            }
                        }}
                        inputProps={{ maxLength: 1 }}
                        value={codigo.codigo3}
                        onChange={(e) => {
                            setCodigo({ ...codigo, codigo3: e.target.value.replace(/[^0-9:]/g, "").trim() })
                        }}
                    />
                    <TextField
                        type="text"
                        id='codigo4'
                        style={{ width: 50, marginLeft: 5 }}
                        onKeyDown={(event) => {
                            handleSetCodigo(event, 'codigo5');
                        }}
                        InputLabelProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px',
                                paddingLeft: 5
                            }
                        }}
                        inputProps={{ maxLength: 1 }}
                        value={codigo.codigo4}
                        onChange={(e) => {
                            setCodigo({ ...codigo, codigo4: e.target.value.replace(/[^0-9:]/g, "").trim() })
                        }}
                    />
                    <TextField
                        type="text"
                        id='codigo5'
                        style={{ width: 50, marginLeft: 5 }}
                        onKeyDown={(event) => {
                            handleSetCodigo(event, 'codigo6');
                        }}
                        InputLabelProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px',
                                paddingLeft: 5
                            }
                        }}
                        inputProps={{ maxLength: 1 }}
                        value={codigo.codigo5}
                        onChange={(e) => {
                            setCodigo({ ...codigo, codigo5: e.target.value.replace(/[^0-9:]/g, "").trim() })
                        }}
                    />
                    <TextField
                        type="text"
                        id='codigo6'
                        style={{ width: 50, marginLeft: 5 }}
                        onKeyDown={(event) => {
                            handleSetCodigo(event, 'botaoValidar');
                        }}
                        InputLabelProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px'
                            }
                        }}
                        InputProps={{
                            style: {
                                color: 'black',
                                fontSize: '14px',
                                paddingLeft: 5
                            }
                        }}
                        inputProps={{ maxLength: 1 }}
                        value={codigo.codigo6}
                        onChange={(e) => {
                            setCodigo({ ...codigo, codigo6: e.target.value.replace(/[^0-9:]/g, "").trim() })
                        }}
                    />
                </Box>

                <Button variant="contained" id='botaoValidar' onClick={() => handleValidarCodigo()}>Validar</Button>
                <Button variant="text" style={{ marginTop: 10 }} onClick={() => handleLogout()}>Sair</Button>
            </Box>
        </Container>
    ) : (
        <Container>
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                
                {/* {isMobile() && <Typography style={{ fontSize: 17, width: 290, fontWeight: 'bold', textAlign: 'center', marginBottom: 40, padding: 10, border: '1px solid #c0c0c0', borderRadius: 10 }}>Para uma experiência otimizada, recomendamos utilizar um computador ou tablet.</Typography>} */}

                <CheckCircleRoundedIcon style={{ color: '#f1416d', width: 250, height: 250 }} />
                <Typography style={{ fontSize: 20, fontWeight: 'bold', marginTop: 50, marginBottom: 10 }}>Obrigado por confirmar seu e-mail</Typography>
                <Button onClick={() => handleVoltarAoSistema()} variant="contained">Acessar o sistema</Button>
            </Box>
        </Container>
    )
}

export default ValidarCodigoAtivacao;