import React from "react"

import './styles.css'

function Video() {
    return (
        <div>
            <div className='aula-header-video'>
                <text>Titulo do video</text>
                <iframe width="100%" height="440" src="https://www.youtube.com/embed/wL6E8YfoKz0?si=3QzTHsVGlSX67lQ7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>

            <div className='aula-description-container'>
                <text>Descrição:</text>
                <text>Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.</text>
                <text>Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.</text>
                <text>Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.Labore labore tempor labore adipisicing.</text>
            </div>

        </div>
    )
}

export default Video