import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './mainRouter';
import AuthenticationRoutes from './authenticationRoutes';
import ValidationCodeRoute from './validationCodeRoute';
import LeapLoversRoute from './leapLoversRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  let rotas = [MainRoutes, AuthenticationRoutes, ValidationCodeRoute, LeapLoversRoute];
  return useRoutes(rotas);
}
