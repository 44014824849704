import { Avatar, Box, Button, Divider, Grid, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { ContainerDetalheProduto } from "./styles";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";


const DetalhesProduto = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [produto, setProduto] = useState(null);
    const [imagemSelecionada, setImagemSelecionada] = useState('');
    const [tabSelecionada, setTabSelecionada] = useState(0);

    const [idCorSelecionada, setIdCorSelecionada] = useState(0);
    const [idTamanhoSelecionadao, setIdTamamhoSelecionado] = useState(0);

    const [cep, setCep] = useState('');

    useEffect(() => {
        setProduto(location.state?.produto);
        setImagemSelecionada(location.state?.produto.fotoProduto);

        const stock = location.state?.produto?.estoque[0];
        if (stock) {
            setIdCorSelecionada(stock.color_id);
            setIdTamamhoSelecionado(stock.size_id);
        }

    }, []);

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function CustomTabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
                style={{ paddingTop: 12 }}
            >
                {value === index && children}
            </div>
        );
    }

    const handleChange = (event, newValue) => {
        setTabSelecionada(newValue);
    };

    return produto && <ContainerDetalheProduto>
        <Grid container spacing={2} style={{ paddingRight: 10 }}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="text" onClick={() => navigate(-1)}>
                    Voltar
                </Button>
                {
                    produto.flgCuradoriaVesti &&
                    <img src='https://hv3.meuvesti.com/images/logo/logo_vesti_padrao.png' style={{ width: 75 }} />
                }
            </Grid>
            <Grid item xs={12} md={3} style={{ width: '100%', maxWidth: 175, paddingLeft: 10, maxHeight: 450, overflowY: 'auto' }}>
                <img
                    alt="Produto"
                    src={produto.fotoProduto}
                    style={{ height: '200px', cursor: 'pointer', border: produto.fotoProduto === imagemSelecionada ? '2.5px solid rgb(254, 158, 64)' : 'none' }}
                    onClick={() => setImagemSelecionada(produto.fotoProduto)}
                />
                {
                    produto.imagensCompolementares.map((img, index) => {
                        return <img
                            key={index}
                            alt="Produto"
                            src={img.fotoProduto}
                            style={{ height: '200px', marginTop: 10, cursor: 'pointer', border: img.fotoProduto === imagemSelecionada ? '2.5px solid rgb(254, 158, 64)' : 'none' }}
                            onClick={() => setImagemSelecionada(img.fotoProduto)}
                        />
                    })
                }
            </Grid>
            <Grid item xs={12} md={3} style={{ display: 'flex', justifyContent: 'flex-start', minWidth: 400, maxWidth: 400 }}>
                <img alt="Produto" src={imagemSelecionada} style={{ width: '100%', maxWidth: '100%', height: 'auto' }} />
            </Grid>

            <Grid item xs={12} md={4} style={{ paddingRight: 15, paddingBottom: 15, marginTop: -10 }}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: "100%", display: 'flex', flexWrap: 'wrap' }}
                >
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'start' }}>
                        <Typography style={{ width: '100%', fontWeight: 700, textTransform: 'uppercase', fontSize: 20, textAlign: 'start' }}>
                            {produto.nome}
                        </Typography>
                    </Box>

                    <Box style={{ width: '100%' }}>
                        <Typography sx={{ fontSize: 16, marginTop: 0.8 }}>
                            <span style={{ fontWeight: 'bold' }}>Marca: </span>{produto.marca.name}
                        </Typography>
                    </Box>
                    <Box style={{ width: '100%', marginTop: 10, marginBottom: 2 }}>
                        <Typography sx={{ textAlign: 'start', marginBottom: 2, fontSize: 12 }}>
                            Aproveite, este produto só tem <span style={{ fontWeight: 'bold' }}>{produto.estoque?.filter(a => a.color_id === idCorSelecionada && a.size_id === idTamanhoSelecionadao)[0]?.quantity}  unidade(s) disponível(eis)</span>
                        </Typography>
                    </Box>

                    {
                        produto.valorPromocional > 0 &&
                        <>
                            <Box style={{ width: '100%', marginLeft: 10 }}>
                                <Typography sx={{ textDecoration: 'line-through', fontWeight: 'bold', color: 'gray', fontSize: 16 }}>
                                    {
                                        produto.valor?.toLocaleString("pt-BR", {
                                            style: "currency",
                                            currency: "BRL",
                                        })
                                    }
                                </Typography>
                            </Box>
                            <Box style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: produto.valorPromocional > 0 ? 10 : 0 }}>
                                <Typography sx={{ fontWeight: '500', fontSize: 24, color: 'rgb(64, 64, 64)' }}>
                                    {produto.valorPromocional?.toLocaleString("pt-BR", {
                                        style: "currency",
                                        currency: "BRL",
                                    })}
                                </Typography>
                            </Box>
                        </>
                    }
                    {
                        produto.valorPromocional == 0 &&
                        <Box style={{ width: '100%', display: 'flex', alignItems: 'center', marginLeft: produto.valorPromocional > 0 ? 10 : 0 }}>
                            <Typography sx={{ fontWeight: '500', fontSize: 24, color: 'rgb(64, 64, 64)' }}>
                                {produto.valor?.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                })}
                            </Typography>
                        </Box>
                    }

                    <Box style={{ width: '100%' }}>
                        <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Cores</Typography>
                        <Box style={{ width: '100%', display: 'flex', marginTop: 5, marginBottom: 10 }}>
                            {
                                produto.colors && [...new Map(produto.colors.map(item => [item['id'], item])).values()].map(cor => {
                                    return <div key={cor.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography style={{ color: 'gray', fontSize: 11, marginLeft: 10 }}><span style={{ fontWeight: 'bold' }}>{cor.name}</span></Typography>
                                        <span
                                            onClick={() => setIdCorSelecionada(cor.id)}
                                            style={{
                                                marginLeft: 10,
                                                padding: 10,
                                                border: idCorSelecionada === cor.id ? '3px solid #929292' : '1px solid white',
                                                borderRadius: 20,
                                                background: cor.code,
                                                fontWeight: 'bold',
                                                cursor: 'pointer',
                                                width: 30,
                                                height: 30
                                            }}
                                        >
                                        </span>
                                    </div>
                                })
                            }
                        </Box>
                    </Box>

                    <Box style={{ width: '100%' }}>
                        <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Tamanhos</Typography>
                        <Box style={{ width: '100%', display: 'flex', marginTop: 5, marginBottom: 10 }}>
                            {
                                produto.tamanhos && [...new Map(produto.tamanhos.map(item => [item['id'], item])).values()].map(tamanho => {
                                    return <div key={tamanho.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <span
                                            onClick={() => setIdTamamhoSelecionado(tamanho.id)}
                                            style={{
                                                marginLeft: 10,
                                                padding: 10,
                                                paddingTop: 9,
                                                paddingLeft: idTamanhoSelecionadao === tamanho.id ? 8 : 10,
                                                fontSize: 14,
                                                border: idTamanhoSelecionadao === tamanho.id ? '3px solid #929292' : '1px solid white',
                                                borderRadius: 20,
                                                background: '#cfcfcf',
                                                color: 'black',
                                                fontWeight: 'bold',
                                                cursor: 'pointer',
                                                width: 40,
                                                height: 40
                                            }}
                                        >
                                            {tamanho.name}
                                        </span>
                                    </div>
                                })
                            }
                        </Box>
                    </Box>

                    <Divider style={{ marginTop: 10, marginBottom: 10 }} />
                    <Box style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            sx={{ marginTop: 1, width: '88%', maxWidth: 380, background: 'black', color: 'white', fontWeight: 'bold', fontSize: 16, borderRadius: 0, height: 45, marginRight: 1 }}
                        >
                            Avisar-me
                        </Button>
                    </Box>

                    <Typography style={{ marginTop: 30, fontSize: 16 }}>Vendido e entregue por <span style={{ fontWeight: 'bold' }}>{produto.loja.nome}</span></Typography>

                    {/* <Box style={{ width: '100%', marginTop: 10, display: 'flex', justifyContent: 'start', alignItems: 'flex-end' }}>
                        <Box>
                            <Typography style={{ color: 'gray', fontSize: 13 }}>Digite seu CEP*</Typography>
                            <TextField
                                sx={{ marginLeft: 0, marginTop: 2 }}
                                InputProps={{
                                    style: { width: '180px', fontSize: 16, height: 40 }
                                }}
                                inputProps={{ maxLength: 8 }}
                                id="cep"
                                placeholder='CEP'
                                variant="outlined"
                                type='text'
                                value={cep}
                                onChange={(e) => {
                                    if (e.target.value === null || e.target.value === undefined || e.target.value === '') return;
                                    setCep(e.target.value.replace('-', '').replace(/[^0-9]/g, ''));
                                }}
                                InputLabelProps={{
                                    style: { fontSize: 16 }
                                }}
                            />
                        </Box>
                        <Button
                            sx={{ marginTop: 1.5, marginLeft: 2, height: 40, minWidth: '170px', maxWidth: 200, backgroundColor: 'black', color: 'white' }}
                            variant='contained'
                        >Pesquisar</Button>
                    </Box> */}
                </Stack>
            </Grid>

            <Box style={{ width: '100%', padding: 30 }}>
                <Tabs value={tabSelecionada} onChange={handleChange} aria-label="basic tabs example">
                    <Tab style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }} label="DETALHES DO PRODUTO" {...a11yProps(0)} />
                    {/* <Tab style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }} label="INFORMAÇÕES" {...a11yProps(1)} /> */}
                </Tabs>
                {/* Pegar as infos da primeira etiqueta */}
                <CustomTabPanel value={tabSelecionada} index={0}>
                    <Typography style={{ marginTop: 5, textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: produto?.descricao }} />
                </CustomTabPanel>

                <Tabs style={{ marginTop: 30, color: 'black' }} value={0} onChange={handleChange} aria-label="basic tabs example">
                    <Tab style={{ color: 'black', fontWeight: 'bold', fontSize: 16 }} label="VENDEDOR" {...a11yProps(0)} />
                </Tabs>
                <CustomTabPanel value={0} index={0}>
                    <Box style={{ marginTop: 10, display: 'flex', flexWrap: 'nowrap', justifyContent: 'start', alignItems: 'center' }}>
                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                            <Avatar style={{ width: 70, height: 70 }} src={produto.loja.fotoLogo} />
                            <Box style={{ width: 'auto', marginLeft: 15 }}>
                                <Typography style={{ fontSize: 20, fontWeight: 'bold' }}>{`${produto.loja.nome}`}</Typography>
                                <Typography style={{ color: 'gray', fontSize: 14 }}>{`São Paulo, SP`}</Typography>
                            </Box>
                        </Box>
                        <Button variant='contained' style={{ marginLeft: 40, height: 45, background: 'rgb(238, 237, 234)', fontWeight: 'bold', color: 'black', fontSize: 14 }} onClick={() => navigate(`/atacado`, { state: { loja: produto.loja } })}>Ver Loja</Button>
                    </Box>
                </CustomTabPanel>
            </Box>

        </Grid>
    </ContainerDetalheProduto>
}

export default DetalhesProduto;