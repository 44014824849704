import { Box, Typography, styled } from '@mui/material';

export const Container = styled(Box)`
    width: 100%;
`;

export const StyleValueCart = styled(Typography)`
    color: white;
    background: #f1416d;
    border-radius: 25px;
    width: 16px;
    height: 15px;
    font-size: 10px !important;
    text-align: center;
    margin-top: -25px;

    margin-left: -2px !important;
`;