import React from 'react';
import { useNavigate } from 'react-router-dom';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';

import './style.css';
import star from '../../assets/icons/estrela.png'
import clock from '../../assets/icons/relogio.png'
import { Box, Typography } from '@mui/material';

function Academy() {
    const navigate = useNavigate()

    const data = [
        {
            image: require('../../assets/image/1.png'),
            name: "Gestão de Loja",
            min: "12 minutos",
            star: "4.5",
            desc: "Magna mollit dolor voluptate velit irure quis ullamco proident reprehenderit laboris.",
            fav: false
        },
        {
            image: require('../../assets/image/9.png'),
            name: "Precificação",
            min: "12 minutos",
            star: "4.5",
            desc: "Magna mollit dolor voluptate velit irure quis ullamco proident reprehenderit laboris.",
            fav: true
        },
        {
            image: require('../../assets/image/10.png'),
            name: "Gestão de Marketplace",
            min: "12 minutos",
            star: "4.5",
            desc: "Magna mollit dolor voluptate velit irure quis ullamco proident reprehenderit laboris.",
            fav: false
        },
    ]

    const data2 = [
        {
            image: require('../../assets/image/5.png'),
            name: "Card name #1",
            min: "12 minutos",
            star: "4.5",
            desc: "Magna mollit dolor voluptate velit irure quis ullamco proident reprehenderit laboris.",
            fav: true
        },
        {
            image: require('../../assets/image/2.png'),
            name: "Card name #2",
            min: "12 minutos",
            star: "4.5",
            desc: "Magna mollit dolor voluptate velit irure quis ullamco proident reprehenderit laboris.",
            fav: false
        },
        {
            image: require('../../assets/image/3.png'),
            name: "Card name #3",
            min: "12 minutos",
            star: "4.5",
            desc: "Magna mollit dolor voluptate velit irure quis ullamco proident reprehenderit laboris.",
            fav: false
        },
    ]

    return (
        <>
            <div className="home-container" style={{ paddingTop: 10 }}>
                <Box sx={{ width: "100%", marginBottom: 1 }}>
                    <Box style={{ width: "100%", marginLeft: 30, marginTop: 20 }}>
                        <Typography style={{ fontWeight: 'bold' }}>Academy</Typography>
                    </Box>
                </Box>
                <div>
                    <div className='home-card-container'>
                        <h3>Gestão</h3>
                        <div style={{ justifyContent: 'center' }}>
                            {data.map((res) => (
                                <div onClick={() => navigate('/aula')} className='home-card-content'>
                                    <img src={res.image} id='home-card-image' alt={res.name} />
                                    <FavoriteRoundedIcon htmlColor={res.fav ? '#ff8e00' : '#d3d3d3'} fontSize='medium' id='home-icon-play-fav' />
                                    <PlayArrowRoundedIcon htmlColor='#fff' fontSize='medium' id='home-icon-play' />
                                    <label>{res.name}</label>
                                    <p id='home-card-description'>{res.desc}</p>
                                    <div className='home-card-status'>
                                        <div id='home-card-footer'>
                                            <img src={clock} id='home-icon-image' alt='icon' />
                                            <text>{res.min}</text>
                                        </div>
                                        <div id='home-card-footer'>
                                            <text>{res.star}</text>
                                            <img
                                                src={star}
                                                id='home-icon-image'
                                                alt='icon'
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className='home-card-container'>
                        <h3>Empreendedorismo</h3>
                        <div>
                            {data2.map((res) => (
                                <div className='home-card-content'>
                                    <img src={res.image} id='home-card-image' alt={res.name} />
                                    <FavoriteRoundedIcon htmlColor={res.fav ? '#ff8e00' : '#d3d3d3'} fontSize='medium' id='home-icon-play-fav' />
                                    <PlayArrowRoundedIcon htmlColor='#fff' fontSize='medium' id='home-icon-play' />
                                    <label>{res.name}</label>
                                    <label>{res.name}</label>
                                    <div className='home-card-status'>
                                        <div id='home-card-footer'>
                                            <img src={clock} id='home-icon-image' alt='icon' />
                                            <text>{res.min}</text>
                                        </div>
                                        <div id='home-card-footer'>
                                            <text>{res.star}</text>
                                            <img
                                                src={star}
                                                id='home-icon-image'
                                                alt='icon'
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Academy