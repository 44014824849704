import {
  DeleteTwoTone,
  PhotoLibraryTwoTone,
} from "@mui/icons-material";
import { IconButton, InputLabel, Stack, Typography } from "@mui/material";
import { styled } from '@mui/material';
import { truncate } from "../../services/utils/functions";
import SearchIcon from '@mui/icons-material/Search';
import { CONTROLEMENSAGEM_AVISO } from "../../store/ControleMensagemReducer/types";
import { useDispatch } from "react-redux";

const UploadImagemContainer = styled(InputLabel)`
  min-width: 200px;
  padding: 1rem;
  max-height: 350px;
  border-radius: 7px;
  overflow: hidden;
  border: 1px dashed gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const UploadImagem = ({ isEditing, imgData, setImgData, index = undefined, style = null, heightImg = 150, widthImg = 200, borderRadiusImg = 10, paddingTopImg = 20, descricao = "" }) => {
  const dispatch = useDispatch();
  const ehFile = typeof imgData == 'string';

  return (
    <UploadImagemContainer htmlFor="file-upload" style={style}>
      {imgData ? (
        <Stack direction="column" justifyContent={"center"} alignItems="center">
          <img
            alt="not fount"
            width={"100%"}
            style={{ height: heightImg, width: widthImg, borderRadius: borderRadiusImg, paddingTop: paddingTopImg }}
            src={isEditing && ehFile ? imgData : URL.createObjectURL(imgData)}
          />
          <Stack direction="row" alignItems="center" sx={{ mt: 2, marginBottom: 1 }}>
            <Typography sx={{ mr: 1 }}>{imgData?.name ? truncate(imgData?.name, 20) : ''}</Typography>
            <IconButton size="large" onClick={() => setImgData(null, index)}>
              <DeleteTwoTone />
            </IconButton>
            <IconButton size="large" onClick={() => window.open(isEditing && ehFile ? imgData : URL.createObjectURL(imgData), '_blank')}>
              <SearchIcon />
            </IconButton>
          </Stack>
        </Stack>
      ) : (
        <>
          <PhotoLibraryTwoTone sx={{ fontSize: "5rem", color: "gray" }} />
          <Typography variant="body2" textAlign={"center"}>
            Subir imagem {descricao !== "" ? `(${descricao})` : ""}
          </Typography>
          <input
            type="file"
            accept="image/png, image/gif, image/jpeg, image/webp"
            id="file-upload"
            name="myImage"
            style={{ display: "none" }}
            onChange={(event) => {
              const img = event.target.files[0];
              const imgsize = img.size / 1024;

              if (imgsize > 2000) {
                dispatch({
                  type: CONTROLEMENSAGEM_AVISO,
                  titulo: 'Aviso',
                  message: 'Tamanho da imagem ultrapassa 2MB, favor tratá-la',
                  tipoComponente: 'alert'
                });
                return;
              }

              setImgData(img, index);
            }}
            onClick={(e) => (e.target.value = null)}
          />
        </>
      )}
    </UploadImagemContainer>
  );
};

export default UploadImagem;
